<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">
          {{ $route.params.id ? "ویرایش کاربر" : "کاربر جدید" }}
        </h5>
        <div style="flex: 1"></div>
        <Back />
      </div>
      <div class="card-body">
        <b-form @submit.prevent="sendUser">
          <b-form-group label="نام ">
            <b-form-input name="first_name" v-model="user.first_name"></b-form-input>
          </b-form-group>
          <b-form-group label="نام خانوادگی ">
            <b-form-input name="last_name" v-model="user.last_name"></b-form-input>
          </b-form-group>
          <b-form-group label="شماره تماس ">
            <b-form-input name="mobile" v-model="user.mobile"></b-form-input>
          </b-form-group>
          <b-form-group label="ایمیل ">
            <b-form-input name="email" v-model="user.email"></b-form-input>
          </b-form-group>
          <b-form-group label="تصویر">
            <b-form-file name="image" v-model="user.image"></b-form-file>
          </b-form-group>
          <b-button :disabled="disabledBtn" type="submit" variant="primary"> ثبت </b-button>
        </b-form>
      </div>
    </div>
  </main>
</template>
<script>
import createEditImpl from "@@/core/pages/users/createEdit/createEditImpl";
export default {
  extends: createEditImpl,
};
</script>
