import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BModal,
  BFormTextarea,
} from "bootstrap-vue";
export default {
  name: "OrderAddress",
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
  },
  created() {
    if (this.$root.isPart("admin")) {
      this.auth = "admin";
    }
  },
  props: {
    order: Object,
    addresses: Array,
  },
  data() {
    return {
      editMode: false,
      statusReq: false,
      mode: "create",
      addressShippings: [],
      disabled: false,
      customer: {},
      address: {},
      showAddressForm: false,
      adminProvinces: null,
      baseUrl: "/addresses",
      url: "admin/customers/addresses",
      cities: null,
      disabled: false,
      auth: "front",
      address: null,
    };
  },
  computed: {
    provinces() {
      if (this.auth === "admin") {
        return this.adminProvinces;
      } else {
        return this.$store.getters["front/getProvincesData"];
      }
    },
  },
  watch: {
    "address.province": function(newVal) {
      console.log(newVal);
      if (newVal) {
        let cities = this.provinces.filter((item) => item.id == newVal.id)[0]
          .cities;
        this.cities = cities;
      }
    },

    addresses(newVal) {
      let addresses = newVal.map((ad) => {
        ad.label =
          ad.city.province.name +
          " ، " +
          ad.city.name +
          " ، " +
          ad.address +
          " - گیرنده : " +
          ad.first_name +
          " " +
          ad.last_name +
          " - کد پستی : " +
          ad.postal_code;
        return ad;
      });
      return addresses;
    },
  },
  methods: {
    editAddress() {
      this.editMode = !this.editMode;
      this.address = this.order.address;
      console.log(this.order);
      // this.setInitAddress();
      this.customer.id = this.order.customer_id;
    },
    setInitAddress() {
      let province = this.provinces.filter(
        (item) => item.id == this.order.address.city.province.id
      )[0];
      this.address.province = province;
      let cities = this.provinces.filter(
        (item) => item.id == this.order.address.city.province.id
      )[0].cities;
      this.cities = cities;
      this.address.city = this.cities.filter(
        (item) => item.id == this.order.address.city.id
      )[0];
    },
    resetVeeValidate() {
      this.$refs.observer.reset();
    },
    setCities(items) {
      this.cities = items.cities;
    },
    async submitAddress(e) {
      this.disabled = true;
      try {
        let formData = new FormData(e.target);
        formData.append("order_id", this.$route.params.id);
        formData.append("city", this.address.city ? this.address.city.id : "");
        // formData.append("province", this.address.province ? this.address.province.id : "");
        formData.append("_method",  "patch" );
        formData.append(
          "customer_id",
          this.auth === "admin" ? this.order.customer.id : ""
        );
        this.statusReq = true;
        const response = await this.$axios.post(this.url+`/${this.order.address.id}`, formData);
        this.statusReq = false;
        this.editMode=false;

        this.$emit("editOrderAddress", response.data.data.address);
        this.showAddressForm = false;
        this.$root.notify(response.data.message, "success");
        this.cities = null;
      } catch (error) {
        this.$root.notify(error);
      }
      this.statusReq = false;

      this.disabled = false;
    },
    async getNeededInfo() {
      const response = await this.$axios.get("admin/orders/create", {
        params: {
          without_customers: true,
        },
      });
      // this.customers = response.data.data.customers;
      // this.shippings = response.data.data.shippings;
      // let products = response.data.data.list_products.filter(p => p.total_quantity > 0)
      // this.products = products.map(product => {
      //   product.varieties.forEach(variety => {
      //     variety.product = cloneDeep(product)
      //   });
      //   return product
      // });
      // this.products = products

      this.adminProvinces = response.data.data.provinces;
    },
    addNewAddress(newAddress) {},
    newAddress() {
      this.address = {
        province: null,
        city: null,
        first_name: "",
        last_name: "",
        postal_code: "",
        address: "",
        mobile: "",
        customer_id: null,
      };
      this.address.customer_id = this.customer.id;
      this.addressMode = "create";
      this.showAddressForm = true;
    },

    async submit() {
      //
      this.disabled = true;
      try {
        const response = await this.$axios.post(
          "admin/orders/" + this.order.id,
          {
            address_id: this.order.address ? this.order.address.id : "",
            shipping_id: this.order.shipping ? this.order.shipping.id : "",
            discount_amount: this.order.discount_amount,
            description: this.order.description,
            _method: "put",
          }
        );

        this.$root.notify(response.data.message, "success");
        this.$root.$emit("bv::hide::modal", "order-edit");
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
  },
  mounted() {
    this.getNeededInfo();
    if (this.auth === "admin") {
      this.baseUrl = "admin/customers" + this.baseUrl;
    } else {
      if (this.$store.getters[this.auth + "/getProvincesData"] == null) {
        this.$store.dispatch(this.auth + "/getProvincesDataFromServer");
      }
      this.baseUrl = "customer" + this.baseUrl;
    }
  },
};
