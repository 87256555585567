<template>
  <main class="mt-5">
 
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold py-3">سفارشات</h5>
        <div style="flex: 1"></div>
        
        <b-button
          v-b-toggle.filter
          :variant="hasFilter() ? 'secondary' : 'primary'"
          class="mr-2"
        >
          <span class="fe fe-search pt-1" style="font-size: 17px"></span>
        </b-button>
      </div>
      <b-collapse id="filter" class="mt-2 mx-5">
        <b-card>
          <form @submit.prevent="filterItem()">
            <div class="row mt-5">
              <b-form-group class="col-md-3">
                <multiselect
                  placeholder="نوع"
                  v-model="filter.type"
                  open-direction="bottom"
                  :options="typeFilterOptions"
                  label="title"
                  track-by="value"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>

              <b-form-group class="col-md-3">
                <b-form-input
                  :disabled="disabled"
                  placeholder="شناسه"
                  v-number
                  v-model="filter.id"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-3">
                <b-form-input
                  :disabled="disabled"
                  placeholder="کد رهگیری"
                  v-number
                  v-model="filter.tracking_code"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-3">
                <b-form-input
                  :disabled="disabled"
                  placeholder="شهر"
                  v-model="filter.city"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-3">
                <b-form-input
                  :disabled="disabled"
                  placeholder="استان"
                  v-model="filter.province"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                v-if="hasPermission('read_customer')"
                class="col-md-3"
              >
                <multiselect
                  placeholder="نماینده"
                  v-model="filter.customer_id"
                  open-direction="bottom"
                  :options="customers"
                  label="full_name"
                  track-by="id"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>
              <b-form-group v-if="widgetData.products" class="col-md-3">
                <multiselect
                  placeholder="محصول"
                  v-model="filter.product_id"
                  open-direction="bottom"
                  :options="products"
                  label="title"
                  track-by="id"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-3">
                <multiselect
                  placeholder="وضعیت"
                  v-model="filter.status"
                  open-direction="bottom"
                  :options="statusFilterOptions"
                  label="title"
                  track-by="value"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-3">
                <multiselect
                  placeholder="وبسایت نماینده"
                  v-model="filter.customer_websites"
                  open-direction="bottom"
                  :options="customer_websites"
                  label="title"
                  track-by="value"
                  :searchable="true"
                  :show-labels="false"
                  :disabled="disabled"
                  
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-4">
                <date-picker
                  placeholder="از تاریخ"
                  v-model="filter.start_date"
                  format="YYYY-MM-DD"
                  display-format="jYYYY-jMM-jDD"
                />
              </b-form-group>
              <b-form-group class="col-md-4">
                <date-picker
                  placeholder="تا تاریخ"
                  v-model="filter.end_date"
                  format="YYYY-MM-DD"
                  display-format="jYYYY-jMM-jDD"
                />
              </b-form-group>
              <b-form-group class="col-md-12">
                <ProductSearch
                  ref="productSearch"
                  :selectable="true"
                  class="row"
                  :disabled="disabled"
                  @selectProduct="selectProduct"
                  :show-label="false"
                  @selectVariety="selectVariety"
                />
              </b-form-group>
            </div>
            <div class="text-center mt-3">
              <button
                :disabled="disabled"
                type="submit"
                class="btn btn-primary px-5"
              >
                جستجو
              </button>
              <button
                :disabled="disabled"
                @click="resetFilter"
                class="btn btn-warning px-5 mr-3"
              >
                برداشتن فیلتر
              </button>
              <button
                :disabled="disabled"
                @click="$root.$emit('bv::toggle::collapse', 'filter')"
                class="btn btn-secondary px-5 mr-3"
              >
                بستن
              </button>
            </div>
          </form>
        </b-card>
      </b-collapse>
      <div class="card-body pt-1">
        <div>
          <OrderStatusesView
            @input="statusChangedByButton"
            v-model="filter.status"
            class="mb-3"
            :order-statuses="orderStatusesInfo"
          />

          <b-table
            responsive
            hover
            bordered
            :items="items.data"
            :fields="fields"
            :style="disabled && items && items.data ? 'opacity: 0.7' : ''"
          >
            <template #head(select)="row">
              <b-form-checkbox
                :disabled="items == null"
                class="text-center text-dark"
                :id="'checkbox-all' + row.label"
                name="checkboxall"
                @change="orderSelectAll"
                v-model="allSelected"
              >
              </b-form-checkbox>
            </template>
            <template #cell(website)="row">
              <span
                v-if="
                  row.item &&
                  row.item.creatorable_type &&
                  row.item.creatorable_type.endsWith('User')
                "
              >
                {{ row.item.customer.website_base_url }}
              </span>
              <span v-else>پنل اصلی</span>
            </template>

            <template #cell(select)="row">
              <b-form-checkbox
                class="text-center text-dark table-checkbox"
                :notselected="!selectedOrders[row.index]"
                :selected="selectedOrders[row.index]"
                :id="'checkbox' + row.item.id"
                :name="'checkbox' + row.item.id"
                @change="orderSelect(row.index, row.item.id)"
              >
              </b-form-checkbox>
            </template>
            <template #cell(total_amount)="row">
              {{ row.item.total_amount | price }}
            </template>
            <template #cell(created_at)="row">
              {{ row.item.created_at | persianDate }}
            </template>
            <template #cell(tracking_code)="row">
              <template
                v-if="
                  row.item.wallet_invoices && row.item.wallet_invoices.length
                "
              >
                <WalletSvg :id="'wallet-btn-' + row.item.id" />
                <b-tooltip
                  :target="'wallet-btn-' + row.item.id"
                  triggers="hover"
                >
                  {{ getSumWalletInvoices(row.item.wallet_invoices) | price }}
                  تومان
                </b-tooltip>
              </template>
              <span
                v-if="
                  !(
                    row.item.wallet_invoices && row.item.wallet_invoices.length
                  ) &&
                  (!row.item.active_payments ||
                    !row.item.active_payments.length)
                "
                >―</span
              >
              <span
                v-else-if="row.item.active_payments.length > 1"
                @mouseenter="showTrack(row.item.id)"
                @mouseleave="hideTrack(row.item.id)"
                class="badge badge-success-dark status position-relative"
                >{{ row.item.active_payments.length }}
                <transition name="fast-fade">
                  <div
                    v-if="showingTrackings.includes(row.item.id)"
                    class="orders-tooltip"
                  >
                    <span
                      v-for="payment in row.item.active_payments"
                      :key="payment.id"
                      ><span>{{ payment.tracking_code }}</span
                      ><span style="user-select: none"> - </span
                      >{{ payment.amount | price }} تومان</span
                    >
                  </div>
                </transition>
                کد رهگیری</span
              >
              <span v-else-if="row.item.active_payments[0]">
                {{ row.item.active_payments[0].tracking_code }}
              </span>
            </template>
            <template #cell(receiver)="row">
              {{ getReceiverName(row.item) }}
            </template>
            <template #cell(status)="row">
              <order-status :status="row.item.status"></order-status>
            </template>
           
            <template #cell(detail)="row">
              <router-link
                :to="{ name: 'virtual.detail', params: { id: row.item.id } }"
              >
                <b-button variant="info"> جزئیات </b-button>
              </router-link>
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <router-link
                  :disabled="!hasPermission('modify_order') ? true : false"
                  :to="{ name: 'order.edit', params: { id: data.item.id } }"
                  class="action-btns1 pt-1 px-2"
                >
                  <i class="feather feather-edit text-primary"></i>
                </router-link>
              </span>
              <b-tooltip
                v-if="!hasPermission('modify_order')"
                :target="'edit-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">موردی یافت نشد</div>
        </div>
      </div>

      <b-modal id="change-status" hide-footer title="تغییر وضعیت">
        <form @submit.prevent="updateStatus()">
          <b-form-group>
            <multiselect
              placeholder="وضعیت"
              v-model="ordersStatus"
              open-direction="bottom"
              :options="statusOptions"
              label="title"
              track-by="value"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
            >
              <template slot="noOptions"> موردی یافت نشد </template>
              <template slot="noResult"> موردی یافت نشد </template>
            </multiselect>
          </b-form-group>
          <div class="text-center mt-3">
            <button class="btn btn-primary" :disabled="disabled">ویرایش</button>
          </div>
        </form>
      </b-modal>
    </div>
  </main>
</template>
<script>
import virtualOrdersImpl from "@@/core/pages/accounting/virtualOrders/virtualOrdersImpl";
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";
export default {
  components: { ProductSearch },
  extends: virtualOrdersImpl,
};
</script>
<style scoped>
.badge {
  font-size: 14px;
}
.orders-tooltip {
  font-size: 14px;
  position: absolute;
  padding: 10px 20px;
  right: -4px;
  top: 50%;
  transform: translate(100%, -50%);
  border-radius: 5px;
  background: #024c5c;
  display: flex;
  flex-direction: column;
  color: white;
}

.orders-tooltip span {
  margin: 4px 0;
}

.orders-tooltip:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -6px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 7px solid #0f6674;
}
</style>
