import {Treeselect} from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import cloneDeep from 'lodash/cloneDeep'
import ProductChangeQuantity from '@@/core/components/product/ProductChangeQuantity/ProductChangeQuantity'
import VarietyInformation from '@@/core/components/product/varieties/VarietyInformation/VarietyInformation'
import ProductImages from '@@/core/components/product/ProductImages/ProductImages'
import VarietyRow from '@@/core/components/product/varieties/VarietyRow/VarietyRow'
import TreeselectAddable from '@@/core/components/product/shared/TreeselectAddable/TreeselectAddable'
import productModelToData from "@@/core/components/product/classes/productModelToData";
import variety from '@@/core/components/product/classes/variety'
import InputGroup from "@@/core/components/product/shared/InputGroup/InputGroup";
import configProvider from "@@/core/configProvider";
import debounce from "lodash.debounce";
import {BFormGroup, BFormInput, BModal, VBModal, VBToggle} from "bootstrap-vue";
import draggable from "vuedraggable";
import Swal from 'sweetalert2'

export default {
  name: "ProductVarieties",
  configProvider,
  dragOptions: {
    animation: 200,
    group: "description",
    disabled: false,
    ghostClass: "ghost"
  },
  directives: {
    'b-toggle': VBToggle, 'b-modal': VBModal,
  },
  components: {
    VarietyInformation,
    ProductImages,
    VarietyRow,
    TreeselectAddable,
    Treeselect,
    InputGroup,
    BFormGroup, BFormInput, BModal,
    ProductChangeQuantity,
    draggable
  },
  props: {
    value: Object,
    productInformation: Object,
    allAttributes: Array,
    colors: Array,
    categories: Array,
    selectedCategories: Array,
    defaultPrice: [String, Number],
    mode: String,
    gifts: Array
  },
  data() {
    return {
      realValue: this.value,
      addedAttributeOptions: [],
      varietiesTemplate: [],
      sortedVarietiesTemplate: [],
      old_selected_attribute_values: null,
      selected_attribute_valuesOnChangeDebounce: debounce(this.selected_attribute_valuesOnChange, 100),
      changeQuantityVariety: null,
      changeQuantityType: 'incrmenet',
    }
  },
  mounted() {
    if (this.realValue.varieties.length) {
      this.generateVarieties()
      this.$nextTick(() => this.makeSortFromVarieties())
      this.realValue.selected_attribute_values.forEach((val, index) => {
        if (typeof val !== 'undefined') {
          if(!this.addedAttributeOptions[index]) {
            this.addedAttributeOptions[index] = []
          }
          val.forEach(v => {
            this.addedAttributeOptions[index].push({
              id: v,
              label:v,
              value:v
            })
          })
        }
      })
      this.old_selected_attribute_values = cloneDeep(this.realValue.selected_attribute_values);
    }
  },
  watch: {
    selectedCategories(newVal) {
      // TODO check if attribute exists in product->varieties or not in edit
      const attributes = this.readAttributes(newVal);
      if (this.mode == 'edit') {
        return;
      }
      this.realValue.attributes = this.realValue.attributes.filter(attr => {
        let found = false;
        attributes.forEach(_attr => {
          if (_attr.id == attr) {
            found = true;
          }
        })
        return found;
      })
    },
    'realValue.attributes': function (newVal) {
      this.generateVarieties();
    },
    'realValue.selected_attribute_values'(newVal) {
      this.selected_attribute_valuesOnChangeDebounce()
    },
    'realValue.colors': function (newVal) {
      this.generateVarieties();
      this.selected_attribute_valuesOnChangeDebounce()
    },
    varietiesTemplate(newVal) {
      let temp = [];
      this.varietiesTemplate.forEach(template => {
        temp.push(this.getVarietyTemplateKey(template))
      })
      this.sortedVarietiesTemplate = temp;
      productModelToData.makeVarieties(this.realValue.varieties, this.varietiesTemplate, this.defaultPrice, this.getVarietyTs());
    }
  },
  computed: {
    dropdownOptions() {
      let id = this.$route.params.id
      let data = [{name: 'price', label:'قیمت گذاری کلی'}]
      if (configProvider.get('product.excelType1') && id) {
        data.push({name: 'excel1', label: 'دانلود اکسل'})
      }
      return data
    },
    realColors() {
      return this.$root.addLabel(this.colors)
    },
    attributes() {
      if (!(this.selectedCategories && this.selectedCategories.length)) {
        return [];
      }

      if (this.mode == 'edit') {
        return this.allAttributes
      } else {
        return this.readAttributes(this.selectedCategories)
      }
    },
    selectedAttributes() {
      return this.attributes.filter(attr => {
        return this.realValue.attributes.includes(attr.id)
      })
    },
    varietyTs() {
      return this.getVarietyTs()
    }
  },
  methods: {
    async dropdownInput(name) {
      switch (name) {
        case 'price':
          const { value: newPrice } = await Swal.fire({
            title: 'قیمت جدید را وارد کنید',
            input: 'number',
            inputPlaceholder: 'قیمت به تومان',
            confirmButtonText: 'ثبت'
          })

          if (newPrice) {
            this.sortedVarietiesTemplate.forEach((key) => {
              const index = this.getVarietyTemplateIndexByKey(key)
              this.realValue.varieties[index].data.price = newPrice
            })
          }
          break;
        case 'excel1':
          let id = this.$route.params.id
          const response = await this.$axios(`admin/products/${id}/excel`, {
            headers: {
              "Accept": "x-xlsx",
            },
            params: {
              type: 1
            },
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
            }
          });
          this.disabled = false
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', id + '.xls');
          document.body.appendChild(link);
          link.click();
      }
    },
    changeQuantity(variety, type) {
      this.changeQuantityVariety = variety;
      this.changeQuantityType = type;
      this.$root.$emit('bv::show::modal', 'product-change-quantity-modal')
    },
    makeSortFromVarieties() {
      let newSortedVarietiesTemplate = []
      window.temp = [...this.realValue.varieties]
      let sortedVarietiesByOrder = [...this.realValue.varieties].sort((a,b) => b.data.order - a.data.order)
      sortedVarietiesByOrder.forEach(variety => {
        let fakeIndex = this.realValue.varieties.findIndex(v => v.unique_key == variety.unique_key);
        newSortedVarietiesTemplate.push(this.sortedVarietiesTemplate[fakeIndex]);
      })
      this.sortedVarietiesTemplate = newSortedVarietiesTemplate
    },
    getSortedVarieties() {
      let varieties = [];
      this.sortedVarietiesTemplate.forEach(key => {
        let index = this.getVarietyTemplateIndexByKey(key)
        varieties.push(this.realValue.varieties[index]);
      })
      return varieties;
    },
    getVarietyTemplateIndexByKey(key) {
      return this.varietiesTemplate.findIndex(template => {
        return key == this.getVarietyTemplateKey(template)
      })
    },
    selected_attribute_valuesOnChange() {
      const oldVarieties = cloneDeep(this.realValue.varieties)
      this.generateVarieties();
      if (this.old_selected_attribute_values) {
        this.$nextTick(() => {
          this.rearrangeVarieties(oldVarieties, this.realValue.varieties)
        })
      }
      this.old_selected_attribute_values = cloneDeep(this.realValue.selected_attribute_values);
    },
    rearrangeVarieties(oldVarieties, newVarieties) {
      let notFoundIndexes = Array(newVarieties.length).fill(true)
      newVarieties = cloneDeep(newVarieties)
      oldVarieties.forEach((oldVariety,index) => {
        let variety = newVarieties.find(v => v.unique_key == oldVariety.unique_key)
        let _index = newVarieties.findIndex(v => v.unique_key == oldVariety.unique_key)
        if (variety) {
          notFoundIndexes[_index] = false;
          variety.data = oldVariety.data;
        }
      });
      notFoundIndexes.forEach((notFound, index) => {
        if (notFound) {
          let ts = cloneDeep(newVarieties[index].ts)
          newVarieties[index] = new variety( {
            price: this.defaultPrice
          })
          newVarieties[index].ts = ts;
          newVarieties[index].present = true;
        }
      })

      this.realValue.varieties = newVarieties;
    },
    getVarietyTs() {
      return productModelToData.getVarietyTs(this.realValue.colors, this.realValue.attributes, this.realValue.selected_attribute_values, this.allAttributes);
    },
    readAttributes(categories) {
      let attributes = [];
      let addedAttributeIds = [];
      categories.forEach(cat => {
        cat.attributes.forEach((attribute) => {
          if (addedAttributeIds.includes(attribute.id)) {
            return;
          }
          addedAttributeIds.push(attribute.id);
          let copy = {...attribute};
          copy.label = copy.name
          attributes.push(copy);
        });
        // let attributesFromChildren = this.readAttributes(cat.children);
        // attributesFromChildren.forEach((attr) => {
        //   if (addedAttributeIds.includes(attr.id)) {
        //     return;
        //   }
        //   addedAttributeIds.push(attr.id);
        //   let copy = {...attr};
        //   copy.label = copy.name
        //   attributes.push(copy);
        // })
      });

      return attributes;
    },
    getOptions(attribute) {
      let fromAdded = this.addedAttributeOptions[attribute.id];
      if (!fromAdded) {
        fromAdded = [];
      }

      return this.$root.addLabel(fromAdded);
    },
    addOption(attribute, option) {
      let added = this.addedAttributeOptions[attribute.id];
      if (!added) {
        this.addedAttributeOptions[attribute.id] = [option]
      } else {
        this.addedAttributeOptions[attribute.id].push(option)
      }
    },
    generateVarieties() {
      this.varietiesTemplate = productModelToData.makeCombinations(this.varietyTs)
    },
    getAttribute(id) {
      return this.attributes.find(item => {
        return item.id == id
      })
    },
    getVarietyTemplateKey(vT) {
      let s = '';
      vT.forEach(i => {
        s += (i + ',');
      })
      return s;
    },
    getColor(colorId) {
      return this.colors.find(c => {
        return c.id === colorId
      })
    },
    changeVarietyPrice(index, newPrice) {
      let cp = cloneDeep(this.realValue.varieties[index]);
      cp.data.price = +newPrice.replace(/\D/g, '');
      this.$set(this.realValue.varieties, index, cp);
    }
  }
}
