<template>
  <div class="col-xl-6 col-md-12">
    <b-form-group>

      <multiselect
          @search-change="getCustomers"
          v-model="selectedCustomer"
          placeholder="انتخاب کنید"
          :allow-empty="false"
          open-direction="bottom"
          :options="customers"
          label="label"
          track-by="id"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
      >
        <template slot="noOptions">
          جستجو کنید
        </template>
        <template slot="noResult">
          <span v-if="isRequesting" v-text="'در حال جستجو...'" />
          <span v-else v-text="'موردی یافت نشد'"></span>
        </template>
      </multiselect>
    </b-form-group>
  </div>
</template>

<script>
import {BFormGroup, BFormInput} from "bootstrap-vue";

export default {
  name: "CustomerSearch",
  components: {BFormGroup, BFormInput},
  props: {
    disabled: Boolean,
    showLabel: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      customers: [],
      isRequesting: true,
      selectedCustomer: null,
      lastQ: '',
      searchMessage: ''
    }
  },
  watch: {
    searchMessage() {
      this.$emit('message', this.searchMessage)
    },
    selectedCustomer() {
      this.searchMessage = ''
      this.$emit('input', this.selectedCustomer)
    }
  },
  methods: {
    async getCustomers(q) {
      if (this.lastQ == q || q == '' || q.length == 1) {
        return;
      }
      this.lastQ = q;
      if (this.axiosProductsCancelSource) {
        this.axiosProductsCancelSource.cancel()
      }
      this.axiosProductsCancelSource = this.$axios.CancelToken.source();
      try {
        this.isRequesting = true
        const response = await this.$axios.get('admin/userSajad?search='+q, {
          cancelToken: this.axiosProductsCancelSource.token,
          
        })
        this.customers = response.data.data.users.data.map(c => {
          c.label = c.id + ' - ' + c.mobile;
          if (c.first_name || c.last_name) {
            c.label += ' - '
          }
          if (c.first_name) {
            c.label += c.first_name;
          }
          if (c.last_name) {
            c.label +=` ${c.last_name}`;
          }
          return c
        })
        
        this.isRequesting = false
      } catch (e) {
        if (!this.$axios.isCancel(e)) {
          this.$root.notify(e)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
