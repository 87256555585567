<template>
  <b-modal
    id="user-wallet"
    hide-footer
    :title="
      type == 'deposit' ? 'افزایش موجودی کیف پول ' : 'کاهش موجودی کیف پول '
    "
  >
    <form @submit.prevent="addWallet">
      <b-form-group v-if="wallet">
        <label class="pb-1" for="">
          مبلغ (تومان) <i class="text-danger">*</i></label
        >
        <b-form-input
          :value="wallet.amount | priceinput"
          @input="
            (value) => {
              wallet.amount = value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
        >
        </b-form-input>
      </b-form-group>
      <b-form-group>
        <label class="pb-1" for=""> توضیحات </label>
        <b-form-textarea v-model="description"></b-form-textarea>
      </b-form-group>
      <div class="text-center mt-3">
        <button
          v-if="type == 'deposit'"
          class="btn btn-success"
          :disabled="disabled"
        >
          افزایش موجودی
        </button>
        <button
          v-else-if="type == 'withdraw'"
          class="btn btn-danger"
          :disabled="disabled"
        >
          کاهش موجودی
        </button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import userWalletImpl from "@@/core/components/users/userWallet/userWalletImpl";
export default {
  extends: userWalletImpl,
};
</script>
