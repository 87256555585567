<template>
  <div class="card-header border-0">
    <h5 class="card-title font-bold">جزئیات سفارش</h5>
    <div style="flex: 1"></div>
    <template v-if="allowEdit">
      <span id="order-edit">
        <button @click="orderEdit()" class="btn btn-info ml-2 btn-print">
          <i class="fe fe-edit"></i>
        </button>
      </span>
      <b-tooltip target="order-edit" triggers="hover"> ویرایش سفارش </b-tooltip>
    </template>
    <template v-if="allowPrint">
      <span id="order-print">
        <button @click="printOrder()" class="btn btn-info ml-2 btn-print">
          <i class="fe fe-printer"></i>
        </button>
      </span>
      <b-tooltip target="order-print" triggers="hover"> چاپ </b-tooltip>
    </template>

    <Back />
  </div>
</template>

<script>
import OrderHeaderImpl from "@@/core/components/order/OrderHeader/OrderHeaderImpl";

export default {
  extends: OrderHeaderImpl,
};
</script>

<style scoped></style>
