<template>
  <b-modal id="new-item" hide-footer title="افزودن به سفارش" size="lg">
    <form @submit.prevent="newOrderItem">
      <ProductSearch
        ref="productSearch"
        :selectable="true"
        class="row"
        :disabled="disabled"
        @selectProduct="selectProduct"
        :show-label="false"
        @selectVariety="selectVariety"
      />
      <b-form-group
        v-if="newItem.selectedVariety != null && newItem.selectedVariety != ''"
      >
        <div class="d-flex justify-content-between">
          <label for="quantity"> تعداد <i class="text-danger">*</i></label>
          <span> موجودی : {{ newItem.varietyQuantity }} </span>
          <span>
            قیمت :
            {{ newItem.selectedVariety.final_price.amount | price }} تومان</span
          >
        </div>
        <b-form-input
          id="quantity"
          name="quantity"
          v-model="newItem.quantity"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="نوع پرداخت:">
        <div
          class="text-center mt-3 d-flex align-items-center"
          style="gap: 7px"
        >
          <input id="wallet" type="checkbox" v-model="wallet" />
          <label for="wallet" style="margin-bottom: 0">کیف پول</label>
          <input id="cardByCard" type="checkbox" v-model="cardByCard" />
          <label for="cardByCard" style="margin-bottom: 0">کارت به کارت</label>
        </div>
      </b-form-group>
      <b-form-group label="شماره کارت" v-if="cardByCard">
        <b-form-input v-model="cartNumber"></b-form-input>
      </b-form-group>
      <div class="text-center mt-3">
        <button class="btn btn-success">ثبت</button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { BModal, BFormGroup, BFormInput } from "bootstrap-vue";
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";

export default {
  name: "OrderAdd",
  data() {
    return {
      newItem: {
        selectedProduct: null,
        selectedVariety: null,
        varietyQuantity: 0,
        quantity: 0,
      },
      wallet: true,
      cardByCard: true,
      cartNumber: null,
    };
  },
  props: {
    order: Object,
    disabled: Boolean,
  },
  components: {
    ProductSearch,
    BModal,
    BFormGroup,
    BFormInput,
  },
  methods: {
    async newOrderItem() {
      try {
        this.$parent.disabled = true;
        let pay_type = "";
        if (this.wallet && this.cardByCard) {
          pay_type = "both";
        } else if (this.wallet) {
          pay_type = "wallet";
        } else if (this.cardByCard) {
          pay_type = "cardByCard";
        }
        const response = await this.$axios.post(
          `/admin/virtualOrders/${this.order.id}/items`,
          {
            product_id: this.newItem.selectedProduct
              ? this.newItem.selectedProduct.id
              : "",
            variety_id: this.newItem.selectedVariety
              ? this.newItem.selectedVariety.id
              : "",
            quantity: this.newItem.quantity,
            pay_type: pay_type,
            cardByCardTransactionId: this.cartNumber,
          }
        );
        this.$root.notify(response.data.message, "success");
        this.order.items.push(response.data.data.orderItem);
        this.$root.$emit("bv::hide::modal", "new-item");
        let orderLog = {
          log_items: [
            {
              type: "new",
              quantity: this.newItem.quantity,
              order_item_id: response.data.data.orderItem.id,
              order_log_id: "",
              id: "",
            },
          ],
        };
        this.order.order_logs.unshift(orderLog);
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.$parent.disabled = false;
      }
    },
    newOrder() {
      this.newItem = {
        selectedProduct: null,
        selectedVariety: null,
        varietyQuantity: 0,
        quantity: 0,
      };
    },
    selectProduct(p) {
      this.newItem.selectedProduct = p;
    },
    selectVariety(v) {
      this.newItem.selectedVariety = v;
    },
  },
};
</script>

<style scoped></style>
