<template>
  <div>
    <div v-for="[key, value] in Object.entries(items)" v-if="shouldShow(value)" class="col-3 mb-3">
      <span class="font-weight-bold">{{ getLabel(key) }}: </span>
      <span>
      <template v-if="isPrice(key)">
        {{ value | price }}
      </template>
      <template v-else>{{ value }}</template>
      {{ getAfterLabel(key) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "KeyValues",
  props: {
    items: Object
  },
  translations: {
    total_product_sales: {
      label: 'میزان فروش محصول',
      price: true,
      afterLabel: 'تومان'
    },
    total_income: {
      label: 'میزان فروش کل',
      price: true,
      afterLabel: 'تومان'
    },
    total_shipping_amount: {
      label: 'میزان حمل و نقل',
      price: true,
      afterLabel: 'تومان'
    },
    total_discount_amount: {
      label: 'میزان تخفیف (کوپن)',
      price: true,
      afterLabel: 'تومان'
    },
    total_order_items_count: {
      label: 'تعداد اقلام'
    },
    total_not_coupon_discount_amount: {
      label: 'میزان تخفیف (بدون کوپن)',
      price: true,
      afterLabel: 'تومان'
    }
  },
  methods: {
    getLabel(key) {
      if (this.$options.translations[key]) {
        return this.$options.translations[key].label
      }
      return key
    },
    getAfterLabel(key) {
      if (this.$options.translations[key] && this.$options.translations[key].afterLabel) {
        return this.$options.translations[key].afterLabel
      }
      return ''
    },
    isPrice(key) {
      if (this.$options.translations[key]) {
        return Boolean(this.$options.translations[key].price)
      }
      return false
    },
    shouldShow(value) {
      return value !== null && typeof value === 'string' || typeof value === 'number'
    }
  }
}
</script>

<style scoped>

</style>
