import {
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BFormCheckbox,
} from "bootstrap-vue";
import { getShippingPrice, setShipping } from "@@/core/libraries/functions";
import UserSearch from "@@/core/components/shared/UserSearch/UserSearch";
import AddressModal from "@@/core/components/address/userAddressModal/AddressModal";
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";
export default {
  name: "Products",
  components: {
    UserSearch,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    AddressModal,
    ProductSearch,
    BTable,
    BFormCheckbox,
  },
  data() {
    return {
      useWallet: false,
      discountAmount: null,
      created_at: "",
      url: "admin/virtualOrders",
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "image",
          label: "تصویر",
        },
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "amount",
          label: "قیمت واحد (تومان)",
        },
        {
          key: "quantity",
          label: "تعداد",
        },
        {
          key: "discount_amount",
          label: "تخفیف واحد (تومان)",
        },
        {
          key: "flash",
          label: "کمپین",
        },
        {
          key: "total_price",
          label: "قیمت نهایی (تومان)",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      cardByCard: false,
      wallet: false,
      vo: {
        user_id: "",
        address_id: "",
        cardByCardTransactionId: "",
        shipping: "",
        discount_amount: null,
        description: "",
        varieties: [],
      },
      disabled: false,
      shippings: "",
      addressShippings: [],

      provinces: [],
      productSelect: "",
      userAddresses: [],
      selectedUser: null,
      address: {
        province: null,
        city: null,
        first_name: "",
        last_name: "",
        postal_code: "",
        address: "",
        mobile: "",
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    addNewAddress(newAddress) {
      let obj = newAddress;
      obj.title = `${newAddress.first_name} ${newAddress.last_name} -${newAddress.address}`;
      this.userAddresses.push(obj);
      this.vo.address_id = obj;
      this.setShipping(this.vo.address_id);
    },
    async loadData() {
      const response = await this.$axios.get("admin/orders/create", {
        params: {
          without_customers: true,
        },
      });
      this.shippings = response.data.data.shippings;
      this.provinces = response.data.data.provinces;
    },
    deleteItem(index) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.$root.$delete(this.vo.varieties, index);
          }
        })
        .catch(() => {});
    },
    decrement(item) {
      if (item.quantity > 1) {
        item.quantity--;
      }
    },
    increment(item) {
      if (item.quantity >= item.variety.quantity) {
        this.$root.notify(
          " از این کالا تنها " + item.variety.quantity + "  عدد موجود است ",
          "warning"
        );
      } else {
        item.quantity++;
      }
    },
    newAddress() {
      this.address = {
        province: null,
        city: null,
        first_name: "",
        last_name: "",
        postal_code: "",
        address: "",
        mobile: "",
      };
      // this.address.customer_id = this.order.customer.id;
      // this.addressMode = "create";
      window.$("#userAddAddressModal").modal("show");
    },
    setShipping(item) {
      this.addressShippings = setShipping(item, this.shippings);
    },

    selectTheProduct(p) {
      this.productSelect = p;
    },
    selectedVariety(item) {
      this.$nextTick(() => {
        const findVariety = this.vo.varieties.find((variety) => {
          return variety.variety_id == item.id;
        });
        if (findVariety) {
          this.$root.notify(
            "این تنوع در لیست محصولات سفارش موجود است.",
            "warning"
          );
        } else if (item.quantity == 0) {
          this.$root.notify("این تنوع موجودی ندارد.", "warning");
        } else {
          let variety = {
            variety_id: item.id,
            variety: item,
            quantity: 1,
            amountWithCalcDiscount:
              item.final_price.sell_price_discount ??
              item.final_price.sell_price,
            amount: item.final_price.sell_price,

            discount_amount: item.final_price.sell_price_discount_amount,
            major_image: this.productSelect.major_image,
          };
          this.vo.varieties.push(variety);
          this.vo.discount_amount += Number(variety.discount_amount);
        }
      });
    },
    async submit(e) {
      e.preventDefault();
      this.disabled = true;

      try {
        let formData = new FormData(e.target);
        formData.append(
          "user_id",
          this.selectedUser ? this.selectedUser.id : ""
        );
        formData.append(
          "address_id",
          this.vo.address_id ? this.vo.address_id.id : ""
        );
        formData.append(
          "shipping_id",
          this.vo.shipping ? this.vo.shipping.id : ""
        );
        formData.append("discount_amount", Number(this.discountAmount));
        if (this.wallet && this.cardByCard) {
          formData.append("pay_type", "both");
        } else if (this.wallet) {
          formData.append("pay_type", "wallet");
        } else if (this.cardByCard) {
          formData.append("pay_type", "cardByCard");
        }
        formData.append(
          "cardByCardTransactionId",
          this.vo.cardByCardTransactionId ? this.vo.cardByCardTransactionId : ""
        );
        if (this.created_at) {
          formData.append("created_at", this.created_at);
        }
        formData.append(
          "description",
          this.vo.description ? this.vo.description : ""
        );
        formData.append("_method", this.mode == "edit" ? "put" : "post");
        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response, "success");
        this.$router.push("/admin/virtual-order");
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
  },
  watch: {
    selectedUser(newUser) {
      this.userAddresses = [];
      if (newUser) {
        this.$axios
          .get(`admin/userSajad/${newUser.id}/addresses`)
          .then((res) => {
            res.data.data.addresses.forEach((add) => {
              let obj = add;
              obj.title = `${add.first_name} ${add.last_name} -${add.address}`;
              this.userAddresses.push(obj);
            });
          });
      }
    },
  },
  computed: {
    totalWithDiscount() {
      return this.total - this.vo.discount_amount;
    },
    totalPrice() {
      if (this.vo.varieties != "") {
        if (this.getShippingPrice != 0) {
          return this.totalWithDiscount + this.getShippingPrice;
        } else {
          return this.totalWithDiscount;
        }
      } else {
        return 0;
      }
    },
    total() {
      if (this.vo.varieties != "") {
        return this.vo.varieties.reduce((sum, item) => {
          sum += item.amount * item.quantity;
          return sum;
        }, 0);
      } else {
        return 0;
      }
    },
    getShippingPrice() {
      return getShippingPrice(
        this.vo.address,
        this.vo.shipping,
        this.totalWithDiscount,
        this.totalQuantity
      );
    },
    totalQuantity() {
      if (!this.vo || this.vo.varieties == "") {
        return 0;
      }
      return this.vo.varieties.reduce((total, item) => {
        return total + item.quantity;
      }, 0);
    },
  },
};
