<template>
  <main class="mt-5 admin-slider">
    <span id="add-btn">
      <button @click="newItem" class="btn btn-success">درصد جدید</button>
    </span>

    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">لیست درصد</h5>
        <div style="flex: 1"></div>
      </div>
      <div class="card-body">
        <b-table responsive hover bordered :items="items" :fields="fields">
          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | persianDate }}
          </template>
        </b-table>
        <Loading v-if="items == null" />
      </div>
    </div>
    <b-modal id="unitPrice-modal" hide-footer title="ثبت درصد جدید">
      <div id="unitPrice-modalContainer">
        <b-form-group label="عنوان را وارد کنید">
          <b-form-input v-model="title" type="text"> </b-form-input>
        </b-form-group>
        <b-form-group label="درصد را وارد کنید">
          <b-form-input v-model="unit_price_percentage" type="number">
          </b-form-input>
        </b-form-group>

        <b-button
          type="button"
          @click="saveData()"
          variant="primary"
          :disabled="disabled"
        >
          ثبت
        </b-button>
      </div>
    </b-modal>
  </main>
</template>
<script>
import calcUnitPriceImpl from "@@/core/pages/calcUnitPrice/calcUnitPriceImpl";
export default {
  extends: calcUnitPriceImpl,
};
</script>
