<template>
  <b-modal
    ok-only
    ok-title="بستن"
    hide-footer
    ok-variant="warning"
    id="coupen-info"
    title="تخفیف"
  >
    <b-form-group label="عنوان تخفیف:">
      <b-form-input v-model="title"> </b-form-input>
    </b-form-group>
    <b-form-group label="تاریخ شروع :">
      <date-picker
        type="datetime"
        format="YYYY-MM-DD HH:mm"
        display-format="jYYYY-jMM-jDD HH:mm"
        v-model="start_date"
      />
    </b-form-group>
    <b-form-group label="تاریخ پایان :">
      <date-picker
        type="datetime"
        format="YYYY-MM-DD HH:mm"
        display-format="jYYYY-jMM-jDD HH:mm"
        v-model="end_date"
      />
    </b-form-group>
    <b-button :disabled="disable" variant="primary" @click="submit()"
      >ثبت</b-button
    >
  </b-modal>
</template>

<script>
import { BModal, BFormGroup, BFormInput, BButton } from "bootstrap-vue";

export default {
  components: {
    "b-modal": BModal,
    BFormGroup,
    BFormInput,
    BButton,
  },
  props: {
    editedItem: Object,
  },
  watch: {
    editedItem(newV) {
      if (newV) {
        this.title = newV.title;
        this.start_date = newV.start_date;
        this.end_date = newV.end_date;
      }
    },
  },
  mounted() {
    if (this.editedItem) {
      this.title = this.editedItem.title;
      this.start_date = this.editedItem.start_date;
      this.end_date = this.editedItem.end_date;
    }
  },
  data() {
    return {
      title: "",
      start_date: "",
      end_date: "",
      disable: false,
    };
  },
  methods: {
    submit() {
      this.disable = true;
      let fd = new FormData();
      fd.append("title", this.title);
      fd.append("start_date", this.start_date);
      fd.append("end_date", this.end_date);
      if (this.editedItem) {
        fd.append("_method", "PUT");
      }
      this.$axios
        .post(
          `admin/specificDiscount${
            this.editedItem ? "/" + this.editedItem.id : ""
          }`,
          fd
        )
        .then((res) => {
          this.$root.notify(res, "success");
          this.$root.$emit("bv::hide::modal", "coupen-info");
          this.$emit("formSubmited");
          this.disable = false;
        })
        .catch((error) => {
          this.disable = false;

          this.$root.notify(error);
        });
    },
  },
};
</script>

<style scoped></style>
