<template>
  <main class="mt-5">
    <span id="add-btn">
      <router-link
        to="/admin/products/create"
        :disabled="hasPermission('write_product') ? false : true"
        class="btn btn-success"
        >محصول جدید</router-link
      >
    </span>
    <b-tooltip
      v-if="!hasPermission('write_product')"
      target="add-btn"
      triggers="hover"
    >
      شما به این بخش دسترسی ندارید
    </b-tooltip>
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">محصولات</h5>
        <div style="flex: 1"></div>
        <b-button
          v-b-toggle.filter
          :variant="hasFilter() ? 'secondary' : 'primary'"
        >
          <span class="fe fe-search pt-1" style="font-size: 17px"></span>
        </b-button>
      </div>
      <b-collapse id="filter" class="mt-2 mx-5">
        <b-card>
          <form @submit.prevent="filterItem()">
            <div class="row mt-5">
              <b-form-group class="col-md-1">
                <b-form-input
                  :disabled="disabled"
                  placeholder="شناسه"
                  v-model="filter.id"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-4">
                <b-form-input
                  :disabled="disabled"
                  placeholder="عنوان"
                  v-model="filter.title"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-3">
                <multiselect
                  v-if="!$root.isEmptyObject(widgetData)"
                  placeholder="دسته بندی"
                  v-model="filter.category_id"
                  open-direction="bottom"
                  :options="categories"
                  label="title"
                  track-by="id"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-3">
                <multiselect
                  v-if="!$root.isEmptyObject(widgetData)"
                  placeholder="تامین کننده"
                  v-model="filter.provider_id"
                  open-direction="bottom"
                  :options="providers"
                  label="full_name"
                  track-by="id"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-2">
                <multiselect
                  placeholder="وضعیت"
                  v-model="filter.status"
                  open-direction="bottom"
                  :options="statusOptions"
                  label="name"
                  track-by="value"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-2">
                <multiselect
                  placeholder="وضعیت تایید"
                  v-model="filter.approved_at"
                  open-direction="bottom"
                  :options="approveOptions"
                  label="name"
                  track-by="value"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-2">
                <multiselect
                  placeholder="وضعیت تخفیف"
                  v-model="filter.has_dicount"
                  open-direction="bottom"
                  :options="has_dicountOptions"
                  label="name"
                  track-by="value"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>
            </div>
            <div class="text-center mt-6">
              <button
                :disabled="disabled"
                type="submit"
                class="btn btn-primary px-5"
              >
                جستجو
              </button>
              <button
                :disabled="disabled"
                @click="resetFilter"
                class="btn btn-warning px-5 mr-3"
              >
                برداشتن فیلتر
              </button>
              <button
                :disabled="disabled"
                @click="$root.$emit('bv::toggle::collapse', 'filter')"
                class="btn btn-secondary px-5 mr-3"
              >
                بستن
              </button>
            </div>
          </form>
        </b-card>
      </b-collapse>
      <div class="card-body">
        <div>
          <b-table hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(title)="data">
              <router-link
                v-if="data.item.status != 'draft'"
                target="_blank"
                :to="`/product/${data.item.id}`"
              >
                <span
                  v-b-tooltip.hover="{
                    placement: 'bottom',
                    customClass: data.item.title.length <= 25 ? 'd-none' : '',
                  }"
                  :title="data.item.title"
                >
                  {{ data.item.title.substr(0, 25) }}
                  {{ data.item.title.length > 25 ? "..." : "" }}
                </span>
              </router-link>
              <span
                v-else
                v-b-tooltip.hover="{
                  placement: 'bottom',
                  customClass: data.item.title.length <= 25 ? 'd-none' : '',
                }"
                :title="data.item.title"
              >
                {{ data.item.title.substr(0, 25) }}
                {{ data.item.title.length > 25 ? "..." : "" }}
              </span>
            </template>
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(approved_at)="data">
              <span
                @click="(e) => approveForm(e, data.item)"
                v-if="data.item.approved_at"
                class="approve text-success fa fa-check-circle-o"
              ></span>
              <span
                @click="(e) => approveForm(e, data.item)"
                v-else
                class="approve text-danger fa fa-times-circle-o"
              ></span>
            </template>
            <template v-slot:cell(status)="data">
              <span
                v-if="data.item.status === 'out_of_stock'"
                class="badge badge-danger status"
                >ناموجود</span
              >
              <span
                v-else-if="data.item.status === 'available'"
                class="badge badge-success status"
                >موجود</span
              >
              <span
                v-else-if="data.item.status === 'soon'"
                class="badge badge-primary status"
                >بزودی</span
              >
              <span
                v-else-if="data.item.status === 'draft'"
                class="badge badge-info status"
                >پیش نویس</span
              >
            </template>
            <template v-slot:cell(main_price)="data">
              {{ data.item.sell_price | price }}
            </template>
            <template v-slot:cell(online_agents_price)="data">
              {{ data.item.online_agents_price | price }}
            </template>
            <template v-slot:cell(categories)="data">
              <span
                v-if="data.item.categories.length > 1"
                @mouseenter="showCat(data.item.id)"
                @mouseleave="hideCat(data.item.id)"
                class="badge badge-success-light status position-relative"
                >{{ data.item.categories.length }}
                <transition name="fast-fade">
                  <div
                    v-if="showingCategories.includes(data.item.id)"
                    class="category-tooltip"
                  >
                    <span v-for="cat in data.item.categories" :key="cat.id">{{
                      cat.title
                    }}</span> 
                  </div>
                </transition>
                دسته بندی</span
              >
              <span class="badge badge-success status" v-else>
                {{
                  data.item.categories[0]
                    ? data.item.categories[0].title
                    : "ندارد"
                }}
              </span>
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <router-link
                  @mouseenter.native="savePage()"
                  :to="'/admin/products/edit/' + data.item.id"
                  :disabled="!hasPermission('modify_product') ? true : false"
                  class="action-btns1 pt-1 px-2"
                  @click="editItem(data.index)"
                >
                  <i class="feather feather-edit text-primary"></i>
                </router-link>
              </span>
              <b-tooltip
                v-if="!hasPermission('modify_product')"
                :target="'edit-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a
                  :disabled="!hasPermission('delete_product') ? true : false"
                  class="action-btns1 pt-1 px-2"
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip
                v-if="!hasPermission('delete_product')"
                :target="'delete-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
            <template v-slot:cell(excell)="data">
              <button
                type="button"
                class="btn btn-light"
                @click="downloadExcell(data.item.id)"
              >
                <span class="fa fa-download"></span>
              </button>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">موردی یافت نشد</div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ProductsImpl from "@@/core/pages/product/Products/ProductsImpl";
export default {
  extends: ProductsImpl,
};
</script>

<style scoped src="@@/core/pages/product/Products/ProductsStyle.css"></style>
