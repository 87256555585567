import ExcelDownload from "@@/core/components/excelDownload/ExcelDownload";
import {BForm,BFormFile,BButton}from "bootstrap-vue"
export default {
  name: "Post",
  components: {ExcelDownload ,BForm,BFormFile,BButton},

  data() {
    return {
 file:null,
 disable:false,
    }
  },
  
  methods: {
    sendExcel(){
        this.disable=true;
const formD=new FormData();
formD.append("file",this.file)
        this.$axios.post("admin/varieties/update",formD).then((res)=>{
            this.disable=false;
            this.$root.notify(res.data.message, 'success');
            this.file=null;

        }).catch(()=>{
            this.disable=false;
        })
    }
  }
}
