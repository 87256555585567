import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";
import Swal from "sweetalert2";
import {
  BTooltip,
  BButton,
  BCard,
  BTable,
  BCollapse,
  BFormGroup,
  BFormInput,
  VBToggle,
  VBTooltip,
} from "bootstrap-vue";
export default {
  name: "Products",
  mixins: [mixins],
  components: {
    Loading,
    BTooltip,
    BButton,
    BCard,
    BTable,
    BCollapse,
    BFormGroup,
    BFormInput,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      url: "admin/varietyTransfers",
      widgets: ["categories"],
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "quantity",
          label: "تعداد",
        },
        {
          key: "from_location",
          label: "از کجا",
        },
        {
          key: "to_location",
          label: "به کجا",
        },
        {
          key: "mover",
          label: "انتقال دهنده",
        },
        {
          key: "receiver",
          label: "تحویل گیرنده",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "delete",
          label: "حذف",
        },
        {
          key: "delete",
          label: "حذف",
        },
        {
          key: "print",
          label: "کنترل و پرینت",
        },
      ],
      showingCategories: [],
      statusOptions: [
        {
          value: "available",
          name: "موجود",
        },
        {
          value: "soon",
          name: "بزودی",
        },
        {
          value: "out_of_stock",
          name: "ناموجود",
        },
        {
          value: "draft",
          name: "پیش نویس",
        },
        {
          value: "",
          name: "همه",
        },
      ],
      approveOptions: [
        {
          name: "تایید شده",
          value: "1",
        },
        {
          name: "تایید نشده",
          value: "0",
        },
        {
          name: "همه",
          value: "",
        },
      ],
      filter: {
        id: "",
        title: "",
        category_id: "",
        status: "",
        approved_at: "",
      },
    };
  },
  mounted() {
    this.loadItems();
  },

  methods: {
    getTrClass(item) {
      if (item.is_delete == 1) {
        return "deleteRow";
      }
    },
    async downloadExcell(product_id) {
      const response = await this.$axios(`admin/products/${product_id}/excel`, {
        headers: {
          Accept: "x-xlsx",
        },
        params: {
          type: 1,
        },
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {},
      });
      this.disabled = false;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", product_id + ".xls");
      document.body.appendChild(link);
      link.click();
    },

    showCat(p) {
      if (!this.showingCategories.includes(p)) {
        this.showingCategories.push(p);
      }
    },
    hideCat(p) {
      this.showingCategories = this.showingCategories.filter((_p) => {
        return _p !== p;
      });
    },
    async filterItem(page = 1) {
      this.searchParams = {
        search1: this.filter.id,
        searchBy1: "id",
        search2: this.filter.title,
        searchBy2: "title",
        search3:
          this.filter.category_id != null ? this.filter.category_id.id : "",
        searchBy3: "category_id",
        search4: this.filter.status ? this.filter.status.value : "",
        searchBy4: "status",
        search5: this.filter.approved_at ? this.filter.approved_at.value : "",
        searchBy5: "approved_at",
      };
    },
    async approveForm(e, product) {
      const el = e.target;
      let bounding = el.getBoundingClientRect();
      this.$nextTick(() => {
        const swalForm = document.querySelector(
          "body.swal2-toast-shown .swal2-container.swal2-center"
        );
        swalForm.style.left = bounding.x + "px";
        swalForm.style.top = bounding.y + "px";
        swalForm.style.right = "unset";
      });
      if (!this.hasPermission("approved_product")) {
        return;
      }
      const approveOrDeny = (approve) => {
        return this.$axios
          .post(
            `admin/products/${product.id}/${approve ? "approve" : "disapprove"}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const message = error.data.message
              ? error.data.message
              : `درخواست با مشکل مواجه شد: ${error.data.message}`;
            Swal.showValidationMessage(message);
          });
      };

      const value = await Swal.fire({
        title: "تایید یا رد محصول شماره " + product.id,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `تایید`,
        denyButtonText: `رد`,
        cancelButtonText: `لغو عملیات`,
        toast: true,
        preConfirm: (result) => {
          return approveOrDeny(true);
        },
        preDeny: (result) => {
          return approveOrDeny(false);
        },
      }).then((result) => {
        if (result.isConfirmed) {
          product.approved_at = this.moment();
          Swal.fire({
            title: `محصول با موفقیت تایید شد`,
            confirmButtonText: `باشه`,
          });
        } else if (result.isDenied) {
          product.approved_at = null;
          Swal.fire({
            title: `محصول با موفقیت رد شد`,
            confirmButtonText: `باشه`,
          });
        }
      });
    },
    deleteItem(id) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.$axios
              .delete(`${this.url}/${id}`)
              .then((response) => {
                this.loadItems();
                window.swal({
                  icon: "success",
                  showConfirmButton: true,
                  text: response.data.message,
                  button: "باشه",
                });
              })
              .catch((error) => {
                window.swal({
                  title: "خطا",
                  showConfirmButton: true,
                  text: error.response.data.message,
                  button: "باشه",
                });
              });
          }
        })
        .catch(() => {});
    },
  },
};
