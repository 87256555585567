<template>
  <tr>
    <td>
      <span style="line-height: 2">{{ item.variety.barcode }}</span>
    </td>
    <td>
      <router-link
        style="max-width: 300px; text-decoration: none; color: inherit"
        class="d-inline-block"
        :to="'/admin/products/edit/' + item.product.id"
        v-if="item.product"
      >
        <span style="line-height: 2">{{ getProductTitle(item) }}</span>
        <template
          v-if="
            !$options.configProvider.get('physicalShop.dontShowFullNameInPrint')
          "
        >
          <span v-if="item.extra.color || item.extra.attributes != ''">
            <span> | </span>
            <span v-if="item.extra.color">
              رنگ : {{ item.extra.color.name }}
              {{ item.extra.attributes != "" ? " | " : "" }}
            </span>
            <span v-if="item.extra.attributes != ''">
              <span v-for="(attr, index) in item.extra.attributes" :key="index">
                {{ attr.label + ": " + attr.pivot.value }}
                {{ index != item.extra.attributes.length - 1 ? " | " : "" }}
              </span>
            </span>
          </span>
        </template>
      </router-link>
    </td>
    <td>{{ item.quantity }}</td>
    <td>{{ getPrice(item.amount + item.discount_amount) | price }}</td>
    <td>{{ getPrice(item.discount_amount * item.quantity) | price }}</td>
    <td>
      {{
        getPrice(
          (item.amount + item.discount_amount) * item.quantity -
            item.discount_amount * item.quantity
        ) | price
      }}
    </td>
  </tr>
</template>

<script>
import configProvider from "@@/core/configProvider";

export default {
  name: "ShopShowItem",
  props: {
    item: Object,
  },
  configProvider,
  methods: {
    getProductTitle(item) {
      let title = "";
      if (item.product) {
        title = item.product.title;
      }
      if (item.variety && item.variety.color) {
        title += ` - ${item.variety.color.name}`;
      }
      if (
        item.variety &&
        item.variety.attributes &&
        item.variety.attributes.length
      ) {
        item.variety.attributes.forEach((attr) => {
          title += ` - ${attr.pivot ? attr.pivot.value : ""}`;
        });
      }
      return title;
    },
    getPriceName() {
      return configProvider.get("physicalShop.printPrice") === "rial"
        ? "ریال"
        : "تومان";
    },
    getPrice(price) {
      return configProvider.get("physicalShop.printPrice") === "rial"
        ? price * 10
        : price;
    },
  },
};
</script>

<style scoped></style>
