import { getShippingPrice, setShipping } from "@@/core/libraries/functions";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BModal,
  BFormTextarea,
} from "bootstrap-vue";
export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
  },
  created() {
    if (this.$root.isPart("admin")) {
      this.auth = "admin";
    }
  },
  props: { value: Object, addresses: Array, shippings: Array },
  data() {
    return {
      mode: "create",
      order: this.value,
      addressShippings: [],
      disabled: false,
      customer: {},
      address: {},
      showAddressForm: false,
      adminProvinces: null,
      baseUrl: "/addresses",
      url: "",
      cities: null,
      disabled: false,
      auth: "front",
      address: null,
    };
  },
  computed: {
    provinces() {
      if (this.auth === "admin") {
        return this.adminProvinces;
      } else {
        return this.$store.getters["front/getProvincesData"];
      }
    },
    getShippingPrice() {
      return getShippingPrice(this.order.address, this.order.shipping, null);
    },
  },
  watch: {
    "address.province": function(newVal) {
      if (newVal) {
        let cities = this.provinces.filter((item) => item.id == newVal.id)[0]
          .cities;
        this.cities = cities;
      }
    },
    value(newVal) {
      this.order = newVal;
      this.setShippingReal();
      this.customer.id = newVal.customer_id;
    },
    order(newVal) {
      this.$emit("input", newVal);
    },
    "order.address"(newVal) {
      this.setShippingReal();
    },
    addresses(newVal) {
      let addresses = newVal.map((ad) => {
        ad.label =
          ad.city.province.name +
          " ، " +
          ad.city.name +
          " ، " +
          ad.address +
          " - گیرنده : " +
          ad.first_name +
          " " +
          ad.last_name +
          " - کد پستی : " +
          ad.postal_code;
        return ad;
      });
      return addresses;
    },
  },
  methods: {
    resetVeeValidate() {
      this.$refs.observer.reset();
    },
    setCities(items) {
      this.cities = items.cities;
    },
    async submitAddress(e) {
      // return console.log(this.order.address);
      this.disabled = true;
      this.mode == "edit"
        ? (this.url = this.baseUrl + "/" + this.address.id)
        : (this.url = this.baseUrl);

      try {
        let formData = new FormData(e.target);
        // formData.append("city", this.address.city ? this.address.city.id : "");
        formData.append("_method", this.mode == "edit" ? "put" : "post");
        formData.append(
          "customer_id",
          this.auth === "admin" ? this.address.customer_id : ""
        );
        const response = await this.$axios.post(this.url, formData);
        // const response = await this.$axios.post(this.url, {
        //   city: this.address.city.id,
        //   first_name: this.address.first_name,
        //   last_name: this.address.last_name,
        //   postal_code: this.address.postal_code,
        //   mobile: this.address.mobile,
        //   address: this.address.address,
        //   _method: this.mode == "edit" ? "put" : "post",
        //   customer_id: this.auth === "admin" ? this.address.customer_id : "",
        // });
        // this.addressShippings.push(response.data.data.address);
        this.$emit("addNewAddressToOrder",response.data.data.address)
        this.showAddressForm=false;
        this.$root.notify(response.data.message, "success");
        this.cities = null;
      } catch (error) {
        this.$root.notify(error);
      }

      this.disabled = false;
    },
    async getNeededInfo() {
      const response = await this.$axios.get("admin/orders/create", {
        params: {
          without_customers: true,
        },
      });
      // this.customers = response.data.data.customers;
      // this.shippings = response.data.data.shippings;
      // let products = response.data.data.list_products.filter(p => p.total_quantity > 0)
      // this.products = products.map(product => {
      //   product.varieties.forEach(variety => {
      //     variety.product = cloneDeep(product)
      //   });
      //   return product
      // });
      // this.products = products

      this.adminProvinces = response.data.data.provinces;
    },
    addNewAddress(newAddress) {},
    newAddress() {
      this.address = {
        province: null,
        city: null,
        first_name: "",
        last_name: "",
        postal_code: "",
        address: "",
        mobile: "",
        customer_id: null,
      };
      this.address.customer_id = this.customer.id;
      this.addressMode = "create";
      this.showAddressForm = true;
    },
    setShipping(item) {
      // this.order.shipping = null;
      // this.addressShippings = setShipping(item, this.shippings);
    },
    setShippingReal() {
      this.$nextTick(() => {
        this.addressShippings = setShipping(this.order.address, this.shippings);
      });
    },
    async submit() {
      //
      this.disabled = true;
      try {
        const response = await this.$axios.post(
          "admin/orders/" + this.order.id,
          {
            address_id: this.order.address ? this.order.address.id : "",
            shipping_id: this.order.shipping ? this.order.shipping.id : "",
            discount_amount: this.order.discount_amount,
            description: this.order.description,
            _method: "put",
          }
        );
        this.$emit(
          "order-update",
          response.data.data.order,
          this.getShippingPrice
        );
        this.$root.notify(response.data.message, "success");
        this.$root.$emit("bv::hide::modal", "order-edit");
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
  },
  mounted() {
    this.getNeededInfo();
    if (this.auth === "admin") {
      this.baseUrl = "admin/customers" + this.baseUrl;
    } else {
      if (this.$store.getters[this.auth + "/getProvincesData"] == null) {
        this.$store.dispatch(this.auth + "/getProvincesDataFromServer");
      }
      this.baseUrl = "customer" + this.baseUrl;
    }
  },
};
