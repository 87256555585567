import Loading from "@@/core/components/shared/Loading/Loading";
import FormModal from "@@/core/libraries/form/FormModal";
import { BForm,BFormGroup,BFormInput,BButton, VBModal } from "bootstrap-vue";

export default {
  name: "faqs",
  components: {
    Loading,
    FormModal,BForm,
    BFormGroup,BFormInput,BButton,
  },

  data() {
    return {
      userNumber: null,
      url: "admin/customer/tokens",
      errMessage:[],
      token:null,
      disabled: false,
    };
  },

  methods: {
    generatePassword() {
      const formD = new FormData();
      formD.append("mobile", this.userNumber);
      this.disabled = true;
      this.errMessage=[];
      this.token=null;
      this.$axios
        .post(this.url, formD)
        .then((res) => {
          this.disabled = false;
          this.errMessage=[];
          this.token=res.data.data.token;

          console.log(res);
        })
        .catch((error) => {
            this.token=null;
          this.disabled = false;
          this.errMessage=error.response.data.errors.mobile;

        });
    },
  },
};
