import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";
import FormModal from "@@/core/components/discountList/modal/modal.vue";
import {
  BButton,
  BCard,
  BCollapse,
  BFormGroup,
  BFormInput,
  BTable,
  BTooltip,
  VBToggle,
  BModal,
} from "bootstrap-vue";

export default {
  name: "Gift",
  components: {
    FormModal,
    Loading,
    BTooltip,
    BButton,
    BCollapse,
    BCard,
    BFormGroup,
    BFormInput,
    BTable,
    BModal,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  mixins: [mixins],

  data() {
    return {
      url: "admin/specificDiscountType",
      types: [
        { title: "درصدی", id: "percentage" },
        { title: "ثابت", id: "flat" },
      ],
      type: "",
      amount: null,
      list: null,
      mainData: "",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "discount",
          label: "تخفیف",
        },

        {
          key: "discount_type",
          label: "نوع تخفیف",
        },

        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
        {
          key: "add",
          label: "افزودن",
        },
      ],
      disable: false,
      editedItem: null,
    };
  },
  mounted() {
    this.url += `/${this.$route.params.id}`;
    this.loadData();
    this.loadMainData();
  },
  methods: {
    saveToStorage(item) {
      localStorage.setItem(
        "discountName",
        `${item.discount}${item.discount_type == "percentage" ? "%" : "تومان"}`
      );

      this.$router.push(
        `/admin/discount-config/list/${this.$route.params.id}/${item.id}`
      );
    },
    editItem(item) {
      this.editedItem = item;
      this.type = this.types.find((i) => item.discount_type == i.id);
      this.amount = item.discount;
      this.$root.$emit("bv::show::modal", "coupen-info");
    },
    newDiscount() {
      this.type = "";
      this.amount = null;
      this.editedItem = null;
      this.$root.$emit("bv::show::modal", "coupen-info");
    },
    loadMainData() {
      this.$axios
        .get(`admin/specificDiscount/${this.$route.params.id}`)
        .then((res) => {
          this.mainData = res.data.data.specificDiscount;
        });
    },
    loadData() {
      this.$axios
        .get(`admin/specificDiscountType/${this.$route.params.id}`)
        .then((res) => {
          this.list = res.data.data.types;
        });
    },
    submit() {
      this.disable = true;
      let fd = new FormData();
      fd.append("discount", this.amount);
      fd.append("discount_type", this.type ? this.type.id : "");
      if (this.editedItem) {
        fd.append("_method", "PUT");
      }
      this.$axios
        .post(
          `admin/specificDiscountType/${this.$route.params.id}${
            this.editedItem ? "/" + this.editedItem.id : ""
          }`,
          fd
        )
        .then((res) => {
          this.$root.$emit("bv::hide::modal", "coupen-info");
          this.$root.notify(res, "success");
          this.disable = false;
          this.loadData();
        })
        .catch((error) => {
          this.$root.notify(error);
          this.disable = false;
        });
    },
    deleteItem(id) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.$axios
              .delete(url + `/${id}`)
              .then((response) => {
                this.loadData();
                window.swal({
                  icon: "success",
                  showConfirmButton: true,
                  text: response.data.message,
                  button: "باشه",
                });
              })
              .catch((error) => {
                window.swal({
                  title: "خطا",
                  showConfirmButton: true,
                  text: error.response.data.message,
                  button: "باشه",
                });
              });
          }
        })
        .catch(() => {});
    },
  },
};
