export default {
  data() {
    return {
      selectedMode: 'online',
    }
  },
  computed: {
    reportTypeOptions() {
      return [{name: 'online', label: 'آنلاین'}, {name: 'mini', label: 'حضوری'}]
    },
    reportCode() {
      return this.selectedMode === 'online' ? 0 : 1
    }
  },
}
