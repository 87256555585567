<template>
  <div class="border rounded mt-4">
    <h5
      class="text-center rounded py-2 font-bold"
      :class="{
        'bg-light': editMode == false,
        'bg-primary': editMode == true,
        'text-white': editMode == true,
      }"
    >
      {{
        `${
          editMode == true
            ? "ویرایش اطلاعات دریافت کننده"
            : "اطلاعات دریافت کننده"
        }`
      }}
    </h5>
    <div v-if="!editMode" class="row px-4 pb-4">
      <div class="col-md-4 d-flex mt-2" v-if="order.address">
        <h6 class="font-bold">نام و نام خانوادگی :</h6>
        <h6 class="pr-1">
          {{ order.address.first_name + " " + order.address.last_name }}
        </h6>
      </div>
      <div class="col-md-4 d-flex mt-2" v-if="order.address">
        <h6 class="font-bold">موبایل :</h6>
        <h6 class="pr-1">
          {{ order.address.mobile }}
        </h6>
      </div>
      <div class="col-md-4 d-flex mt-2" v-if="order.address">
        <h6 class="font-bold">کد پستی :</h6>
        <h6 class="pr-1">
          {{ order.address.postal_code }}
        </h6>
      </div>
      <div class="col-md-12 d-flex mt-2" v-if="order.address">
        <h6 class="font-bold">آدرس :</h6>
        <h6 class="pr-1">
          <span v-if="order.address.city"
            >{{
              order.address.city.province
                ? order.address.city.province.name + " - "
                : ""
            }}
            {{ order.address.city.name + " - " }}</span
          >
          <span>{{ order.address.address }}</span>
        </h6>
      </div>
    </div>
    <div v-else class="pr-4 pb-4">

    <ValidationObserver  v-slot="{ handleSubmit }" ref="observer">
      <form
        id="address-form"
        @submit.prevent="(e) => handleSubmit(() => submitAddress(e))"
      >
        <b-row>
          <b-col md="6" v-if="provinces != null" class="text-right">
            <ValidationProvider
              name="province"
              mode="passive"
              class="mb-3 d-flex flex-column text-right"
              rules="required"
              v-slot="v"
            >
            
            <label for="">استان :</label>
              <div class="variety-title ">
                <multiselect
                v-model="address.province"
                :allow-empty="false"
                placeholder="استان"
                open-direction="bottom"
                :options="provinces"
                label="name"
                track-by="id"
                :searchable="true"
                :multiple="false"
                :close-on-select="true"
                :show-labels="false"
                :disabled="disabled"
              >
                <template slot="noOptions">
                  موردی یافت نشد
                </template>
                <template slot="noResult">
                  موردی یافت نشد
                </template>
              </multiselect>
              </div>
             
              <span class="text-right text-danger mt-2 mx-2">{{
                v.errors[0]
              }}</span>
             
            </ValidationProvider>
          </b-col>
          <b-col md="6" class="text-right">
            <ValidationProvider
              v-if="cities != null"
              name="city"
              mode="passive"
              class="mb-3 d-flex flex-column text-right"
              rules="required"
              v-slot="v"
            >
              <label for="">شهر :</label>
              <div class="variety-title ">
                <multiselect
                  v-model="address.city"
                  :allow-empty="false"
                  placeholder="شهر"
                  open-direction="bottom"
                  :options="cities"
                  label="name"
                  track-by="id"
                  :searchable="true"
                  :multiple="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
              </div>
              <div v-if="address.city != null">
                <input
                  name="city"
                  @change="v.validate"
                  type="hidden"
                  :value="address.city.id"
                />
              </div>
              <span class="text-right text-danger mt-2 mx-2">{{
                v.errors[0]
              }}</span>
            </ValidationProvider>
          </b-col>
          <b-col md="6" class="text-right">
            <ValidationProvider
              name="first_name"
              mode="passive"
              rules="required"
              v-slot="v"
            >
              <label for="">نام :</label>
              <input
                v-model="address.first_name"
                :disabled="disabled"
                name="first_name"
                @change="v.validate"
                type="text"
                placeholder="نام"
                class="border-radius7 form-control"
                :class="v.errors[0] ? 'border-danger' : ''"
              />
              <span class="text-right text-danger mt-2 mx-2">{{
                v.errors[0]
              }}</span>
            </ValidationProvider>
          </b-col>
          <b-col md="6" class="text-right">
            <ValidationProvider
              name="last_name"
              mode="passive"
              rules="required"
              v-slot="v"
            >
              <label for="">نام خانوادگی :</label>
              <input
                v-model="address.last_name"
                :disabled="disabled"
                name="last_name"
                @change="v.validate"
                type="text"
                placeholder="نام خانوادگی"
                class="border-radius7 form-control"
                :class="v.errors[0] ? 'border-danger' : ''"
              />
              <span class="text-right text-danger mt-2 mx-2">{{
                v.errors[0]
              }}</span>
            </ValidationProvider>
          </b-col>
          <b-col md="6" class="text-right">
            <ValidationProvider
              name="postal_code"
              mode="passive"
              rules="required"
              v-slot="v"
            >
              <label for="">کد پستی :</label>
              <input
                v-model="address.postal_code"
                :disabled="disabled"
                name="postal_code"
                @change="v.validate"
                type="text"
                placeholder="کد پستی"
                class="border-radius7 form-control"
                :class="v.errors[0] ? 'border-danger' : ''"
              />
              <span class="text-right text-danger mt-2 mx-2">{{
                v.errors[0]
              }}</span>
            </ValidationProvider>
          </b-col>
          <b-col md="6" class="text-right">
            <ValidationProvider
              name="mobile"
              mode="passive"
              rules="required"
              v-slot="v"
            >
              <label for="">موبایل :</label>
              <input
                v-model="address.mobile"
                :disabled="disabled"
                name="mobile"
                @change="v.validate"
                type="text"
                placeholder="موبایل"
                class="border-radius7 form-control"
                :class="v.errors[0] ? 'border-danger' : ''"
              />
              <span class="text-right text-danger mt-2 mx-2">{{
                v.errors[0]
              }}</span>
            </ValidationProvider>
          </b-col>
          <b-col md="12" class="text-right">
            <ValidationProvider
              name="address"
              mode="passive"
              rules="required"
              v-slot="v"
              class="form-group"
            >
              <label for="">آدرس :</label>
              <textarea
                v-model="address.address"
                :disabled="disabled"
                name="address"
                @change="v.validate"
                type="text"
                class="border-radius7 form-control"
                :class="v.errors[0] ? 'border-danger' : ''"
              ></textarea>
              <span class="text-right text-danger mt-2 mx-2">{{
                v.errors[0]
              }}</span>
            </ValidationProvider>
          </b-col>
        </b-row>
        <div class="text-center mt-3">
          <button
          v-if="editMode==true&&statusReq==false"
            class="btn"
            :disabled="disabled"
            :class="mode == 'create' ? 'btn-success' : 'btn-primary'"
            type="submit"
          >
          ویرایش آدرس
          </button>
          <button type="button" class="btn btn-success" v-else>
            درحال بروز رسانی
          </button>
          <button
          type="button"
            v-if="statusReq == false && editMode == true"
            @click="editMode = false"
            class="btn btn-warning mr-4 "
          >
            لغو
          </button>
        </div>
      </form>
    </ValidationObserver>
    </div>
   
  </div>
</template>

<script>
import OrderAddressImImpl from "@@/core/components/virtualOrder/OrderAddress/OrderAddressImImpl";

export default {
  extends: OrderAddressImImpl,
};
</script>

<style scoped></style>
