import {BFormGroup, BFormInput, BFormTextarea, BFormFile, BFormCheckbox} from "bootstrap-vue";
import Ckeditor from '@@/core/components/shared/Ckeditor/Ckeditor'

export default {
  name: "CategoryForm",
  components: {
    BFormGroup, BFormInput, BFormTextarea, BFormFile, BFormCheckbox,Ckeditor
  },
  props: {
    mode: String,
    options: Array,
    attributes: Array,
    specifications: Array,
    brands: Array,
    // value: {
    //   default: 0,
    //   type: Number
    // },
    url: String,
    category: Object,
  },
  watch: {
    category(newVal) {
      this.parentId = newVal.parent_id
    }
  },
  data() {
    return {
      shouldShowCount: false,
      disabled: false,
      parentId: this.category.parent_id
    }
  },
  mounted() {
    if (typeof this.value === 'string') {
      this.value = Number.parseInt(this.value)
    }
  },
  computed: {
    realOptions() {
      let temp = this.$root.addLabel(this.options)
      temp.unshift({
        label: 'ندارد',
        children: [],
        id: 0
      });

        return temp
      }
    },
    methods: {
      setDisabled(val) {
        this.disabled = val
      },
      async submit(e) {
        e.preventDefault()
        this.disabled = true

        try {
          let formData = new FormData(e.target)
          // formData.append('type', this.specification.type ? this.specification.type.value : '')
          formData.append('_method', this.mode == 'edit' ? 'put' : 'post')
          formData.append('description', this.category.description)
          formData.append('status', this.category.status)
          
          const response = await this.$axios.post(this.url, formData);
          this.$root.notify(response, 'success')
          this.$router.push('/admin/categories')
        } catch (error) {
          this.$root.notify(error)
        } finally {
          this.disabled = false
        }

      },
    },
}
