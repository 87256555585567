import Loading from '@@/core/components/shared/Loading/Loading'
import {uniqueId} from '@@/core/libraries/VueMethods'
import Dropdown from "@@/core/components/shared/Dropdown/Dropdown";
import dateFilterMixin from "@@/core/mixins/dateFilterMixin";
import {isPermissionError} from "@/core/libraries/functions";

export default {
  name: "Chart1",
  mixins: [dateFilterMixin],
  components: {
    Loading,
    Dropdown
  },
  props: {
    labels: Array,
    mode: {
      default: 'online',
      type: String,
    },
    datasets: Array // {label: String, Data: Array}
  },
  data() {
    return {
      id: uniqueId(),
      chart: null,
      realLabels: this.labels,
      realDatasets: this.datasets,
      loading: false,
    }
  },
  mounted() {
    this.loadType('month')
    // this.loadChart()
  },
  methods: {
    loadChart() {
      if (this.chart) {
        this.chart.destroy()
      }
      let dataSets = [];
      let colors = ['#3366ff', "#8492a6"];
      this.realDatasets.forEach((dataset, index) => {
        let color = colors[index]
        dataSets.push({
          label: dataset.label,
          data: dataset.data,
          borderWidth: 3,
          backgroundColor: color,
          borderColor: color,
          pointBackgroundColor: color,
          pointRadius: 0,
          type: 'bar',
        })
      })
      // eslint-disable-next-line no-undef
      Chart.defaults.global.defaultFontFamily = 'iranSans';
      let ctx = document.getElementById(this.id)
      if (!ctx) {
        return
      }
      ctx.getContext('2d');
      // ctx.height = 400;
      // eslint-disable-next-line no-undef
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.realLabels,
          datasets: dataSets
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          },
          tooltips: {
            enabled: true,
          },
          scales: {
            yAxes: [{
              gridLines: {
                display: true,
                drawBorder: false,
                zeroLineColor: 'rgba(142, 156, 173,0.1)',
                color: "rgba(142, 156, 173,0.1)",
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                // stepSize: 5,
                // suggestedMin: 5,
                // suggestedMax: 30,
                fontColor: "#8492a6",
              },
            }],
            xAxes: [{
              barValueSpacing: -2,
              barDatasetSpacing: 0,
              barRadius: 15,
              stacked: false,
              categoryPercentage: 0.3,
              barPercentage: 0.8,
              ticks: {
                beginAtZero: true,
                fontColor: "#8492a6",
                fontFamily: "iranSans",
              },
              gridLines: {
                color: "rgba(142, 156, 173,0.1)",
                display: false
              },

            }]
          },
          legend: {
            display: false,
            labels: {
              // This more specific font property overrides the global property
              font: {
                family: "'iranSans'"
              }
            }
          },
          elements: {
            point: {
              radius: 0
            }
          }
        }
      });
    },
    priceFilter(num) {
      if(num) {
          // let englishNumber = this.convertToEnglishNumber(num);
          return num.toString()
              .replace(/\D/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
          return 0
      }
  },
    async loadType(type = null) {
      this.axiosCancelSource = this.$axios.CancelToken.source();
      type = type ? type : this.type;
      if (this.loading) {
        return
      }
      this.type = type;
      try {
        this.loading = true;
        const response = await this.$axios.get('admin/reports/chart1', {
          cancelToken: this.axiosCancelSource.token,
          params: {
            type,
            mode: this.mode,
            offset_year: this.moment().format('YYYY') - this.selectedYear,
            month: this.selectedMonth
          }
        });
        let data = response.data.data.data
        let datasets = [
          {
            label: 'میزان فروش کل',
            data: Array(Object.entries(data).length)
          },
          {
            label: 'هزینه حمل و نقل',
            data: Array(Object.entries(data).length)
          }
        ];
        let labels = this.getTypeLabels(type, Object.entries(data).length);

        for (let [index,value] of Object.entries(data)) {
          // datasets[0].data[index - 1] = this.priceFilter(value.amount)
          datasets[0].data[index - 1] = value.amount
          // datasets[1].data[index - 1] = value.discount_amount
          datasets[1].data[index - 1] = value.shipping_amount
        }
        this.realLabels = labels;
        this.realDatasets = datasets;
        if (typeof Chart === 'undefined') {
          setTimeout(() => {
            this.loadChart()
          }, 1000)
        } else {
          this.loadChart()
        }
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return;
        }
        if (!isPermissionError(error)) {
          this.$root.notify(error);
        }
      }
      this.loading = false
    }
  },
  beforeDestroy() {
    this.axiosCancelSource.cancel()
  }
}
