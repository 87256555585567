<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold py-3">گزارش محصولات</h5>
        <div style="flex: 1"></div>
        <div>
          <Dropdown
            v-if="$options.configProvider.get('physicalShop.active')"
            :disabled="disabled"
            v-model="selectedMode"
            class="d-inline ml-2"
            :options="reportTypeOptions"
          />
          <button @click="print" class="btn btn-primary">
            <span
              style="transform: translateY(3px)"
              class="feather-printer"
            ></span>
          </button>
        </div>
      </div>
      <div class="row px-4 not-in-print">
        <form @submit.prevent="doFilter" class="col-12 row">
          <div class="col-6">
            <DatePickerRange v-model="filter.date" />
          </div>
          <div class="col-12 d-flex justify-content-end">
            <button :disabled="disabled" type="submit" class="btn btn-primary">
              فیلتر
            </button>
          </div>
        </form>
      </div>
      <div id="print" class="p-4">
        <div
          class="d-flex mb-3 mr-2-print"
          v-if="appliedFilter.date.startDate || appliedFilter.date.endDate"
        >
          <div v-if="appliedFilter.date.startDate">
            <span> از تاریخ</span
            ><span> {{ appliedFilter.date.startDate | persianDate }} </span>
          </div>
          <div class="mr-4" v-if="appliedFilter.date.endDate">
            <span> تا تاریخ</span
            ><span> {{ appliedFilter.date.endDate | persianDate }} </span>
          </div>
        </div>
        <div v-if="totals != null" class="row totals">
          <div class="col-md-3">
            <strong>جمع کل</strong>
            <span>
              {{ totals.sum_amount | price }}تومان
            </span>
          </div>
          <div class="col-md-3">
            <strong>جمع تخفیف محصولات</strong>
            <span> {{ totals.sum_product_discount | price }} تومان </span>
          </div>
          <div class="col-md-3">
            <strong>تعداد کل</strong>
            <span>
              {{ totals.sum_quantity | price }}
            </span>
          </div>
        </div>
        <br>
        <b-table
          id="main-table"
          v-if="reports"
          bordered
          striped
          :fields="fields"
          :items="reports"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter-included-fields="['title', 'id']"
          responsive="sm"
        >
          <template v-slot:cell(total_sale)="data">
            {{ data.item.total_sale < 0 ? "-" : ""
            }}{{ data.item.total_sale | price }}
          </template>
          <template v-slot:cell(title)="data">
            <a target="_blank" :href="'/admin/products/edit/' + data.item.id">{{
              data.item.title
            }}</a>
          </template>
          
        </b-table>
        
        <div v-if="!reports" class="alert alert-primary">در حال بارگزاری</div>
      </div>
      <div class="d-flex flex-column w-100">
        <!--        <div>-->
        <!--          <div class="d-flex">-->
        <!--            <div class="p-5" style="border: 1px solid" v-for="field in fields">-->
        <!--              {{ field.label }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="d-flex w-100" v-for="item in reports">-->
        <!--            <div class="p-5" style="border: 1px solid" v-for="field in fields">-->
        <!--              {{ item[field.key] }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </main>
</template>

<script>
import { BTable, BFormGroup, BFormInput } from "bootstrap-vue";
import DatePickerRange from "@@/core/components/shared/DatePickerRange/DatePickerRange";
import Dropdown from "@@/core/components/shared/Dropdown/Dropdown";
import configProvider from "@@/core/configProvider";
import reportMixin from "@/core/mixins/reportMixin";

function Filter() {
  this.date = {
    startDate: "",
    endDate: "",
  };
}

Filter.prototype.set = function (filters) {
  Object.entries(filters).forEach(([index, value]) => {
    this[index] = value;
  });
};

export default {
  name: "ProductsReport",
  components: {
    Dropdown,
    DatePickerRange,
    BTable,
    BFormGroup,
    BFormInput,
  },
  configProvider,
  data() {
    return {
      disabled: false,
      fields: [
        {
          key: "product_id",
          label: "شناسه محصول",
          sortable: true,
        },
        {
          key: "product_title",
          label: "عنوان",
          sortable: true,
        },
        {
          key: "quantity",
          label: "تعداد فروش",
          sortable: true,
        },
        {
          key: "discount_amount",
          label: "میزان تخفیف (تومان)",
          sortable: true,
        },
        {
          key: "sell_price",
          label: "میزان فروش (تومان)",
          sortable: true,
        },
        
      ],
      page: 1,
      reports: null,
      totals:null,
      sortBy: null,
      sortDesc: null,
      filter: new Filter(),
      appliedFilter: new Filter(),
    };
  },
  mixins: [reportMixin],
  watch: {
    selectedMode() {
      this.loadData();
    },
  },

  methods: {
    print() {
      print();
    },
    doFilter() {
      this.loadData();
    },
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData();
    },
    async loadData() {
      try {
        this.appliedFilter.set(this.filter);
        this.disabled = true;
        const response = await this.$axios.get(
          "admin/virtualOrdersProductReports",
          {
            params: {
              start_date: this.appliedFilter.date.startDate,
              end_date: this.appliedFilter.date.endDate,
              report_mini_product: this.reportCode,
            },
          }
        );
        this.reports = response.data.data.product_lists;
        this.totals = response.data.data.totals;
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
      }
    },
   
  },
};
</script>

<style>
.print-only {
  display: none;
}
@media print {
  .not-in-print {
    display: none !important;
  }

  .app-content .side-app {
    padding: 0 !important;
  }

  .print-only {
    display: block !important;
  }

  body * {
    visibility: hidden;
  }
  #print,
  #print * {
    visibility: visible;
    font-size: 1.25rem;
  }
  #print {
    padding: 0 !important;
  }
  #print {
    width: 80%;
  }

  #main-table th:last-child,
  #main-table td:last-child {
    display: none;
  }

  #print .not-in-print {
    display: none;
  }

  .mr-2-print {
    margin-right: 20px;
  }
}
.row.totals div {
  border: 1px solid #e3e3e3;
  padding: 4px 8px;
  display: flex;
  justify-content: space-between;
}
</style>
