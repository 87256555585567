<template>
  <main class="mt-5">
    <b-alert variant="warning" show class="rounded rounded-lg font-bold">
      تراکنش های انبار مرتبط با سفارش های در وضعیت ارسال شده در این لیست لحاظ نمیشود
    </b-alert>
    <b-alert variant="warning" show class="rounded rounded-lg font-bold">
      تراکنش های انبار مرتبط با سفارش های حضوری در این لیست لحاظ نمیشود
    </b-alert>
    <b-tooltip v-if="!hasPermission('write_store')" target="add-btn" triggers="hover">
      شما به این بخش دسترسی ندارید
    </b-tooltip>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold"> تعیین وضعیت موجودی های انبار</h5>
        <button v-on:click="loadItems" class="btn" type="button">
          <span v-if="!spinner" class="fa fa-refresh fa-2x"></span>
          <b-spinner variant="success" v-else type="grow" label="Spinning"></b-spinner>
        </button>
        <div style="flex:1"></div>
        <b-button v-b-toggle.filter :variant="hasFilter() ? 'secondary' : 'primary'">
          <span class="fe fe-search pt-1" style="font-size: 17px;"></span>

        </b-button>
      </div>
      <b-collapse id="filter" class="mt-2 mx-5">
        <b-card>
          <form @submit.prevent="filterItem()">
            <div class="d-flex flex-column mt-5">
              <!-- <ProductSearch class="col-md-6 row" :selectable="true" :disabled="disabled"
                             @selectProduct="selectProduct" @selectVariety="selectVariety" /> -->

              <div class="row">

                <b-form-group class="col-md-4">
                  <date-picker placeholder="از تاریخ" v-model="filter.start_date" format="YYYY-MM-DD"
                               display-format="jYYYY-jMM-jDD"/>
                </b-form-group>
                <b-form-group class="col-md-4">
                  <date-picker placeholder="تا تاریخ" v-model="filter.end_date" format="YYYY-MM-DD"
                               display-format="jYYYY-jMM-jDD"/>
                </b-form-group>
                <b-form-checkbox v-model="filter.is_done" name="check-button" switch size="lg">
                  <b class="mx-7">({{ filter.is_done ? 'تکمیل شده ها' : 'در انتظار تکمیل' }})</b>
                </b-form-checkbox>
              </div>
            </div>
            <div class="text-center mt-3">
              <button :disabled="disabled" type="submit" class="btn btn-primary px-5">جستجو</button>
              <button :disabled="disabled" @click="resetFilter" class="btn btn-warning px-5 mr-3">برداشتن
                فیلتر
              </button>
              <button :disabled="disabled" @click="$root.$emit('bv::toggle::collapse', 'filter')"
                      class="btn btn-secondary px-5 mr-3">بستن
              </button>
            </div>
          </form>
        </b-card>
      </b-collapse>
      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="items.data" :fields="fields" :tbody-tr-class="getBgColor">
            <template v-if="!real_done_status" v-slot:cell(is_done)="data">
              <form @submit.prevent="markAsDone(data.item.id)">
                <button type="submit" class="btn btn-primary  rounded-circle"><span
                    class="fa fa-check "></span></button>

              </form>
            </template>
            <template v-slot:cell(product)="data">
                            <span v-if="data.item.variety">
                                {{ data.item.variety.product.title }}
                                {{ data.item.variety.color ? ' - ' + data.item.variety.color.name : '' }}
                                <template v-if="data.item.variety.attributes.length > 1">
                                    <span v-for="attr in data.item.variety.attributes" :key="attr.id">
                                        {{ attr.pivot ? ' - ' + attr.pivot.value : '' }}
                                    </span>
                                </template>
                                <span v-else>
                                    {{
                                    data.item.variety.attributes[0] && data.item.variety.attributes[0].pivot ? ' - ' +
                                        data.item.variety.attributes[0].pivot.value : ''
                                  }}
                                </span>
                            </span>
            </template>
            <template v-slot:cell(quantity)="data">
                            <span>
                                {{ data.item.quantity }}
                            </span>
            </template>
            <template v-slot:cell(created_at)="data">
                            <span>
                                {{ data.item.created_at | persianDate }}
                            </span>
            </template>

            <template v-slot:cell(reserved)="data">
                            <span>
                                {{ data.item.order.status === 'reserved' ? 'بله' : 'خیر' }}
                            </span>
            </template>

            <template v-slot:cell(order_id)="data">
              <a :href="'/admin/order/' + data.item.order.id " target="_blank">
                <button type="button" class="btn btn-danger"><span>{{data.item.order.id }}</span></button>
              </a>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)"/>
          <pagination v-else-if="items.data != ''" :disabled="disabled" :limit="2" :data="items"
                      @pagination-change-page="changePage">
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
    <StoreModal :mode="mode" v-model="store"/>
  </main>
</template>
<script>
import StoreImpl from '@@/core/pages/Store/TransactionManagement/TransactionManagementImpl.js'
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";

export default {
  components: {ProductSearch},
  extends: StoreImpl,
  
}
</script>
<style>
.table td,.table td span{
  font-weight: inherit;
}
.bg-light-red{
  background-color: #FFCCCB;
}

</style>