import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from '@@/core/mixins/mixins'
import FormModal from "@@/core/libraries/form/FormModal";
import {BButton, BCard, BCollapse, BFormGroup, BFormInput, BTable, BTooltip, VBToggle} from "bootstrap-vue";

export default {
  name: "Color",
  components: {
    FormModal,
    Loading,
    BTooltip, BButton, BCollapse, BCard, BFormGroup, BFormInput, BTable
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/colors',
      fields: [
          {
        key: 'id',
        label: 'شناسه'
      },
        {
          key: 'name',
          label: 'نام'
        },
        {
          key: 'code',
          label: 'کد رنگ'
        },
        {
          key: 'status',
          label: 'وضعیت'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
      filter: {
        name: '',
        code: '',
        status: null
      },
      status_options: [{
        name: 'فعال',
        value: '1'
      },
        {
          name: 'غیرفعال',
          value: '0'
        },
        {
          name: 'همه',
          value: ''
        },
      ],
    }
  },
  mounted() {
    this.loadItems()
  },
  watch: {
    'filter.status': function (newVal) {
      if (typeof newVal === 'string' || typeof newVal === 'number') {
        this.filter.status = this.status_options.find(item => {
          return item.value == newVal
        })
      }
    }
  },
  methods: {
    async filterItem(page = 1) {
      this.searchParams = {
        search1: this.filter.name,
        searchBy1: 'name',
        search2: this.filter.code,
        searchBy2: 'code',
        search3: this.filter.status ? this.filter.status.value : '',
        searchBy3: 'status'
      }
    },
    onColorClicked() {
      if (this.filter.code == '') {
        this.filter.code = '#ff0000'
      }
      setTimeout(() => {
        this.$refs.colorPicker.click()
      })
    },
    resetFilter() {
      this.filter = this.$options.data.apply(this).filter
    },
    hasFilter() {
      // اون دیتایی که برای اولین بار ست کردیم
      // اگر فیلتر الان با ابتدا فرق داشت یعنی فیلتر داریم
      let initialFilter = this.$options.data.apply(this).filter
      let has = false;
      for (let [index, value] of Object.entries(initialFilter)) {
        if (initialFilter[index] !== this.filter[index]) {
          has = true;
          break;
        }
      }
      return has;
    }
  }
}
