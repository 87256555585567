<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">
          {{ $route.params.id ? "ویرایش نماینده" : "نماینده جدید" }}
        </h5>
        <div style="flex: 1"></div>
        <Back />
      </div>
      <div class="card-body">
        <customerForm
          ref="customerForm"
          :mode="$route.params.id ? 'edit' : 'create'"
          :url="url"
          :customer="customer"
        />
      </div>
    </div>
  </main>
</template>
<script>
import CustomersCreateEditImpl from "@@/core/pages/customer/CustomersCreateEdit/CustomersCreateEditImpl";
export default {
  extends: CustomersCreateEditImpl,
};
</script>
