import { BFormGroup, BFormInput, BModal, BTable } from "bootstrap-vue";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BTable,
  },
  props: {
    list: Array,
  },
  data() {
    return {
      transactionsFields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "amount",
          label: "مبلغ (تومان)",
        },
        {
          key: "type",
          label: "نوع",
        },
        {
          key: "confirmed",
          label: "وضعیت",
        },
        {
          key: "description",
          label: "توضیحات",
        },
        {
          key: "tracking_code",
          label: "شناسه پرداخت",
        },
        {
          key: "created_at",
          label: "تاریخ",
        },
      ],
    };
  },
};
