<template>
  <main class="mt-5">
    <span id="add-btn">
      <router-link
        to="/admin/store-transfer/create"
        :disabled="hasPermission('write_product') ? false : true"
        class="btn btn-success"
        >جابجایی جدید</router-link
      >
    </span>
    <b-tooltip
      v-if="!hasPermission('write_product')"
      target="add-btn"
      triggers="hover"
    >
      شما به این بخش دسترسی ندارید
    </b-tooltip>
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">جابجایی</h5>
        <div style="flex: 1"></div>
     
      </div>

      <div class="card-body">
        <div>
          <b-table 
          :tbody-tr-class="getTrClass"
          hover bordered :items="items.data" :fields="fields">
          
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a
                  v-if="data.item.is_delete==0"
                  class="action-btns1 pt-1 px-2"
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
                <a v-else class="action-btns1 pt-1 px-2">
                  <i class="feather feather-check text-danger"></i>
                </a>
              </span>
              <b-tooltip
                v-if="!hasPermission('delete_product')"
                :target="'delete-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
            
            <template v-slot:cell(print)="data">
                <router-link
                :to="`/admin/store-transfer/print/${data.item.id}`"
                  class="action-btns1 pt-1 px-2"
                >
                  <i class="feather feather-eye text-success"></i>
                </router-link>
           
             
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">موردی یافت نشد</div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import storeTransferImpl from "@@/core/pages/Store/storeTransfer/storeTransferImpl";
export default {
  extends: storeTransferImpl,
};
</script>
<style>
tr.deleteRow {
    background: #ff000036;
}
</style>