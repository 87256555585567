<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-body">
        <b-table
          :style="{ opacity: disabled ? '0.6' : 1 }"
          bordered
          responsive
          striped
          v-if="renewSubscription"
          :fields="fields"
          :items="renewSubscription.data"
        >
          <template v-slot:cell(index)="data">
            {{
              data.index +
                1 +
                (!disabled
                  ? (page - 1) * per_page
                  : (previousPage - 1) * per_page)
            }}
          </template>

          <template v-slot:cell(customer)="data">
            {{ data.item.customer.full_name }}
          </template>
          <template v-slot:cell(mobile)="data">
            {{ data.item.mobile }}
          </template>
          <template v-slot:cell(status)="data">
            <button class="btn statusBtn">
              <b-form-checkbox
                @click.native="changeStatus(data.item,data.index)"
                v-model="statusList[data.index]"
                name="check-button"
                switch
              ></b-form-checkbox>
            </button>
          </template>
          <template v-slot:cell(delete)="data">
            <button
              @click="deleteRow(data.item)"
              class="btn"
              style="line-height: 0.8;"
            >
              <i class="feather feather-trash-2 text-danger"></i>
            </button>
          </template>
        </b-table>
        <div v-else-if="renewSubscription == ''" class="alert alert-danger">
          موردی یافت تشد
        </div>
        <div v-else class="alert alert-primary">در حال بارگزاری</div>
        <pagination
          :router="true"
          v-if="renewSubscription && renewSubscription.data != ''"
          :disabled="disabled"
          :limit="2"
          :data="renewSubscription"
          @pagination-change-page="changePage"
        />
      </div>
    </div>
  </main>
</template>

<script>
import { BTable, BFormCheckbox } from "bootstrap-vue";

export default {
  name: "renewSubscription",
  components: {
    BTable,
    BFormCheckbox,
  },
  data() {
    return {
      page: 1,
      previousPage: 1,
      per_page: 10,
      disabled: true,
      statusList: [],
      renewSubscription: null,
      statistics: {
        order_statuses: [],
        total_income: null,
        total_shipping_amount: null,
        total_product_sales: null,
        total_discount_amount: null,
        total_order_items_count: null,
      },
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "mobile",
          label: "شماره موبایل",
          sortable: false,
        },
        {
          key: "customer",
          label: "نام نماینده",
          sortable: false,
        },
        {
          key: "status",
          label: "وضعیت بررسی",
          sortable: false,
        },
        {
          key: "delete",
          label: "حذف",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    per_page() {
      this.changePage(1);
    },
  },
  created() {
    if(this.$route.query.page){
      this.page=this.$route.query.page
    }
    this.loadData();
  },
  methods: {
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData();
    },
    changeStatus(item,index) {
      const fd=new  FormData();
      // bar aks bayad bashe chun ghable taghire araye injaeim
      fd.append("status",this.statusList[index]==true?0:1)
      this.$axios.post(`admin/subscription_request/change_status/${item.id}`,fd);
    },
    deleteRow(item) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          if (willDelete) {
            try {
              this.$axios
                .delete(`admin/subscription_request/${item.id}`)
                .then((res) => {
                  this.$root.notify(res.data.message, "success");

                  this.disabled = false;
                  this.loadData();
                });
            } catch (e) {
              this.$root.notify(e);
            }
          }
        });
    },
    async loadData() {
      try {
        let statusList = [];
        this.disabled = true;
        const renewSubscription = await this.$axios.get(
          "admin/subscription_request",
          {
            params: {
              page: this.page,
              per_page: this.per_page,
            },
          }
        );
        renewSubscription.data.data.subscriptionRequests.data.forEach(
          (element) => {
            statusList.push(element.status == 1 ? true : false);
          }
        );
        this.statusList=statusList;
        this.renewSubscription =
          renewSubscription.data.data.subscriptionRequests;
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
      }
    },
  },
};
</script>
<style>
.statusBtn .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem);
    right: 25px!important;
    transition: all 0.25s;
}
</style>
