import mixins from "@@/core/mixins/mixins";
import Loading from "@@/core/components/shared/Loading/Loading";
import { BButton, BModal, BFormGroup, BFormInput, BTable } from "bootstrap-vue";
export default {
  components: {
    BModal,
    Loading,
    BButton,
    BFormGroup,
    BFormInput,
    BTable,
  },
  mixins: [mixins],
  data() {
    return {
      title: "",
      disabled: false,
      items: null,
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "created_at",
          label: "ساخت",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      selectedId: null,
    };
  },
  methods: {
    newItem() {
      this.$root.$emit("bv::show::modal", "location-modal");
    },
    editItem(item) {
      this.title = item.title;
      this.selectedId = item.id;
      this.$root.$emit("bv::show::modal", "location-modal");

    },
    deleteItem(id) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.$axios
              .delete(`admin/varietyTransferLocations/${id}`)
              .then((response) => {
                this.loadData();
                window.swal({
                  icon: "success",
                  showConfirmButton: true,
                  text: response.data.message,
                  button: "باشه",
                });
              })
              .catch((error) => {
                window.swal({
                  title: "خطا",
                  showConfirmButton: true,
                  text: error.response.data.message,
                  button: "باشه",
                });
              });
          }
        })
        .catch(() => {});
    },
    saveData() {
      this.disabled = true;
      let fd = new FormData();
      fd.append("title", this.title);
      if (this.selectedId) {
        fd.append("_method", "PATCH");
      }
      this.$axios
        .post(
          `/admin/varietyTransferLocations${
            this.selectedId ? "/" + this.selectedId : ""
          }`,
          fd
        )
        .then((res) => {
          this.disabled = false;
          this.selectedId = null;
          this.$root.notify(res.data.message, "success");
          this.$root.$emit("bv::hide::modal", "location-modal");
          this.loadData();
        })
        .catch((errr) => {
          this.disabled = false;
          this.$root.notify(errr);
        });
    },
    async loadData() {
      await this.$axios.get("/admin/varietyTransferLocations").then((res) => {
        this.items = res.data.data.locations;
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
