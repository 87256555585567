<template>
  <div class="rounded">
    <h5 class="bg-light text-center rounded py-2 mb-0 font-bold">محصولات</h5>

    <b-table
      hover
      bordered
      responsive
      :items="order.items"
      :fields="fields"
      :tbody-tr-class="rowClass"
    >
      <template v-slot:cell(image)="data">
        <div class="order-product-img">
          <router-link
            :to="'/admin/products/edit/' + data.item.product.id"
            v-if="data.item.product || data.item.variety"
          >
            <img
              class="w-100 h-100"
              v-if="data.item.variety && data.item.variety.images != ''"
              :src="data.item.variety.images[0].url"
              alt=""
            />
            <img
              class="w-100 h-100"
              v-else-if="data.item.product && data.item.product.images != ''"
              :src="data.item.product.images[0].url"
              alt=""
            />
          </router-link>
        </div>
      </template>
      <template v-slot:cell(product)="data">
        <router-link
          style="max-width: 300px"
          class="d-inline-block"
          :to="'/admin/products/edit/' + data.item.product.id"
          v-if="data.item.product"
        >
          <h6 style="line-height: 2">{{ data.item.product.title }}</h6>
          <h6 v-if="data.item.extra.color || data.item.extra.attributes != ''">
            <span v-if="data.item.extra.color">
              رنگ:{{ data.item.extra.color }}
              {{ data.item.extra.attributes != "" ? " | " : "" }}
            </span>
            <span v-if="data.item.extra.attributes != ''">
              <span
                v-for="(attr, index) in data.item.extra.attributes"
                :key="index"
              >
                {{ attr.label + ":" + attr.pivot.value }}
                {{
                  index != data.item.extra.attributes.length - 1 ? " | " : ""
                }}
              </span>
            </span>
          </h6>
        </router-link>
      </template>
      <template v-slot:cell(amount)="data">
        <span v-if="data.item.amount != 0">{{
          (data.item.amount + data.item.discount_amount) | price
        }}</span>
        <span v-else>0</span>
      </template>
      <template v-slot:cell(discount_amount)="data">
        <span v-if="data.item.discount_amount">{{
          data.item.discount_amount | price
        }}</span>
        <span v-else>ندارد</span>
      </template>
      <template v-slot:cell(total_price)="data">
        <span v-if="data.item.amount != 0">{{
          (data.item.amount * data.item.quantity) | price
        }}</span>
        <span v-else>0</span>
      </template>
      <template v-slot:cell(flash)="data">
        {{ data.item.flash_id ? data.item.flash.title : "ندارد" }}
      </template>
      <template v-slot:cell(edit)="data">
        <button
          :disabled="disabled"
          @click="editItem(data.index)"
          class="action-btns1 pt-1 px-2"
        >
          <i class="feather feather-edit text-primary"></i>
        </button>
      </template>
    </b-table>
    <div
      class="price-box mt-5 col-md-3 mx-auto"
      style="flex: 0 0 28%; max-width: 28%"
    >
      <!-- <div class="d-md-flex justify-content-between">
              <span class=" px-3 py-2 font-bold">
                مجموع قیمت های کالا
              </span>
        <span class=" px-3 py-2"> {{ total | price }} تومان </span>
      </div> -->
      <div class="d-md-flex justify-content-between">
        <span class="px-3 py-2 font-bold"> هزینه ارسال </span>
        <span v-if="order.shipping_amount != 0" class="px-3 py-2">
          {{ order.shipping_amount | price }} تومان
        </span>
        <span v-else class="px-3 py-2"> 0 </span>
      </div>
      <div class="d-md-flex justify-content-between">
        <span class="px-3 py-2 font-bold"> تخفیف محصولات </span>
        <span  class="px-3 py-2">
          {{ productsDiscount | price }} تومان
        </span>
      </div>
      <div class="d-md-flex justify-content-between">
        <span class="px-3 py-2 font-bold"> تخفیف فاکتور </span>
        <span v-if="order.discount_amount" class="px-3 py-2">
          {{ order.discount_amount | price }} تومان
        </span>
        <span v-else class="px-3 py-2"> ندارد </span>
      </div>
      <div class="d-md-flex justify-content-between">
        <span class="px-3 py-2 font-bold"> پرداخت نهایی </span>
        <span class="px-3 py-2"> {{ totalPrice | price }} تومان </span>
      </div>
    </div>
  </div>
</template>

<script>
import OrderItemsImpl from "@@/core/components/order/OrderItems/OrderItemsImpl";

export default {
  name: "OrderItems",
  extends: OrderItemsImpl,
};
</script>

<style scoped>
.price-box {
  box-shadow: 0 0 5px -3px #133e9e;
  border-radius: 6px;
}

.order-product-img {
  max-width: 100px;
}
</style>
