<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">
          {{ $route.params.id ? "ویرایش بازاریاب" : "بازاریاب جدید" }}
        </h5>
        <div style="flex: 1"></div>
        <Back />
      </div>
      <div class="card-body">
        <b-form @submit.prevent="sendSeller">
          <b-form-group label="نام ">
            <b-form-input name="full_name" v-model="seller.fullName"> </b-form-input>
          </b-form-group>
          <b-form-group label="شماره تماس ">
            <b-form-input type="number" name="mobile" v-model="seller.mobile"> </b-form-input>
          </b-form-group>
          <b-form-group label="فعال است؟ ">
            <b-form-checkbox name="active" v-model="seller.active"> </b-form-checkbox>
          </b-form-group>
          <b-button type="submit" variant="primary">ثبت</b-button>
        </b-form>
      </div>
    </div>
  </main>
</template>
<script>
import createEditImpl from "@@/core/pages/sellers/createEdit/createEditImpl";
export default {
  extends: createEditImpl,
};
</script>
