import Loading from '@@/core/components/shared/Loading/Loading'
import StoreModal from '@@/core/components/store/StoreForm/StoreForm'
import mixins from '@@/core/mixins/mixins'
import {
    BSpinner,
    BButton,
    BCard,
    BCollapse,
    BFormGroup,
    BFormInput,
    BTable,
    BTooltip,
    VBToggle,
    BAlert,
    BFormCheckbox
} from "bootstrap-vue";

export default {
    name: "Store",
    components: {
        Loading,
        StoreModal,
        BTooltip, BSpinner, BButton, BCard, BTable, BCollapse, BFormGroup, BFormInput, BAlert, BFormCheckbox
    },
    directives: {
        'b-toggle': VBToggle
    },
    mixins: [mixins],
    data() {
        return {
            spinner: false,
            url: 'admin/order_items/statuses/pending_list',
            mode: 'increment',
            fields: [
                {
                    key: 'id',
                    label: 'شناسه ایتم سفارش'
                },
                {
                    key: 'order_id',
                    label: 'شماره فاکتور'
                },
                {
                    key: 'product',
                    label: 'محصول'
                },

                {
                    key: 'quantity',
                    label: 'تعداد'
                },
                {
                    key: 'created_at',
                    label: 'تاریخ'
                },
                {
                    key: 'reserved',
                    label: 'سفارش رزرو ؟'
                },
                {
                    key: 'is_done',
                    label: 'تکمیل فرایند خروج'
                },

            ],
            store: {
                selectedProduct: null,
                selectedVariety: null
            },

            filter: {
                product_id: '',
                variety_id: '',
                type: '',
                start_date: '',
                end_date: '',
                is_done: 0
            },
            real_done_status: 0

        }
    },
    mounted() {
        this.loadItems();
        setInterval(() => {
            this.spinner = true;
            this.loadItems().then(() => {
                this.spinner = false;
            });
        }, 5000)
    },
    watch: {

        'filter.type': function (newVal) {
            if (typeof newVal === 'string' || typeof newVal === 'number') {
                this.filter.type = this.typeOptions.find(item => {
                    return item.value == newVal
                })
            }
        },

    },
    methods: {
        getBgColor(item, type) {
            console.log(item.variety);
            if (type === 'row') {
              if (item.variety&&item.variety.quantity < 2) {
                return 'bg-light-red font-weight-bold';
              }
            }
          },
        async filterItem(page = 1) {
            this.searchParams = {
                search1: this.filter.product_id ? this.filter.product_id : '',
                searchBy1: 'product_id',
                variety_id: this.filter.variety_id ? this.filter.variety_id : '',
                search2: this.filter.type ? this.filter.type.value : '',
                searchBy2: 'type',
                start_date: this.filter.start_date ? window.moment(this.filter.start_date, 'YYYY.MM.DD').unix() : '',
                end_date: this.filter.end_date ? window.moment(this.filter.end_date, 'YYYY.MM.DD').unix() : '',
                is_done: this.filter.is_done
            }
        },
        getVarietyOrderItems(variety_id) {
            // debugger;
            return this.items.data.find(item => item.id == variety_id).pending_order_items.map(orderItem => orderItem.id);
        },
        async markAsDone(orderItemId) {
            const response = await this.$axios.post(`admin/order_items/statuses/mark-as-done`, {
                "orderItem": orderItemId
            });
            this.loadItems();
        },
        selectProduct(product) {
            this.filter.product_id = product.id
        },
        selectVariety(variety) {
            this.filter.variety_id = variety.id
        },


    },
    watch: {
        "$route.query": function (newValue) {
            this.loadItems().then(() => {
                this.real_done_status = this.searchParams.is_done
            })
        }
    }
}
