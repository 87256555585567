<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold py-3">ثبت خرید</h5>
        <div style="flex: 1"></div>
        <Back url="/admin/shop" />
      </div>
      <div class="card-body">
        <form @submit.prevent="submit">
          <b-form-group class="col-md-6" label="زمان ثبت">
            <date-picker
              type="datetime"
              v-model="created_at"
              :format="'YYYY-MM-DD HH:mm'"
              :display-format="'jYYYY-jMM-jDD HH:mm'"
            />
          </b-form-group>
          <b-form-group label="انتخاب کاربر">
            <div class="d-flex col-md-6 mb-3" style="gap: 0.5rem">
              <b-form-input
                @keypress.enter.prevent="requestCustomer"
                v-model="customerMobile"
                :disabled="disabled || customerInfo"
                @input="requestCustomer"
                type="text"
              />
              <button
                @click="clearCustomer"
                v-if="customerInfo"
                class="btn btn-danger"
              >
                لغو کاربر
              </button>
            </div>
            <template v-if="customerInfo">
              <div v-if="customerInfo.user" class="row">
                <div class="col-4 px-3">
                  <div class="little card info">
                    <div class="card-header">اطلاعات کاربری</div>
                    <div class="card-body">
                      <div class="d-flex" style="gap: 1rem">
                        <div v-if="customerInfo.user.full_name">
                          نام و نام خانوادگی:
                          {{ customerInfo.user.full_name }}
                        </div>
                        <div>
                          شارژ کیف پول:
                          {{ customerInfo.user.wallet_balance | price }} تومان
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-for="i in [
                    ['آنلاین', 'online'],
                    ['حضوری', 'physical'],
                  ]"
                  :key="i[1]"
                  class="col-4 px-3"
                >
                  <div class="little card" :class="i[1]">
                    <div class="card-header d-flex justify-content-between">
                      <span class="font-bold"> آمار {{ i[0] }} </span>
                      <span v-if="customerInfo[i[1]].last_order_at">
                        آخرین خرید:
                        {{ customerInfo[i[1]].last_order_at | persianDate }}
                      </span>
                    </div>
                    <div class="card-body">
                      <div
                        class="d-flex justify-content-between"
                        style="gap: 1rem"
                      >
                        <div>
                          تعداد خرید: {{ customerInfo[i[1]].orders_count }}
                        </div>
                        <div>
                          میزان خرید: {{ customerInfo[i[1]].orders_total ?? 0 }}
                        </div>
                        <div v-if="customerInfo[i[1]].last_order_at"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 font-weight-bold" v-else>کاربر جدید</div>
            </template>
          </b-form-group>

          <b-form-group class="col-6">
            <label class="" for="products">
              بازاریاب <i class="text-danger">*</i></label
            >
            <div id="selectSeller">
              <button
                v-for="(seller, index) in sellers"
                :key="index"
                class="sellerBtn"
                :class="{ active: selectedSellerId == seller.id }"
                type="button"
                @click="selectedSellerFunc(seller)"
              >
                {{ seller.label }}
              </button>
            </div>
          </b-form-group>

          <b-form-group label="انتخاب محصول">
            <div class="d-flex col-md-6" style="gap: 0.5rem">
              <b-form-input
                ref="productSku"
                v-model="productSku"
                :disabled="disabled"
                @keypress.prevent.enter="requestProduct"
                type="text"
              />
              <div class="d-flex mr-3" style="gap: 0.6rem">
                <label for="refund_checkbox">مرجوعی</label>
                <b-form-checkbox
                  id="refund_checkbox"
                  type="checkbox"
                  :disabled="disabled"
                  v-model="refund"
                />
              </div>
            </div>
          </b-form-group>
          <b-form-group label="جستجوی محصول">
            <ProductSearch
              ref="productSearch"
              :selectable="true"
              class="row"
              :show-label="false"
              @selectVariety="selectVariety"
            />
          </b-form-group>
          <div
            v-for="i in ['varieties', 'refund_varieties']"
            v-if="$data[i] != ''"
          >
            <template>
              <h4 class="table-title">
                <template v-if="i === 'varieties'">فروش</template>
                <template v-else>مرجوع</template>
              </h4>
            </template>
            <b-table
              class="mt-3 admin-order"
              :class="$data[i]"
              id="miniorderitems"
              hover
              bordered
              responsive
              :items="$data[i]"
              :fields="fields"
            >
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template v-slot:cell(image)="data" class="text-center">
                <div class="order-product-img">
                  <a :href="getImage(data.item)">
                    <img class="w-100 h-100" :src="getImage(data.item)" />
                  </a>
                </div>
              </template>
              <template v-slot:cell(title)="data">
                <span style="width: 300px">
                  <span style="line-height: 2">{{
                    data.item.product.title
                  }}</span>
                  <span v-if="data.item.color || data.item.attributes != ''">
                    <span> | </span>
                    <span v-if="data.item.color">
                      رنگ : {{ data.item.color.name }}
                      {{ data.item.attributes != "" ? " | " : "" }}
                    </span>
                    <span v-if="data.item.attributes != ''">
                      <span
                        v-for="(attr, index) in data.item.attributes"
                        :key="index"
                      >
                        {{ attr.label + ": " + attr.pivot.value }}
                        {{
                          index != data.item.attributes.length - 1 ? " | " : ""
                        }}
                      </span>
                    </span>
                  </span>
                </span>
              </template>
              <template v-slot:cell(quantity)="data">
                <button
                  type="button"
                  class="border border-radius7 px-1 bg-none"
                  @click="decrement(data.item)"
                  :disabled="data.item.quantity == 1 || disabled ? true : false"
                >
                  <DashSvg />
                </button>
                <span class="px-2">{{ data.item.quantity }}</span>
                <button
                  type="button"
                  class="border border-radius7 px-1 bg-none"
                  @click="increment(data.item)"
                  :disabled="disabled"
                >
                  <PlusSvg />
                </button>
              </template>
              <template v-slot:cell(amount)="data">
                <b-form-input
                  style="width: 80px"
                  class="m-auto"
                  :value="data.item.final_price.sell_price | priceinput"
                  @input="
                    (value) => {
                      data.item.final_price.sell_price =
                        value !== '' ? +value.replace(/\D/g, '') : '';
                    }
                  "
                >
                </b-form-input>
              </template>

              <template v-slot:cell(seller_id)="data">
                <!-- <b-form-group>
                  <multiselect
                    placeholder="بازاریاب"
                    v-model="seller_ids[data.item.id]"
                    open-direction="bottom"
                    :options="sellers"
                    label="label"
                    track-by="id"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :disabled="disabled"
                    @input="sellerChanged"
                  >
                    <template slot="noOptions"> موردی یافت نشد </template>
                    <template slot="noResult"> موردی یافت نشد </template>
                  </multiselect>
                </b-form-group> -->
                <b-button @click="sellerModalHandler(data.item.id)">
                  <span>
                    {{
                      seller_ids[data.item.id] && seller_ids[data.item.id].label
                        ? seller_ids[data.item.id].label
                        : "انتخاب نشده"
                    }}
                  </span>
                  <i class="fa fa-angle-down"></i>
                </b-button>
              </template>
              <template v-slot:cell(flash)="data">
                {{
                  data.item.product && data.item.product.active_flash != ""
                    ? data.item.product.active_flash[0].title
                    : "ندارد"
                }}
              </template>
              <template v-slot:cell(discount)="data">
                <span v-if="data.item.final_price.sell_price_discount">
                  {{
                    (data.item.final_price.sell_price_discount_amount *
                      data.item.quantity)
                      | price
                  }}
                </span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(total_price)="data">
                {{
                  data.item.final_price.sell_price_discount_amount
                    ? calcPrice(data.item, true)
                    : calcPrice(data.item, false) | price
                }}
              </template>
              <template v-slot:cell(delete)="data">
                <a
                  class="action-btns1 pt-1 px-2"
                  @click="deleteItem(data.item, i)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </template>
            </b-table>
          </div>
          <template v-if="varieties != '' || refund_varieties != ''">
            <div
              class="mt-5 col-md-4 mx-auto card justify-content-center little py-5 d-flex"
            >
              <div class="d-md-flex justify-content-between">
                <span class="px-3 py-2 font-bold"> مجموع قیمت کالا ها : </span>
                <span class="px-3 py-2"> {{ total | price }} تومان </span>
              </div>
              <div class="d-md-flex justify-content-between">
                <span class="px-3 py-2 font-bold"> تخفیف : </span>
                <span class="px-3 py-2">
                  <b-form-input
                    style="width: 80px"
                    :value="discountAmount | priceinput"
                    @input="
                      (value) => {
                        discountAmount =
                          value !== '' ? +value.replace(/\D/g, '') : '';
                      }
                    "
                  />
                </span>
              </div>

              <div class="d-md-flex justify-content-between">
                <span class="px-3 py-2 font-bold"> مبلغ پرداخت نقدی : </span>
                <b-form-input
                  style="width: 80px"
                  :value="cash_amount | priceinput"
                  @input="
                    (value) => {
                      cash_amount =
                        value !== '' ? +value.replace(/\D/g, '') : '';
                    }
                  "
                />
              </div>
              <div class="d-md-flex justify-content-between">
                <span class="px-3 py-2 font-bold"> مبلغ کارت به کارت : </span>
                <b-form-input
                  style="width: 80px"
                  :value="cardByCard_amount | priceinput"
                  @input="
                    (value) => {
                      cardByCard_amount =
                        value !== '' ? +value.replace(/\D/g, '') : '';
                    }
                  "
                />
              </div>
              <div
                v-if="userWalletAmount"
                class="d-md-flex justify-content-between"
              >
                <span class="px-3 py-2 font-bold">
                  
                  استفاده از کیف پول کاربر؟
                </span>
                <div class="d-flex justify-content-center col-md-4">
                  <b-form-checkbox v-model="from_wallet_amount" />
                </div>
              </div>
              <div class="d-md-flex justify-content-between">
                <span class="px-3 py-2 font-bold"> پرداخت نهایی : </span>
                <span
                  class="px-3 py-2"
                  :class="{
                    'text-success': totalPrice >= 0,
                    'text-danger': totalPrice < 0,
                  }"
                >
                  {{ totalPrice | price }} تومان
                </span>
              </div>
            </div>
            <div class="row mt-4">
              <!-- <b-form-group class="col-md-6" label="کد رهگیری">
                <b-form-input placeholder="کد رهگیری" />
              </b-form-group> -->
              <b-form-group class="col-md-6" label="یادداشت">
                <b-form-textarea placeholder="یادداشت" />
              </b-form-group>
            </div>
            <div class="mt-4 w-100 d-flex justify-content-center">
              <button
                type="submit"
                :disabled="disabled"
                class="btn btn-success"
              >
                ثبت سفارش
              </button>
            </div>
          </template>
        </form>
      </div>
      <div id="selectSellerModal" @click="closeModal" v-if="showModal">
        <div id="selectSellerModalContainer">
          <div class="card">
            <div class="card-header border-0">
              <h5 class="card-title font-bold py-3">انتخاب بازاریاب</h5>
              <div style="flex: 1"></div>
              <b-button variant="danger" @click="closeModal('close')"
                >X</b-button
              >
            </div>
            <div class="card-body">
              <div id="selectSeller">
                <button
                  v-for="(seller, index) in sellers"
                  :key="index"
                  class="sellerBtn"
                  type="button"
                  @click="selectedSellerForVariety(seller)"
                >
                  {{ seller.label }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BTable,
  BFormCheckbox,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import PlusSvg from "@@/core/components/svgs/PlusSvg";
import DashSvg from "@@/core/components/svgs/DashSvg";
import Back from "@@/core/components/shared/Back/Back";
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";

export default {
  name: "ShopCreate",
  components: {
    DashSvg,
    PlusSvg,
    BFormGroup,
    BFormInput,
    BTable,
    BFormCheckbox,
    BFormTextarea,
    Back,
    BButton,
    ProductSearch,
  },
  data() {
    return {
      showModal: false,
      created_at: null,
      selectedVarietyId: null,
      cash_amount: null,
      seller_ids: {},
      cardByCard_amount: null,
      isRequesting: false,
      sellerDisabled: false,
      selectedSeller: null,
      selectedSellerId: null,
      lastQ: "",
      sellers: [],
      disabled: false,
      refund: false,
      from_wallet_amount: false,
      discountAmount: 0,
      userWalletAmount: 0,
      productSku: "",
      customerMobile: "",
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "image",
          label: "تصویر",
        },
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "amount",
          label: "قیمت واحد (تومان)",
        },
        {
          key: "quantity",
          label: "تعداد",
        },
        {
          key: "discount",
          label: "تخفیف",
        },
        {
          key: "seller_id",
          label: "بازاریاب",
        },
        {
          key: "flash",
          label: "کمپین",
        },
        {
          key: "total_price",
          label: "قیمت نهایی (تومان)",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      varieties: [],
      refund_varieties: [],
      customerInfo: null,
    };
  },
  computed: {
    total() {
      return (
        this.varieties.reduce((a, b) => {
          let sameb = 0;

          if (b.final_price.sell_price_discount) {
            sameb = b.final_price.sell_price_discount * b.quantity;
          } else {
            sameb = b.final_price.sell_price * b.quantity;
          }
          return a + sameb;
        }, 0) -
        this.refund_varieties.reduce((a, b) => {
          let sameb = 0;

          if (b.final_price.sell_price_discount) {
            sameb = b.final_price.sell_price_discount * b.quantity;
          } else {
            sameb = b.final_price.sell_price * b.quantity;
          }
          return a + sameb;
        }, 0)
      );
    },
    totalPrice() {
      return this.from_wallet_amount
        ? Number(this.userWalletAmount) > this.total - this.discountAmount
          ? 0
          : this.total - this.discountAmount - Number(this.userWalletAmount)
        : this.total - this.discountAmount;
    },
  },
  mounted() {
    this.listenForKeyPress();
  },
  methods: {
    calcPrice(item, hasDiscount) {
      // data.item.final_price.sell_price_discount_amount  //
      // data.item.final_price.sell_price\
      if (hasDiscount) {
        let discount =
          item.final_price.sell_price_discount_amount * item.quantity;
        let price = item.final_price.sell_price * item.quantity;
        return price - discount;
      } else {
        return item.final_price.sell_price * item.quantity;
      }
    },
    selectVariety(variety) {
      this.addVariety(variety);
    },
    selectedSellerForVariety(seller) {
      this.seller_ids[this.selectedVarietyId] = {
        label: seller.label,
        id: seller.id,
      };
      this.closeModal("close");
    },
    closeModal(e) {
      if (e == "close" || e.target.id == "selectSellerModal") {
        this.showModal = false;
      }
    },
    sellerModalHandler(id) {
      this.selectedVarietyId = id;
      this.showModal = true;
    },
    sellerChanged() {
      this.$forceUpdate();
    },
    async getSellers() {
      const response = await this.$axios.get("admin/sellers?all=1");
      this.sellers = response.data.data.sellers.map((s) => {
        s.label = `${s.full_name}`;
        return s;
      });
    },
    async getSeller(q) {
      if (this.lastQ == q || q == "" || q.length == 1) {
        return;
      }
      this.lastQ = q;
      let search = q;
      if (this.axiosProductsCancelSource) {
        this.axiosProductsCancelSource.cancel();
      }
      this.axiosProductsCancelSource = this.$axios.CancelToken.source();
      try {
        this.isRequesting = true;
        const response = await this.$axios.get("admin/sellers", {
          cancelToken: this.axiosProductsCancelSource.token,
          params: { search },
        });
        this.sellers = response.data.data.sellers.data.map((s) => {
          s.label = `${s.full_name} - ${s.mobile}`;
          return s;
        });
        this.isRequesting = false;
      } catch (e) {
        if (!this.$axios.isCancel(e)) {
          this.$root.notify(e);
        }
      }
    },
    listenForKeyPress() {
      // add document event listener for all key presses
      document.removeEventListener("keyup", this.onKeyPress);
      document.addEventListener("keyup", this.onKeyPress);
    },
    onKeyPress(e) {
      const input = this.$refs.productSku;
      if (/[0-9]/g.test(e.key)) {
        if (document.activeElement.tagName !== "INPUT") {
          input.focus();
          this.productSku = e.key;
        }
      }
    },
    async requestProduct() {
      try {
        this.disabled = true;
        const response = await this.$axios.get("admin/mini_orders/search", {
          params: {
            sku: this.productSku,
            refund: this.refund,
          },
        });
        const variety = response.data.data.variety;
        this.addVariety(variety);
        this.productSku = "";
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
      }
    },
    addVariety(variety) {
      console.log(variety);
      const mode = this.refund ? "refund_varieties" : "varieties";
      const alreadyAdded = this[mode].find((v) => {
        return v.id == variety.id;
      });
      if (alreadyAdded) {
        alreadyAdded.quantity++;
        this.$root.notify(
          "به تعداد محصول اضافه شد: " + alreadyAdded.quantity,
          "success"
        );
      } else {
        variety.quantity = 1;
        this[mode].push(variety);
        this.seller_ids[variety.id] = this.selectedSeller
          ? { label: this.selectedSeller.label, id: this.selectedSeller.id }
          : {};
      }
    },
    getImage(variety) {
      if (variety.images != "") {
        return variety.images[0].url;
      }
      if (variety.product.images != "") {
        return variety.product.images[0].url;
      }
      return null;
    },
    decrement(variety) {
      if (variety.quantity === 0) {
        return;
      }
      variety.quantity--;
    },
    increment(variety) {
      variety.quantity++;
    },
    deleteItem(variety, mode) {
      this[mode] = this[mode].filter((v) => {
        return v.id !== variety.id;
      });
    },
    async requestCustomer(mobile) {
      if (mobile.length !== 11) return;
      try {
        this.disabled = true;
        const response = await this.$axios.get(
          "admin/mini_orders/user-search",
          {
            params: {
              mobile,
            },
          }
        );
        this.disabled = false;
        const data = response.data.data;
        this.userWalletAmount = response.data.data.user?response.data.data.user.wallet_balance:0;
        this.customerInfo = data;
        this.productSku = "";
      } catch (e) {
        this.$root.notify(e);
      }
    },
    selectedSellerFunc(seller) {
      this.selectedSellerId = seller.id;
      this.selectedSeller = seller;
    },
    clearCustomer() {
      this.customerInfo = null;
      this.customerMobile = "";
      this.from_wallet_amount = false;
      this.userWalletAmount = 0;
    },
    async submit() {
      if (!this.validate()) {
        return;
      }

      let fd = new FormData();
      fd.append("mobile", this.customerMobile);
      if (this.created_at) {
        fd.append("created_at", this.created_at);
      }
      fd.append("discount_amount", this.discountAmount);
      fd.append("cash_amount", this.cash_amount ? this.cash_amount : 0);
      fd.append(
        "cardByCard_amount",
        this.cardByCard_amount ? this.cardByCard_amount : 0
      );
      fd.append("description", this.description);
      fd.append("from_wallet_amount", Number(this.from_wallet_amount));

      this.varieties.forEach((item, index) => {
        fd.append(
          `varieties[${index}][seller_id]`,
          this.seller_ids[item.id] ? +this.seller_ids[item.id].id : null
        );
        fd.append(`varieties[${index}][id]`, item.id);
        fd.append(`varieties[${index}][quantity]`, item.quantity);
        fd.append(
          `varieties[${index}][amount]`,
          item.final_price.sell_price_discount_amount
            ? item.final_price.sell_price -
                item.final_price.sell_price_discount_amount
            : item.final_price.sell_price
        );
      });
      this.refund_varieties.forEach((item, index) => {
        fd.append(
          `refund_varieties[${index}][seller_id]`,
          this.seller_ids[item.id] ? +this.seller_ids[item.id].id : null
        );
        fd.append(`refund_varieties[${index}][id]`, item.id);
        fd.append(`refund_varieties[${index}][quantity]`, item.quantity);
        fd.append(
          `refund_varieties[${index}][amount]`,
          item.final_price.sell_price_discount_amount
            ? item.final_price.sell_price -
                item.final_price.sell_price_discount_amount
            : item.final_price.sell_price
        );
      });
      try {
        this.disabled = true;
        const response = await this.$axios.post("admin/mini_orders", fd);

        const miniOrder = response.data.data.mini_order;
        this.$root.notify(response.data.message, "success");
        this.$router.push(`/admin/shop/orders/${miniOrder.id}?print=1`);
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
      }
    },
    validate() {
      if (
        (!this.varieties || !this.varieties.length) &&
        !this.refund_varieties &&
        !this.refund_varieties.length
      ) {
        this.$root.warningNotify("حداقل انتخاب یک تنوع الزامی است");
        return false;
      }
      return true;
    },
  },
  created() {
    this.getSellers();
  },
};
</script>

<style scoped>
.order-product-img {
  max-width: 50px;
  margin: auto;
}

.table-title {
  font-weight: bold;
}
</style>
<style>
.card.little.info {
  background: linear-gradient(30deg, #d2e8ff 0%, #d5e6fc 35%, #eaf5ff 100%);
}

.card.little.online {
  background: linear-gradient(30deg, #c8ffc8 0%, #c8ffc8 35%, #e0ffe0 100%);
}

.card.little.physical {
  background: linear-gradient(30deg, #ffffe3 0%, #ffffbc 35%, #ffffc5 100%);
}

.card.little .card-header {
  font-weight: bold;
}
div#selectSeller button:hover,
div#selectSeller button.active {
  transition: all 0.4s;
  background: #009eff;
  color: white;
}
div#selectSeller button {
  border: 1px solid grey;
  background: transparent;
  box-shadow: 0 0 4px #1122115e;
  padding: 4px;
  border-radius: 6px;
}

div#selectSeller {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}
.mt-3.admin-order.table-responsive.id.price.barcode.product_id.order.max_number_purchases.created_at.updated_at.unique_attributes_key.images.user_images.quantity.final_price.attributes.product.store.id.price.barcode.product_id.order.max_number_purchases.created_at.updated_at.unique_attributes_key.images.user_images.quantity.final_price.attributes.product.store {
  overflow: visible;
}
div#selectSellerModal {
  position: fixed;
  inset: 0;
  background: #11221194;
  z-index: 10000;
}
div#selectSellerModalContainer {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
</style>
