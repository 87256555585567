import ImageHolder from '@@/core/components/shared/ImageHolder/ImageHolder'
import Loading from '@@/core/components/shared/Loading/Loading'
import SliderForm from '@@/core/components/slider/SliderForm/SliderForm'
import {BTooltip, VBModal} from "bootstrap-vue";
import draggable from "vuedraggable";

export default {
  components: {
    ImageHolder,
    Loading,
    SliderForm,
    BTooltip,
    draggable
  },
  directives: {
    'b-modal': VBModal
  },
  data() {
    return {
      url: '',
      group: '',
      sliders: null,
      edit: {},
      mode: 'create',
      linkables: [],
      slider: {
        title: '',
        status: 1,
        link: null,
        linkable_type: null,
        linkable_id: null,
        image: '',
        description: ''
      },
      modelLable: '',
      disabled: false
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: this.disabled,
        ghostClass: "ghost"
      }
    }
  },
  created() {
    this.url = `admin/sliders/groups/${this.$route.params.group}?all=1`
    this.group = this.$route.params.group
    this.loadSlider();
    this.loadLinkables();
  },
  methods: {
    async loadLinkables(){
      const response = await this.$axios.get("admin/links")
      this.linkables = response.data.data.linkables
      this.linkables.push({
        linkable_type: "link_url",
        unique_type: "link_url",
        label: "لینک دلخواه",
        model: "",
      })
    },
    async loadSlider() {
      const response = await this.$axios.get(this.url)
      this.sliders = response.data.data.sliders
      // this.linkables = response.data.data.widgets.linkables
      // this.linkables.push({
      //   linkable_type: "link_url",
      //   unique_type: "link_url",
      //   label: "لینک دلخواه",
      //   models: "link_url",
      // })
    },
    deleteItem(id) {
      window.swal({
        title: "آیتم حذف شود؟",
        text: "این عملیات غیر قابل بازگشت خواهد بود",
        icon: "warning",

        showCancelButton: true,
        showConfirmButton: true,
        dangerMode: true,
        buttons: ["خیر", "بله حذف کن"]
      })
          .then((willDelete) => {
            if (willDelete) {
              this.$axios.delete(`admin/sliders/${id}`)
                  .then((response) => {
                    this.sliders = this.sliders.filter(item => item.id != id)
                    window.swal({
                      icon: "success",
                      showConfirmButton: true,
                      text: response.data.message,
                      button: "باشه",
                    })
                  })
                  .catch(error => {
                    window.swal({
                      title: 'خطا',
                      showConfirmButton: true,
                      text: error.response.data.message,
                      button: "باشه",
                    })
                  })
            }
          })
          .catch(() => {

          })
    },
    editItem(index) {
      this.slider = {
        ...this.sliders[index]
      }
      if (this.slider.link == null) {
        this.slider.linkable_type = this.linkables.filter(item => item.unique_type == this.slider
            .unique_type)[0]

        this.slider.linkable_id != null && this.slider.linkable_type.models ? this.slider.linkable_id = this
            .slider.linkable_type.models.filter(
                item => item.id == this.slider.linkable_id)[0] : ''

        if (this.slider.linkable_type.models != null && this.slider.linkable_type.models[0].title) {
          this.modelLable = "title";
        } else if (this.slider.linkable_type.models != null && this.slider.linkable_type.models[0].name) {
          this.modelLable = "name";
        }
      } else {
        console.log(1);
        this.slider.linkable_type = {
          linkable_type: "link_url",
          unique_type: "link_url",
          label: "لینک دلخواه",
          model: "",
        }
      }

      this.mode = 'edit'
      this.slider.status ? this.slider.status = 1 : this.slider.status = 0
      this.slider.index = index
      this.$root.$emit('bv::show::modal', 'slider-modal')
    },
    newSlider() {
      this.slider = {
        title: '',
        status: 1,
        link: null,
        linkable_type: '',
        linkable_id: '',
        image: '',
      }
      this.mode = 'create'
    },
    async sortSlider() {
      let orders = [];

      this.sliders.forEach((item) => {
        orders.push(item.id)
      });
      try {
        this.disabled = true;
        const response = await this.$axios.post('admin/sliders/sort', {
          group: this.group,
          orders: orders,
        })
        this.$root.notify(response.data.message, 'success')
        // window.swal({
        //     title: response.data.message,
        // });
      } catch (error) {
        this.$root.notify(error)
      }
      this.disabled = false
    }
  }
}
