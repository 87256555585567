<template>
  <main class="mt-5">
    <span id="add-btn">
      <router-link
        to="/admin/users/create"
        :disabled="hasPermission('write_product') ? false : true"
        class="btn btn-success"
        >کابر جدید</router-link
      >
    </span>
    <b-tooltip
      v-if="!hasPermission('write_product')"
      target="add-btn"
      triggers="hover"
    >
      شما به این بخش دسترسی ندارید
    </b-tooltip>
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">کاربران</h5>
        <div style="flex: 1"></div>
        <b-button
          v-b-toggle.filter
          :variant="hasFilter() ? 'secondary' : 'primary'"
        >
          <span class="fe fe-search pt-1" style="font-size: 17px"></span>
        </b-button>
      </div>
      <b-collapse  id="filter" class="mt-2 mx-5">
        <b-card>
          <form @submit.prevent="filterItem()">
            <div class="col-xl-4 col-md-12 d-flex">
              <div class="col-6 d-flex">
                <b-form-group label="از تاریخ :">
                  <date-picker
                    type="datetime"
                    v-model="start_date"
                    :format="'YYYY-MM-DD HH:mm'"
                    :display-format="'jYYYY-jMM-jDD HH:mm'"
                  />
                </b-form-group>
              </div>
              <div class="col-6 d-flex">
                <b-form-group label="تا تاریخ :">
                  <date-picker
                    type="datetime"
                    v-model="end_date"
                    :format="'YYYY-MM-DD HH:mm'"
                    :display-format="'jYYYY-jMM-jDD HH:mm'"
                  />
                </b-form-group>
              </div>
            </div>
            <div class="col-xl-12 col-md-12 d-flex">
              <b-form-group label="دسته :" class="col-md-3">
                <multiselect
                  placeholder="دسته"
                  v-model="filter.user_type"
                  open-direction="bottom"
                  :options="user_types"
                  label="title"
                  track-by="id"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>
            </div>
            <div class="row mt-5">
              <b-form-group class="col-md-6">
                <b-form-input
                  :disabled="disabled"
                  placeholder="جستجو"
                  v-model="search"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <div class="text-center mt-6">
              <button
              @click="getExcel()"
                :disabled="disabled||!start_date||!end_date"
                type="button"
                class="btn btn-success ml-2 px-5"
              >
                خروجی اکسل
              </button>
              <button
                :disabled="disabled"
                type="submit"
                class="btn btn-primary px-5"
              >
                جستجو
              </button>
              <button
                :disabled="disabled"
                @click="resetFilter"
                class="btn btn-warning px-5 mr-3"
              >
                برداشتن فیلتر
              </button>
              <button
                :disabled="disabled"
                @click="$root.$emit('bv::toggle::collapse', 'filter')"
                class="btn btn-secondary px-5 mr-3"
              >
                بستن
              </button>
            </div>
          </form>
        </b-card>
      </b-collapse>
      <div class="card-body">
        <div>
          <b-table hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(fullName)="data">
             
              <span
              >
              {{ data.item.first_name + " " + data.item.last_name }}
              </span>
            </template>
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template  v-slot:cell(seen)="data">
              <router-link :to="`/admin/users/show/${data.item.id}`" >
                <button class="btn btn-info">
                <i class="fa fa-eye"></i>

                </button>
              </router-link>
                <!-- router -->
              
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <router-link
                  @mouseenter.native="savePage()"
                  :to="'/admin/users/edit/' + data.item.id"
                  :disabled="!hasPermission('modify_product') ? true : false"
                  class="action-btns1 pt-1 px-2"
                  @click="editItem(data.index)"
                >
                  <i class="feather feather-edit text-primary"></i>
                </router-link>
              </span>
              <b-tooltip
                v-if="!hasPermission('modify_product')"
                :target="'edit-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a
                  :disabled="!hasPermission('delete_product') ? true : false"
                  class="action-btns1 pt-1 px-2"
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip
                v-if="!hasPermission('delete_product')"
                :target="'delete-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
       
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">موردی یافت نشد</div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import usersImpl from "@@/core/pages/users/users/usersImpl";
export default {
  extends: usersImpl,
};
</script>
