<template>
  <div class="mt-5">
    <h6 class="bg-light text-center rounded py-2 font-bold">
      تراکنش های کیف پول
    </h6>
    <b-table  v-if="list&&list.length"
      hover
      bordered
      responsive
      :items="list"
      :fields="transactionsFields"
    >
    <template v-slot:cell(amount)="data">
      {{data.item.amount | price}}
    </template>
    <template v-slot:cell(type)="data">
      <span class="badge badge-success" v-if="data.item.type == 'deposit'">افزایش کیف پول</span>
      <span class="badge badge-danger" v-else-if="data.item.type == 'withdraw'">برداشت از کیف پول</span>
    </template>
    <template v-slot:cell(tracking_code)="data">
      <span v-if="data.item.deposit && data.item.deposit.active_payment">
        {{ data.item.deposit.active_payment.tracking_code }}
      </span>
      <span v-else>
        –
      </span>
    </template>
    <template v-slot:cell(confirmed)="data">
      <span class="badge badge-success" v-if="data.item.confirmed">موفقیت آمیز</span>
      <span class="badge badge-danger" v-else>ناموفق</span>
    </template>
    <template v-slot:cell(description)="data">
      {{data.item.meta ? data.item.meta.description : ''}}
    </template>
      <template v-slot:cell(created_at)="data">
        {{data.item.created_at | persianDate}}
      </template>
    </b-table>
    
    <div v-else class="alert alert-danger">
      موردی یافت نشد
    </div>
  </div>
</template>
<script>
import OrderTransactionImpl from "@@/core/components/virtualOrder/OrderTransaction/OrderTransactionImpl";
export default {
  extends: OrderTransactionImpl,
};
</script>
