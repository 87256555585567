<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold py-3">گزارش فروشگاه مجازی</h5>
      </div>
      <div class="card-body">
        <BForm class="row justify-content-between">
          <DatePickerRange
            newFormat="YYYY-MM-DD"
            newFormatShow="jYYYY-jMM-jDD"
            v-model="filter.date"
          />

          <BButton @click="loadData()" variant="primary">فیلتر سازی</BButton>
        </BForm>
        <br />
        <div v-if="totals != null" class="row totals">
          <div class="col-md-3">
            <strong>تعداد سفارشات</strong>
            <span>
              {{ totals.orders_count | price }}
            </span>
          </div>
          <div class="col-md-3">
            <strong>پرداخت از درگاه</strong>
            <span> {{ totals.payed_by_gateways | price }} تومان </span>
          </div>
          <div class="col-md-3">
            <strong>پرداخت از کیف پول</strong>
            <span> {{ totals.payed_by_wallet | price }}تومان </span>
          </div>
          <div class="col-md-3">
            <strong>تعداد مروجوعی</strong>
            <span>
              {{ totals.refund_quantity | price }}
            </span>
          </div>
          <div class="col-md-3">
            <strong>مبلغ مروجوعی</strong>
            <span> {{ totals.refund_amount | price }}تومان </span>
          </div>

          <div class="col-md-3">
            <strong>مبلغ کارت به کارت</strong>
            <span> {{ totals.sell_cardByCard_amount | price }}تومان </span>
          </div>
          <div class="col-md-3">
            <strong>دریافت نقدی</strong>
            <span> {{ totals.sell_cash_amount | price }}تومان </span>
          </div>
          <div class="col-md-3">
            <strong>تخفیف صندوقدار</strong>
            <span> {{ totals.sell_cashier_discount | price }}تومان </span>
          </div>
          <div class="col-md-3">
            <strong>میزان کد تخفیف</strong>
            <span> {{ totals.sell_coupon_discount | price }}تومان </span>
          </div>
          <div class="col-md-3">
            <strong>جمع مبالغ فروخته شده</strong>
            <span> {{ totals.sell_items_amount | price }}تومان </span>
          </div>
          <div class="col-md-3">
            <strong>مبلغ قابل پرداخت</strong>
            <span> {{ totals.sell_payable_amount | price }}تومان </span>
          </div>
          <div class="col-md-3">
            <strong>دستگاه پوز</strong>
            <span> {{ totals.sell_pos_amount | price }}تومان </span>
          </div>
          <div class="col-md-3">
            <strong>میزان تخفیف محصولات</strong>
            <span> {{ totals.sell_product_discount | price }}تومان </span>
          </div>
          <div class="col-md-3">
            <strong>تعداد فروش</strong>
            <span>
              {{ totals.sell_quantity | price }}
            </span>
          </div>
          <div class="col-md-3">
            <strong>هزینه حمل و نقل</strong>
            <span> {{ totals.sell_shipping | price }}تومان </span>
          </div>
        </div>
        <br />
        <b-table
          v-if="reports"
          :style="{ opacity: disabled ? '0.6' : 1 }"
          bordered
          responsive
          striped
          :fields="fields"
          :items="reports"
          :disabled="disabled"
        >
          <template v-slot:cell(index)="data">
            {{
              data.index +
              1 +
              (!disabled
                ? (page - 1) * per_page
                : (previousPage - 1) * per_page)
            }}
          </template>
          <template v-slot:cell(id)="data">
            <a :href="'/admin/order/' + data.item.id" target="_blank">{{
              data.item.id
            }}</a>
          </template>
          <template v-slot:cell(_total)="data">
            {{ data.item._total | price }}
          </template>
          <template v-slot:cell(mobile)="data">
            <router-link
              :to="'/admin/customers/show/' + data.item.customer.id"
              >{{ data.item.customer.mobile }}</router-link
            >
          </template>

          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | persianDate }}
          </template>
        </b-table>
        <div v-else-if="reports == ''" class="alert alert-danger">
          موردی یافت تشد
        </div>
        <pagination
          :router="true"
          v-if="reports && reports.data != ''"
          :disabled="disabled"
          :limit="2"
          :data="reports"
          @pagination-change-page="changePage"
        />
      </div>
    </div>
  </main>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput, BTable } from "bootstrap-vue";
import {
  getStatusLabel,
  getStatusColor,
} from "@@/core/libraries/adminFunctions";
import DatePickerRange from "@@/core/components/shared/DatePickerRange/DatePickerRange";
import { priceFilter } from "@@/core/libraries/filters";
import sortMixin from "@@/core/libraries/mixins/sortMixin";

export default {
  name: "OrdersReport",
  components: {
    DatePickerRange,
    BTable,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  mixins: [sortMixin],
  data() {
    return {
      totals: null,
      ordersInfo: null,
      attributesInfo: null,
      page: 1,
      previousPage: 1,
      per_page: 50,
      disabled: true,
      reports: null,
      filter: {
        date: {
          startDate: "",
          endDate: "",
        },
      },
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "id",
          label: "شناسه سفارش",
        },

        {
          key: "discount_amount",
          label: "تخفیف",
          formatter: priceFilter,
        },
        {
          key: "shipping_amount",
          label: "هزینه حمل و نقل",
          formatter: priceFilter,
        },
        {
          key: "order_profit",
          label: "سود سفارش ",
          formatter: priceFilter,
        },
        {
          key: "total_amount",
          label: "جمع کل",
          formatter: priceFilter,
        },

        {
          key: "created_at",
          label: "تاریخ",
        },
      ],
    };
  },
  watch: {
    per_page() {
      this.changePage(1);
    },
  },

  methods: {
    getStatusLabel,
    getStatusColor,
    showAttributesInfo(report) {
      this.attributesInfo = report.attribute_info;
      this.$root.$emit("bv::show::modal", "attributes-info");
    },
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData();
    },
    convertToTimeStamp(data) {
      console.log(data);

      var myDate = data;
      myDate = myDate.split("-");
      var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);
      console.log(newDate.getTime() / 1000);
      return newDate.getTime() / 1000;
    },
    async loadData() {
      try {
        this.disabled = true;
        const response = await this.$axios.get("admin/virtualOrdersReports", {
          params: {
            start_date: this.filter.date.startDate,
            end_date: this.filter.date.endDate,
          },
        });
        this.reports = response.data.data.orders;
        this.totals = response.data.data.totals;
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
      }
    },
    // convertToTimeStamp(data) {
    //   console.log(data);
    //   var myDate = data;
    //   myDate = myDate.split("-");
    //   var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
    //   console.log(newDate.getTime());
    // },
  },
};
</script>
<style>
.row.totals div {
  border: 1px solid #e3e3e3;
  padding: 4px 8px;
  display: flex;
  justify-content: space-between;
}
</style>
