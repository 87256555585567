<template>
  <b-modal style="max-height: 500px; overflow-y: scroll;" id="shipping-modal"
           :title="mode === 'edit' ? 'ویرایش حمل و نقل' : 'ثبت حمل و نقل' " :ok-disabled="disabled"
           :cancel-disabled="disabled" @ok="submit" class="in-form-modal" ok-title="ثبت" cancel-title="لغو" size="lg">
    <form ref="form" @submit.prevent="submit">
      <div class="row">
        <b-form-group class="col-md-6" >
          <label for="name"> نام <i class="text-danger">*</i></label>
          <b-form-input id="name" ref="name" name="name" :value="shipping.name"></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" label="مبلغ پیش فرض (تومان)" label-for="default-price">
          <b-form-input :value="shipping.default_price | priceinput"
                        @input="value=>{shipping.default_price = value !== '' ? +value.replace(/\D/g, '') : ''}">
          </b-form-input>
        </b-form-group>
<!--        <b-form-group class="col-md-6" label="حد ارسال رایگان (تومان)" label-for="free-threshold">-->
<!--          <b-form-input :value="shipping.free_threshold | priceinput"-->
<!--                        @input="value=>{shipping.free_threshold = value !== '' ? +value.replace(/\D/g, '') : ''}">-->
<!--          </b-form-input>-->
<!--        </b-form-group>-->
<!--        <b-form-group class="col-md-6" label="سایز هر بسته *">-->
<!--          <b-form-input type="number" min="1" name="packet_size" v-model="shipping.packet_size" />-->
<!--        </b-form-group>-->
<!--        <b-form-group class="col-md-6" label="سایز اولین بسته *">-->
<!--          <b-form-input type="number" min="1" name="first_packet_size" v-model="shipping.first_packet_size" />-->
<!--        </b-form-group>-->
<!--        <b-form-group class="col-md-6" label="هزینه اضافه به ازای هر بسته *">-->
<!--          <b-form-input :value="shipping.more_packet_price | priceinput"-->
<!--                        @input="value=>{shipping.more_packet_price = value !== '' ? +value.replace(/\D/g, '') : ''}"/>-->
<!--        </b-form-group>-->
        <b-form-group class="col-md-6" >
          <label>توضیحات</label>
          <b-form-input name="description" :value="shipping.description"></b-form-input>
        </b-form-group>
        <div class="col-md-6 my-2">
          <b-form-checkbox id="status" :disabled="disabled" v-model="shipping.status" name="status" value="1"
                           unchecked-value="0">
            <span class="pr-5">وضعیت</span>
          </b-form-checkbox>
        </div>
<!--        <div class="col-md-6 my-2">-->
<!--          <b-form-checkbox id="special" :disabled="disabled" v-model="publicItem" value="1"-->
<!--                           unchecked-value="0">-->
<!--            <span class="pr-5">عمومی</span>-->
<!--          </b-form-checkbox>-->
<!--        </div>-->
        <b-form-group class="col-md-6 pb-3" >
          <label for=""> لوگو <i class="text-danger">*</i></label>
          <b-form-file placeholder="فایلی انتخاب نشده" name="logo"></b-form-file>
        </b-form-group>
<!--        <transition name="fade">-->
<!--          <b-form-group v-if="publicItem == 0" class="col-md-6" label="استان">-->
<!--            <multiselect v-model="shipping.provinces" placeholder="انتخاب کنید" open-direction="bottom"-->
<!--                         :options="provinces" label="name" track-by="id" :searchable="true" :multiple="true"-->
<!--                         :close-on-select="false" :show-labels="false" :disabled="disabled">-->
<!--              <template slot="noOptions">-->
<!--                موردی یافت نشد-->
<!--              </template>-->
<!--              <template slot="noResult">-->
<!--                موردی یافت نشد-->
<!--              </template>-->
<!--            </multiselect>-->
<!--          </b-form-group>-->
<!--        </transition>-->
        <b-form-group v-if="$options.configProvider.get('shipping.hasVip')" class="col-md-6" label="نقش">
          <multiselect v-model="shipping.customer_roles" placeholder="انتخاب کنید" open-direction="bottom"
                       :options="customerRoles" label="name" track-by="id" :searchable="true" :multiple="true"
                       :close-on-select="false" :show-labels="false" :disabled="disabled">
            <template slot="noOptions">
              موردی یافت نشد
            </template>
            <template slot="noResult">
              موردی یافت نشد
            </template>
          </multiselect>
        </b-form-group>
        <b-table class="col-md-8 mx-auto" responsive
                 v-if="shipping.provinces != null && shipping.provinces != '' && publicItem != 1" hover bordered
                 :items="shipping.provinces" :fields="fields">
          <template v-slot:cell(price)="data">
            <b-form-input :value="data.item.pivot.price | priceinput"
                          @input="value=>{data.item.pivot.price = value !== '' ? +value.replace(/\D/g, '') : ''}"></b-form-input>
          </template>
        </b-table>
        <b-table class="col-md-8 mx-auto" responsive
                 v-if="shipping.customer_roles != null && shipping.customer_roles != ''" hover bordered
                 :items="shipping.customer_roles" :fields="customerRoleFields">
          <template v-slot:cell(amount)="data">
            <b-form-input :value="data.item.pivot.amount | priceinput"
                          @input="value=>{data.item.pivot.amount = value !== '' ? +value.replace(/\D/g, '') : ''}"></b-form-input>
          </template>
        </b-table>
        <template v-if="shipping.provinces != null && shipping.provinces != '' && publicItem != 1">
          <input v-for="(item, index) in shipping.provinces" :key="item.id" type="hidden"
                 :name="'provinces['+index+'][id]'" :value="item.id">
          <input v-for="(price, index) in shipping.provinces" :key="'price' + index" type="hidden"
                 :name="'provinces['+index+'][price]'" :value="price.pivot.price">
        </template>
        <div v-if="shipping.customer_roles != null && shipping.customer_roles != ''">
          <input v-for="(item, index) in shipping.customer_roles" :key="item.id" type="hidden"
                 :name="'customer_roles['+index+'][id]'" :value="item.id">
          <input v-for="(price, index) in shipping.customer_roles" :key="'price' + index" type="hidden"
                 :name="'customer_roles['+index+'][amount]'" :value="price.pivot.amount">
        </div>
      </div>
    </form>
  </b-modal>
</template>
<script>
import ShippingFormImpl from '@@/core/components/shipping/ShippingForm/ShippingFormImpl'
export default {
  extends: ShippingFormImpl
}
</script>
