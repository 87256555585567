<template>
  <main class="mt-5 admin-slider">
    <span id="add-btn">
      <button @click="newItem" class="btn btn-success">مکان جدید</button>
    </span>

    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">مکان ها</h5>
        <div style="flex: 1"></div>
      </div>
      <div class="card-body">
        <b-table responsive hover bordered :items="items" :fields="fields">
          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | persianDate }}
          </template>
          <template v-slot:cell(edit)="data">
            <span :id="'edit-btn-' + data.item.id">
              <a
                :disabled="!hasPermission('modify_product') ? true : false"
                class="action-btns1 pt-1 px-2"
                @click="editItem(data.item)"
              >
                <i class="feather feather-edit text-primary"></i>
              </a>
            </span>
            <b-tooltip
              v-if="!hasPermission('modify_product')"
              :target="'edit-btn-' + data.item.id"
              triggers="hover"
            >
              شما به این بخش دسترسی ندارید
            </b-tooltip>
          </template>
          <template v-slot:cell(delete)="data">
            <span :id="'delete-btn-' + data.item.id">
              <a
                :disabled="!hasPermission('delete_product') ? true : false"
                class="action-btns1 pt-1 px-2"
                @click="deleteItem(data.item.id)"
              >
                <i class="feather feather-trash-2 text-danger"></i>
              </a>
            </span>
            <b-tooltip
              v-if="!hasPermission('delete_product')"
              :target="'delete-btn-' + data.item.id"
              triggers="hover"
            >
              شما به این بخش دسترسی ندارید
            </b-tooltip>
          </template>
        </b-table>
        <Loading v-if="items == null" />
      </div>
    </div>
    <b-modal id="location-modal" hide-footer title="ثبت درصد جدید">
      <div id="unitPrice-modalContainer">
        <b-form-group label="عنوان مکان را وارد کنید">
          <b-form-input v-model="title" type="text"> </b-form-input>
        </b-form-group>
        <b-button
          type="button"
          @click="saveData()"
          variant="primary"
          :disabled="disabled"
        >
          {{ selectedId ? "ویرایش" : "ثبت" }}
        </b-button>
      </div>
    </b-modal>
  </main>
</template>
<script>
import storeTransferLocationImpl from "@@/core/pages/Store/storeTransferLocation/storeTransferLocationImpl";
export default {
  extends: storeTransferLocationImpl,
};
</script>
