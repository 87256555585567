<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold py-3">گزارش سفارشات</h5>
        <div style="flex: 1"></div>
        <!-- <button @click="print" class="btn btn-primary">
          <span
            style="transform: translateY(3px)"
            class="feather-printer"
          ></span>
        </button> -->
      </div>
      <div class="row px-4 not-in-print">
        <form @submit.prevent="doFilter" class="d-flex col-12 flex-wrap">
          <div class="col-md-12 d-flex align-items-center">
            <div class="col-4 d-flex">
              <b-form-group label="از تاریخ :">
                <date-picker
                  type="datetime"
                  v-model="startDate"
                  :format="'YYYY-MM-DD HH:mm'"
                  :display-format="'jYYYY-jMM-jDD HH:mm'"
                />
              </b-form-group>
            </div>
            <div class="col-4 d-flex">
              <b-form-group label="تا تاریخ :">
                <date-picker
                  type="datetime"
                  v-model="endDate"
                  :format="'YYYY-MM-DD HH:mm'"
                  :display-format="'jYYYY-jMM-jDD HH:mm'"
                />
              </b-form-group>
            </div>
            <div class="col-md-4 d-flex" style="gap: 8px">
              <button
                :disabled="disabled"
                type="submit"
                class="btn btn-primary"
              >
                فیلتر
              </button>
              <button
                @click="resetFilter"
                :disabled="disabled"
                type="submit"
                class="btn btn-warning"
              >
                برداشتن فیلتر
              </button>
            </div>
          </div>
        </form>
      </div>
      <div id="print" class="p-4">
        <table
          class="table table-bordered table-hover"
          v-if="totals && sum_totals"
        >
          <thead>
            <tr>
              <th>نوع رکورد /نوع فروش</th>
              <th>نمایندگان</th>
              <th>فروشگاه مجازی</th>
              <th>وبسایت نمایندگان</th>
              <th>وبسایت اصلی</th>
              <th>فروشگاه حضوری</th>
              <th>جمع کل</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>تعداد سفارشات</td>
              <td>
                {{ totals.agents.orders_count | price }}
              </td>
              <td>
                {{ totals.virtual.orders_count | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.orders_count | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.orders_count | price }}
              </td>
              <td>
                {{ totals.real.orders_count | price }}
              </td>
              <td>
                {{ sum_totals.orders_count | price }}
              </td>
            </tr>
            <tr>
              <td>تعداد محصولات</td>

              <td>
                {{ totals.agents.sell_quantity | price }}
              </td>
              <td>
                {{ totals.virtual.sell_quantity | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.sell_quantity | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.sell_quantity | price }}
              </td>
              <td>
                {{ totals.real.sell_quantity | price }}
              </td>
              <td>
                {{ sum_totals.sell_quantity | price }}
              </td>
            </tr>

            <tr>
              <td>تخفیف های محصولات</td>

              <td>
                {{ totals.agents.sell_product_discount | price }}
              </td>
              <td>
                {{ totals.virtual.sell_product_discount | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.sell_product_discount | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.sell_product_discount | price }}
              </td>
              <td>
                {{ totals.real.sell_product_discount | price }}
              </td>
              <td>
                {{ sum_totals.sell_product_discount | price }}
              </td>
            </tr>
            <tr>
              <td>مجموع مبالغ محصولات فروخته شده</td>

              <td>
                {{ totals.agents.sell_items_amount | price }}
              </td>
              <td>
                {{ totals.virtual.sell_items_amount | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.sell_items_amount | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.sell_items_amount | price }}
              </td>
              <td>
                {{ totals.real.sell_items_amount | price }}
              </td>
              <td>
                {{ sum_totals.sell_items_amount | price }}
              </td>
            </tr>
            <tr>
              <td>تخفیف کدهای تخفیف</td>

              <td>
                {{ totals.agents.sell_coupon_discount | price }}
              </td>
              <td>
                {{ totals.virtual.sell_coupon_discount | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.sell_coupon_discount | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.sell_coupon_discount | price }}
              </td>
              <td>
                {{ totals.real.sell_coupon_discount | price }}
              </td>
              <td>
                {{ sum_totals.sell_coupon_discount | price }}
              </td>
            </tr>
            <tr>
              <td>تخفیف های فروشندگان</td>

              <td>
                {{ totals.agents.sell_cashier_discount | price }}
              </td>
              <td>
                {{ totals.virtual.sell_cashier_discount | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.sell_cashier_discount | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.sell_cashier_discount | price }}
              </td>
              <td>
                {{ totals.real.sell_cashier_discount | price }}
              </td>
              <td>
                {{ sum_totals.sell_cashier_discount | price }}
              </td>
            </tr>
            <tr>
              <td>حمل و نقل</td>

              <td>
                {{ totals.agents.sell_shipping | price }}
              </td>
              <td>
                {{ totals.virtual.sell_shipping | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.sell_shipping | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.sell_shipping | price }}
              </td>
              <td>
                {{ totals.real.sell_shipping | price }}
              </td>
              <td>
                {{ sum_totals.sell_shipping | price }}
              </td>
            </tr>
            <tr>
              <td>مبلغ قابل پرداخت</td>

              <td>
                {{ totals.agents.sell_payable_amount | price }}
              </td>
              <td>
                {{ totals.virtual.sell_payable_amount | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.sell_payable_amount | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.sell_payable_amount | price }}
              </td>
              <td>
                {{ totals.real.sell_payable_amount | price }}
              </td>
              <td>
                {{ sum_totals.sell_payable_amount | price }}
              </td>
            </tr>
            <tr>
              <td>مبلغ سود</td>

              <td>
                {{ totals.agents.profit | price }}
              </td>
              <td>
                {{ totals.virtual.profit | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.profit | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.profit | price }}
              </td>
              <td>
                {{ totals.real.profit | price }}
              </td>
              <td>
                {{ sum_totals.profit | price }}
              </td>
            </tr>
            <tr>
              <td>نقدی</td>

              <td>
                {{ totals.agents.sell_cash_amount | price }}
              </td>
              <td>
                {{ totals.virtual.sell_cash_amount | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.sell_cash_amount | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.sell_cash_amount | price }}
              </td>
              <td>
                {{ totals.real.sell_cash_amount | price }}
              </td>
              <td>
                {{ sum_totals.sell_cash_amount | price }}
              </td>
            </tr>
            <tr>
              <td>کارت به کارت</td>

              <td>
                {{ totals.agents.sell_cash_amount | price }}
              </td>
              <td>
                {{ totals.virtual.sell_cash_amount | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.sell_cash_amount | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.sell_cash_amount | price }}
              </td>
              <td>
                {{ totals.real.sell_cash_amount | price }}
              </td>
              <td>
                {{ sum_totals.sell_cash_amount | price }}
              </td>
            </tr>
            <tr>
              <td>دستگاه پوز</td>

              <td>
                {{ totals.agents.sell_pos_amount | price }}
              </td>
              <td>
                {{ totals.virtual.sell_pos_amount | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.sell_pos_amount | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.sell_pos_amount | price }}
              </td>
              <td>
                {{ totals.real.sell_pos_amount | price }}
              </td>
              <td>
                {{ sum_totals.sell_pos_amount | price }}
              </td>
            </tr>
            <tr>
              <td>تعداد مرجوعی</td>

              <td>
                {{ totals.agents.refund_quantity | price }}
              </td>
              <td>
                {{ totals.virtual.refund_quantity | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.refund_quantity | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.refund_quantity | price }}
              </td>
              <td>
                {{ totals.real.refund_quantity | price }}
              </td>
              <td>
                {{ sum_totals.refund_quantity | price }}
              </td>
            </tr>
            <tr>
              <td>مبلغ مرجوعی</td>

              <td>
                {{ totals.agents.refund_amount | price }}
              </td>
              <td>
                {{ totals.virtual.refund_amount | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.refund_amount | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.refund_amount | price }}
              </td>
              <td>
                {{ totals.real.refund_amount | price }}
              </td>
              <td>
                {{ sum_totals.refund_amount | price }}
              </td>
            </tr>
            <tr>
              <td>پرداخت شده با کیف پول</td>

              <td>
                {{ totals.agents.payed_by_wallet | price }}
              </td>
              <td>
                {{ totals.virtual.payed_by_wallet | price }}
              </td>
              <td>
                {{ totals.agentsWebsites.payed_by_wallet | price }}
              </td>
              <td>
                {{ totals.sajadWebsite.payed_by_wallet | price }}
              </td>
              <td>
                {{ totals.real.payed_by_wallet | price }}
              </td>
              <td>
                {{ sum_totals.payed_by_wallet | price }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="card-header border-0">
          <h5 class="card-title font-bold py-3">لیست اقلام</h5>
        </div>
        <b-table
          id="main-table"
          v-if="reports"
          bordered
          striped
          :fields="fields"
          :items="reports"
          responsive="sm"
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(amount)="data">
            {{ data.item.total_amount | price }}
          </template>

          <template v-slot:cell(discount_amount)="data">
            {{ data.item.discount_amount | price }}
          </template>

          <template v-slot:cell(customer)="data">
            {{
              data.item.sell_type == "real"
                ? data.item.user
                  ? data.item.user.first_name
                    ? data.item.user.first_name + " " + data.item.user.last_name
                    : data.item.mobile
                  : ""
                : !data.item.receiver_first_name
                ? "-"
                : data.item.receiver_first_name +
                  " " +
                  data.item.receiver_last_name
            }}
          </template>
          <template v-slot:cell(sell_type)="data">
            {{ getSellType(data.item.sell_type) }}
          </template>
        </b-table>
      </div>
      <div class="d-flex flex-column w-100">
        <!--        <div>-->
        <!--          <div class="d-flex">-->
        <!--            <div class="p-5" style="border: 1px solid" v-for="field in fields">-->
        <!--              {{ field.label }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="d-flex w-100" v-for="item in reports">-->
        <!--            <div class="p-5" style="border: 1px solid" v-for="field in fields">-->
        <!--              {{ item[field.key] }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </main>
</template>

<script>
import { BTable, BFormGroup, BFormInput, BTr, BTd } from "bootstrap-vue";
import DatePickerRange from "@@/core/components/shared/DatePickerRange/DatePickerRange";
import Dropdown from "@@/core/components/shared/Dropdown/Dropdown";
import configProvider from "@@/core/configProvider";
import reportMixin from "@/core/mixins/reportMixin";
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";

export default {
  name: "ProductsReport",
  components: {
    Dropdown,
    DatePickerRange,
    BTable,
    BFormGroup,
    BFormInput,
    BTr,
    BTd,
    ProductSearch,
  },
  configProvider,
  data() {
    return {
      reportsLoading: false,
      selectedProduct: null,
      selectedVariety: null,
      disabled: false,
      totals: null,
      sum_totals: null,
      index: 1,
      fields: [
        {
          key: "index",
          label: " ردیف",
          sortable: true,
        },
        {
          key: "customer",
          label: "سفارش دهنده",
          sortable: true,
        },
        {
          key: "receiver_mobile",
          label: "شماره تماس",
          sortable: true,
        },

        {
          key: "amount",
          label: "میزان فروش کل (تومان)",
          sortable: true,
        },
        {
          key: "discount_amount",
          label: "قیمت تخفیف (تومان)",
        },
        {
          key: "sell_type",
          label: "سفارش از",
        },
      ],

      types: [
        { id: "sell", label: "فروش" },
        { id: "refund", label: "مرجوعی" },
      ],
      sell_types: [
        { id: "agents", label: "نمایندگان" },
        { id: "virtual", label: "مجازی" },
        { id: "real", label: "حضوری" },
        { id: "agentsWebsites", label: "وبسایت نماینده ها" },
        { id: "sajadWebsite", label: "وبسایت اصلی " },
      ],
      type: { id: "sell", label: "فروش" },
      endDate: null,
      startDate: null,
      productsList: [{ status: true }],
      page: 1,
      reports: null,
      sortBy: null,
      sortDesc: null,

      showVarietiesProductId: null,
    };
  },
  mixins: [reportMixin],

  methods: {
    getSellType(type) {
      let finded = this.sell_types.find((item) => {
        return item.id == type;
      });
      return finded ? finded.label : "";
    },
    resetFilter() {
      this.sell_type = null;
      this.type = { id: "sell", label: "فروش" };
      this.endDate = null;
      this.startDate = null;
      this.productsList = [{ status: true }];
    },
    handlerItem(content) {
      if (typeof content == "string") {
        this.productsList.push({ status: true });
        this.$forceUpdate();
      } else {
        this.productsList[content].status = false;
        this.$forceUpdate();
      }
    },
    selectTheVariety(e) {
      this.selectedVariety = e;
      if (e.rowIndex != null) {
        this.productsList[e.rowIndex].variety = e;
      }
    },
    selectTheProduct(e) {
      console.log(e);
      this.selectedProduct = e;
    },
    print() {
      print();
    },
    doFilter() {
      this.loadData();
    },
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData();
    },
    async loadData() {
      try {
        this.disabled = true;
        this.reportsLoading = true;
        let selectedVariety = [];
        this.productsList
          .filter((item) => item.status)
          .forEach((item) => {
            if (item.variety) selectedVariety.push(item.variety.id);
          });
        const response = await this.$axios.get(
          "admin/accounting/orders_report",
          {
            params: {
              start_date: this.startDate,
              end_date: this.endDate,
              type: this.type ? this.type.id : null,
              sell_type: this.sell_type ? this.sell_type.id : null,
              variety_ids: selectedVariety.toString(),
            },
          }
        );
        this.reports = response.data.data.allOrders;
        this.totals = response.data.data.totals;
        this.sum_totals = response.data.data.sum_totals;
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
        this.reportsLoading = false;
      }
    },
    showVarieties(productId) {
      this.$root.$emit("bv::show::modal", "show-varieties-modal");
      this.showVarietiesProductId = productId;
    },
  },
};
</script>

<style>
.print-only {
  display: none;
}
.show-in-print {
  display: none;
}
@media print {
  .show-in-print {
    display: block;
  }
  .not-in-print {
    display: none !important;
  }
  .print-justify-center {
    justify-content: center;
  }
  .app-content .side-app {
    padding: 0 !important;
  }

  .print-only {
    display: block !important;
  }

  body * {
    visibility: hidden;
  }
  #print,
  #print * {
    visibility: visible;
    font-size: 1.25rem;
  }
  #print {
    padding: 0 !important;
  }
  #print {
    width: 100%;
  }

  #print .not-in-print {
    display: none;
  }

  .mr-2-print {
    margin-right: 20px;
  }
}
</style>
