<template>
  <b-modal id="order-edit" hide-footer title="ویرایش سفارش">
    <form   v-if="!showAddressForm" @submit.prevent="submit()">
      <b-form-group v-if="order.customer">
        <div class="d-flex justify-content-between mb-1">
          <label for=""> آدرس <i class="text-danger">*</i> </label>
          <button
            @click.prevent="newAddress"
            class="btn btn-success"
            :disabled="disabled"
          >
            افزودن آدرس
          </button>
        </div>

        <multiselect
          v-model="order.address"
          :allow-empty="false"
          placeholder="انتخاب کنید"
          open-direction="bottom"
          :options="addresses"
          label="label"
          track-by="id"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
          @input="setShipping($event)"
        >
          <template slot="noOptions">
            موردی یافت نشد
          </template>
          <template slot="noResult">
            موردی یافت نشد
          </template>
        </multiselect>
      </b-form-group>
      <b-form-group v-if="order.address">
        <div class="d-flex justify-content-between mb-1">
          <label class="pb-1" for="">
            حمل و نقل <i class="text-danger">*</i></label
          >
          <span
            >هزینه ارسال :
            <span v-if="getShippingPrice"
              >{{ getShippingPrice | priceinput }} تومان</span
            >
            <span v-else>رایگان</span>
          </span>
        </div>
        <multiselect
          v-model="order.shipping"
          :allow-empty="false"
          placeholder="انتخاب کنید"
          open-direction="bottom"
          :options="addressShippings"
          label="name"
          track-by="id"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
        >
          <template slot="noOptions">
            موردی یافت نشد
          </template>
          <template slot="noResult">
            موردی یافت نشد
          </template>
        </multiselect>
      </b-form-group>
      <b-form-group>
        <label class="pb-1" for=""> تخفیف (تومان) </label>
        <b-form-input
          :value="order.discount_amount | priceinput"
          @input="
            (value) => {
              order.discount_amount =
                value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
        >
        </b-form-input>
      </b-form-group>
      <b-form-group>
        <label class="pb-1" for=""> توضیحات </label>
        <b-form-textarea
          v-model="order.description"
          name="description"
        ></b-form-textarea>
      </b-form-group>
      <div class="text-center mt-3">
        <button class="btn btn-primary" :disabled="disabled">
          ویرایش
        </button>
      </div>
    </form>
    <div v-else id="addAddressInEditOrder" class="modal-body text-center px-4">
      <div class="d-flex justify-content-between">
        <h3>افزودن آدرس</h3>
        <button class="btn btn-outline-info" @click="showAddressForm = false">
          <span class="fa fa-arrow-left"></span>
        </button>
      </div>

      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          id="address-form"
          @submit.prevent="(e) => handleSubmit(() => submitAddress(e))"
        >
          <b-row>
            <b-col md="6" v-if="provinces != null" class="text-right">
              <b-form-group label="استان" label-align="right">
                <multiselect
                  v-model="address.province"
                  :allow-empty="false"
                  placeholder="استان"
                  open-direction="bottom"
                  :options="provinces"
                  label="name"
                  track-by="id"
                  :searchable="true"
                  :multiple="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col md="6" class="text-right">
              <ValidationProvider
                v-if="cities != null"
                name="city"
                mode="passive"
                class="mb-3 d-flex flex-column text-right"
                rules="required"
                v-slot="v"
              >
                <label for="">شهر :</label>
                <div class="variety-title ">
                  <multiselect
                    v-model="address.city"
                    :allow-empty="false"
                    placeholder="شهر"
                    open-direction="bottom"
                    :options="cities"
                    label="name"
                    track-by="id"
                    :searchable="true"
                    :multiple="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :disabled="disabled"
                  >
                    <template slot="noOptions">
                      موردی یافت نشد
                    </template>
                    <template slot="noResult">
                      موردی یافت نشد
                    </template>
                  </multiselect>
                </div>
                <!-- <input name="categories" @change="v.validate" type="hidden"
                        :value="selected_category != null ? selected_category : ''"> -->
                <div v-if="address.city != null">
                  <input
                    name="city"
                    @change="v.validate"
                    type="hidden"
                    :value="address.city.id"
                  />
                </div>
                <span class="text-right text-danger mt-2 mx-2">{{
                  v.errors[0]
                }}</span>
              </ValidationProvider>
            </b-col>
            <b-col md="6" class="text-right">
              <ValidationProvider
                name="first_name"
                mode="passive"
                rules="required"
                v-slot="v"
              >
                <label for="">نام :</label>
                <input
                  v-model="address.first_name"
                  :disabled="disabled"
                  name="first_name"
                  @change="v.validate"
                  type="text"
                  placeholder="نام"
                  class="border-radius7 form-control"
                  :class="v.errors[0] ? 'border-danger' : ''"
                />
                <span class="text-right text-danger mt-2 mx-2">{{
                  v.errors[0]
                }}</span>
              </ValidationProvider>
            </b-col>
            <b-col md="6" class="text-right">
              <ValidationProvider
                name="last_name"
                mode="passive"
                rules="required"
                v-slot="v"
              >
                <label for="">نام خانوادگی :</label>
                <input
                  v-model="address.last_name"
                  :disabled="disabled"
                  name="last_name"
                  @change="v.validate"
                  type="text"
                  placeholder="نام خانوادگی"
                  class="border-radius7 form-control"
                  :class="v.errors[0] ? 'border-danger' : ''"
                />
                <span class="text-right text-danger mt-2 mx-2">{{
                  v.errors[0]
                }}</span>
              </ValidationProvider>
            </b-col>
            <b-col md="6" class="text-right">
              <ValidationProvider
                name="postal_code"
                mode="passive"
                rules="required"
                v-slot="v"
              >
                <label for="">کد پستی :</label>
                <input
                  v-model="address.postal_code"
                  :disabled="disabled"
                  name="postal_code"
                  @change="v.validate"
                  type="text"
                  placeholder="کد پستی"
                  class="border-radius7 form-control"
                  :class="v.errors[0] ? 'border-danger' : ''"
                />
                <span class="text-right text-danger mt-2 mx-2">{{
                  v.errors[0]
                }}</span>
              </ValidationProvider>
            </b-col>
            <b-col md="6" class="text-right">
              <ValidationProvider
                name="mobile"
                mode="passive"
                rules="required"
                v-slot="v"
              >
                <label for="">موبایل :</label>
                <input
                  v-model="address.mobile"
                  :disabled="disabled"
                  name="mobile"
                  @change="v.validate"
                  type="text"
                  placeholder="موبایل"
                  class="border-radius7 form-control"
                  :class="v.errors[0] ? 'border-danger' : ''"
                />
                <span class="text-right text-danger mt-2 mx-2">{{
                  v.errors[0]
                }}</span>
              </ValidationProvider>
            </b-col>
            <b-col md="12" class="text-right">
              <ValidationProvider
                name="address"
                mode="passive"
                rules="required"
                v-slot="v"
                class="form-group"
              >
                <label for="">آدرس :</label>
                <textarea
                  v-model="address.address"
                  :disabled="disabled"
                  name="address"
                  @change="v.validate"
                  type="text"
                  class="border-radius7 form-control"
                  :class="v.errors[0] ? 'border-danger' : ''"
                ></textarea>
                <span class="text-right text-danger mt-2 mx-2">{{
                  v.errors[0]
                }}</span>
              </ValidationProvider>
            </b-col>
          </b-row>
          <div class="text-center mt-3">
            <button
              class="btn"
              :disabled="disabled"
              :class="mode == 'create' ? 'btn-success' : 'btn-primary'"
              type="submit"
            >
              {{ mode == "create" ? "ثبت" : "ویرایش" }} آدرس
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>
<script>
import OrderEditImpl from "@@/core/components/order/OrderEdit/OrderEditImpl";

export default {
  extends: OrderEditImpl,
};
</script>
<style
  src="@@/core/components/address/AddressModal/AddressModalStylePublic.css"
></style>
