<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold"> نمایندگان</h5>
      </div>
      <div class="card-body">
        <div>
          <b-table
            v-if="agentsOrder"
            responsive
            hover
            bordered
            :items="agentsOrder"
            :fields="fields"
          >
            <template v-slot:cell(show)="data">
              <a
              class="seeMore"
                target="_blank"
                :href="`${data.item.front_url}/admin/login?username=${data.item.username}&password=${data.item.password}`"
              >
              <span>
                مشاهده </span>
              <i class="fa fa-eye"></i>
            </a>
            </template>
          </b-table>
          <Loading v-if="!agentsOrder" />

          <div
            v-else-if="agentsOrder && agentsOrder.length == 0"
            class="alert alert-danger"
          >
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import agentsImpl from "@@/core/pages/agents/agentsImpl.js";
export default {
  extends: agentsImpl,
};
</script>
<style>
.seeMore {
    background-color: #00a1ff;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
}
</style>
