<template>
  <button :disabled="disabled" @click="download" class="btn btn-success">
    دانلود اکسل
  </button>
</template>

<script>
export default {
  name: "ExcelDownload",
  props: {
    url: String,
    extraQuery:{
      type:Object,
      default:null
    }
  },
  data() {
    return {
      percentCompleted: null,
      disabled: false,
    }
  },
  methods: {
    async download() {
      this.disabled = true;
      let params={
          parsa:1
        };
        if(this.extraQuery){
          for (const key in this.extraQuery) {
            params[key]=this.extraQuery[key]
          }
        }
      const response = await this.$axios(this.url, {
        headers: {
          "Accept": "x-xlsx",
        },
        params: params,
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(progressEvent.lengthComputable);
          console.log(percentCompleted);

        }
      });
      this.disabled = false
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'output.xlsx');
      document.body.appendChild(link);
      link.click();
    },
  }
}
</script>

<style scoped>

</style>
