<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold py-3">گزارش محصولات</h5>
        <div style="flex: 1"></div>
        <button @click="print" class="btn btn-primary">
          <span
            style="transform: translateY(3px)"
            class="feather-printer"
          ></span>
        </button>
      </div>
      <div class="row px-4 not-in-print">
        <form @submit.prevent="doFilter" class="d-flex col-12 flex-wrap">
          <b-form-group class="col-xl-4 col-md-12" label="نوع گزارش :">
            <multiselect
              placeholder="نوع را انتخاب کنید"
              v-model="type"
              open-direction="bottom"
              :options="types"
              label="label"
              track-by="id"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
            >
              <template slot="noOptions"> موردی یافت نشد </template>
              <template slot="noResult"> موردی یافت نشد </template>
            </multiselect>
          </b-form-group>

          <b-form-group class="col-xl-4 col-md-12" label="تامین کننده :">
            <multiselect
              placeholder="تامین کننده را انتخاب کنید"
              v-model="provider"
              open-direction="bottom"
              :options="providers"
              label="full_name"
              track-by="id"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
            >
              <template slot="noOptions"> موردی یافت نشد </template>
              <template slot="noResult"> موردی یافت نشد </template>
            </multiselect>
          </b-form-group>
          <b-form-group class="col-xl-4 col-md-12" label="نوع فروش :">
            <multiselect
              placeholder="نوع فروش را انتخاب کنید"
              v-model="sell_type"
              open-direction="bottom"
              :options="sell_types"
              label="label"
              track-by="id"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
            >
              <template slot="noOptions"> موردی یافت نشد </template>
              <template slot="noResult"> موردی یافت نشد </template>
            </multiselect>
          </b-form-group>
          <div class="col-xl-4 col-md-12 d-flex">
            <div class="col-6 d-flex">
              <b-form-group label="از تاریخ :">
                <date-picker
                  type="datetime"
                  v-model="startDate"
                  :format="'YYYY-MM-DD HH:mm'"
                  :display-format="'jYYYY-jMM-jDD HH:mm'"
                />
              </b-form-group>
            </div>
            <div class="col-6 d-flex">
              <b-form-group label="تا تاریخ :">
                <date-picker
                  type="datetime"
                  v-model="endDate"
                  :format="'YYYY-MM-DD HH:mm'"
                  :display-format="'jYYYY-jMM-jDD HH:mm'"
                />
              </b-form-group>
            </div>
          </div>
          <div class="col-md-12">
            <div
              class="d-flex align-items-center"
              v-for="(item, index) in productsList"
              :key="index"
            >
              <template v-if="item.status">
                <button
                  v-if="index == 0"
                  type="button"
                  class="btn btn-primary"
                  @click="handlerItem('add')"
                >
                  +
                </button>
                <button
                  @click="handlerItem(index)"
                  type="button"
                  v-if="index != 0"
                  class="btn btn-danger"
                >
                  <i class="fa fa-trash"></i>
                </button>
                <ProductSearch
                  :rowIndex="index"
                  class="row col-11"
                  @selectProduct="selectTheProduct"
                  @selectVariety="selectTheVariety"
                  :disabled="disabled"
                />
              </template>
            </div>
          </div>

          <div class="col-12 d-flex justify-content-end" style="gap: 8px">
            <button :disabled="disabled" type="submit" class="btn btn-primary">
              فیلتر
            </button>
            <button
              @click="resetFilter"
              :disabled="disabled"
              type="submit"
              class="btn btn-warning"
            >
              برداشتن فیلتر
            </button>
          </div>
        </form>
      </div>
      <div id="print" class="p-4">
        <b-table
          id="main-table"
          v-if="reports"
          bordered
          striped
          :fields="fields"
          :items="reports"
          responsive="sm"
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(amount)="data">
            {{ data.item.amount | price }}
          </template>
          <template v-slot:cell(diff_amount_from_real)="data">
            {{ data.item.diff_amount_from_real | price }}
          </template>
          <template v-slot:cell(discount_amount)="data">
            {{ data.item.discount_amount | price }}
          </template>
          <template v-slot:cell(purchase_price)="data">
            {{ data.item.purchase_price | price }}
          </template>
          <template v-slot:cell(profit)="data">
            {{ data.item.profit | price }}
          </template>
          <template #bottom-row="data">
            <b-td colspan="2">
              <div class="text-left">جمع کل :</div>
            </b-td>
            <b-td>
              <strong>{{ totals.sum_quantity | price }} </strong>
            </b-td>

            <b-td>
              <strong> - </strong>
            </b-td>
            <b-td>
              <strong>{{ totals.sum_discount_amount | price }} تومان</strong>
            </b-td>
            <b-td>
              <strong>{{ totals.sum_profit | price }} تومان</strong>
            </b-td>
            <b-td>
              <strong> {{ totals.sum_amount | price }} تومان</strong></b-td
            >
          </template>
        </b-table>
        <table v-if="totals" class="d-flex justify-content-between"></table>
        <ShowVarietiesModal
          :product-id="showVarietiesProductId"
          :report-code="reportCode"
        />
        <div v-if="reportsLoading" class="alert alert-primary">
          در حال بارگزاری
        </div>
      </div>
      <div class="d-flex flex-column w-100"></div>
    </div>
  </main>
</template>

<script>
import { BTable, BFormGroup, BFormInput, BTr, BTd } from "bootstrap-vue";
import DatePickerRange from "@@/core/components/shared/DatePickerRange/DatePickerRange";
import ShowVarietiesModal from "@@/core/components/report/ShowVarietiesModal";
import Dropdown from "@@/core/components/shared/Dropdown/Dropdown";
import configProvider from "@@/core/configProvider";
import reportMixin from "@/core/mixins/reportMixin";
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";

export default {
  name: "ProductsReport",
  components: {
    Dropdown,
    ShowVarietiesModal,
    DatePickerRange,
    BTable,
    BFormGroup,
    BFormInput,
    BTr,
    BTd,
    ProductSearch,
  },
  configProvider,
  data() {
    return {
      reportsLoading: false,
      selectedProduct: null,
      selectedVariety: null,
      disabled: false,
      totals: null,
      index: 1,
      fields: [
        {
          key: "index",
          label: " ردیف",
          sortable: true,
        },
        {
          key: "product_title",
          label: "عنوان",
          sortable: true,
        },
        {
          key: "quantity",
          label: "تعداد",
        },
        {
          key: "purchase_price",
          label: "قیمت خرید واحد (تومان)",
          sortable: true,
        },

        {
          key: "discount_amount",
          label: "قیمت تخفیف (تومان)",
        },
        {
          key: "profit",
          label: "سود (تومان)",
        },

        {
          key: "amount",
          label: "میزان فروش کل (تومان)",
          sortable: true,
        },
        // {
        //   key: "diff_amount_from_real",
        //   label: "ماتفاوت",
        // },
      ],
      types: [
        { id: "sell", label: "فروش" },
        { id: "refund", label: "مرجوعی" },
      ],
      sell_types: [
        { id: "agents", label: "نمایندگان" },
        { id: "virtual", label: "مجازی" },
        { id: "real", label: "حضوری" },
        { id: "agentsWebsites", label: "وبسایت نماینده ها" },
        { id: "sajadWebsite", label: "وبسایت اصلی " },
      ],
      sell_type: null,
      type: { id: "sell", label: "فروش" },
      endDate: null,
      startDate: null,
      productsList: [{ status: true }],
      page: 1,
      reports: null,
      sortBy: null,
      sortDesc: null,
      providers: [],
      provider: null,
      showVarietiesProductId: null,
    };
  },
  mixins: [reportMixin],

  methods: {
    getProdviders() {
      this.$axios
        .get("https://api.sajadcameron.ir/v1/admin/providers")
        .then((res) => {
          this.providers = res.data.data.providers;
        });
    },
    resetFilter() {
      this.sell_type = null;
      this.type = { id: "sell", label: "فروش" };
      this.endDate = null;
      this.startDate = null;
      this.productsList = [{ status: true }];
    },
    handlerItem(content) {
      if (typeof content == "string") {
        this.productsList.push({ status: true });
        this.$forceUpdate();
      } else {
        this.productsList[content].status = false;
        this.$forceUpdate();
      }
    },
    selectTheVariety(e) {
      this.selectedVariety = e;
      if (e.rowIndex != null) {
        this.productsList[e.rowIndex].variety = e;
      }
    },
    selectTheProduct(e) {
      this.selectedProduct = e;
      this.productsList[e.rowIndex].product_id = e.id;
    },
    print() {
      print();
    },
    doFilter() {
      this.loadData();
    },
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData();
    },
    async loadData() {
      try {
        this.disabled = true;
        this.reportsLoading = true;
        let selectedVariety = [];
        let selectedProducts = [];
        this.productsList
          .filter((item) => item.status)
          .forEach((item) => {
            if (item.variety) {
              selectedVariety.push(item.variety.id);
            }
            if (item.product_id) {
              selectedProducts.push(item.product_id);
            }
          });
        const response = await this.$axios.get(
          "admin/accounting/product_report",
          {
            params: {
              start_date: this.startDate,
              end_date: this.endDate,
              type: this.type ? this.type.id : null,
              sell_type: this.sell_type ? this.sell_type.id : null,
              variety_ids: selectedVariety.toString(),
              product_ids: selectedProducts.toString(),
              provider_id: this.provider ? this.provider.id : "",
            },
          }
        );
        this.reports = response.data.data.product_lists;
        this.totals = response.data.data.totals;
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
        this.reportsLoading = false;
      }
    },
    showVarieties(productId) {
      this.$root.$emit("bv::show::modal", "show-varieties-modal");
      this.showVarietiesProductId = productId;
    },
  },
  mounted() {
    this.getProdviders();
  },
};
</script>

<style>
.print-only {
  display: none;
}
.show-in-print {
  display: none;
}
@media print {
  .show-in-print {
    display: block;
  }
  .not-in-print {
    display: none !important;
  }
  .print-justify-center {
    justify-content: center;
  }
  .app-content .side-app {
    padding: 0 !important;
  }

  .print-only {
    display: block !important;
  }

  body * {
    visibility: hidden;
  }
  #print,
  #print * {
    visibility: visible;
    font-size: 1.25rem;
  }
  #print {
    padding: 0 !important;
  }
  #print {
    width: 100%;
  }

  #print .not-in-print {
    display: none;
  }

  .mr-2-print {
    margin-right: 20px;
  }
}
</style>
