import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";
import Swal from "sweetalert2";
import {
  BTooltip,
  BButton,
  BCard,
  BTable,
  BCollapse,
  BFormGroup,
  BFormInput,
  VBToggle,
  VBTooltip,
} from "bootstrap-vue";
export default {
  name: "Products",
  mixins: [mixins],
  components: {
    Loading,
    BTooltip,
    BButton,
    BCard,
    BTable,
    BCollapse,
    BFormGroup,
    BFormInput,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      search: "",
      url: "admin/userSajad",
      start_date: "",
      end_date: "",
      widgets: ["categories"],
      startDate: "",
      endDate: "",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "fullName",
          label: "نام کامل",
        },
        {
          key: "mobile",
          label: "شماره تماس",
        },

        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "seen",
          label: "مشاهده",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      showingCategories: [],
      statusOptions: [
        {
          value: "available",
          name: "موجود",
        },
        {
          value: "soon",
          name: "بزودی",
        },
        {
          value: "out_of_stock",
          name: "ناموجود",
        },
        {
          value: "draft",
          name: "پیش نویس",
        },
        {
          value: "",
          name: "همه",
        },
      ],
      approveOptions: [
        {
          name: "تایید شده",
          value: "1",
        },
        {
          name: "تایید نشده",
          value: "0",
        },
        {
          name: "همه",
          value: "",
        },
      ],
      user_types: [
        { id: "real", title: "مشتریان فروشگاه حضوری" },
        { id: "sajadWebsite", title: "مشتریان وبسایت اصلی" },
        { id: "virtualAdmins", title: "مشتریان ادمین های مجازی" },
        { id: "agentsUsers", title: "مشتریان نمایندگان" },
      ],
      filter: {
        id: "",
        user_type: "",
        title: "",
        category_id: "",
        status: "",
        approved_at: "",
      },
    };
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    resetFilter() {
      this.search = "";
      this.searchParams = {};
    },
    async getExcel() {
      try {
        let response = await this.$axios.get(
          `admin/userSajad?start_date=${this.start_date}&end_date=${
            this.end_date
          }${
            this.filter.user_type
              ? "&user_type=" + this.filter.user_type.id
              : ""
          }`,
          {
            headers: {
              Accept: "x-xlsx",
            },
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
              let percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log(progressEvent.lengthComputable);
              console.log(percentCompleted);
            },
          }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "output.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.$root.notify(error);
      }
    },
    filterItem() {
      this.searchParams = {
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        user_type: this.filter.user_type ? this.filter.user_type.id : "",
      };
    },
  },
};
