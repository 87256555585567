import Back from "@@/core/components/shared/Back/Back";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
} from "bootstrap-vue";

export default {
  components: {
    Back,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    BTable,
  },
  data() {
    return {
      storeData: null,
      rows: [],
      totalQuantity: 0,
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "label",
          label: "کالا",
        },
        {
          key: "quantityTransfer",
          label: "تعداد انتقالی",
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    getVarietyTitle(variety) {
      let v = variety;
      if (v.attributes.length == 1) {
        v.title =
          (v.color != null ? v.color.name + " - " : "") +
          v.attributes[0].pivot.value;
      } else if (v.attributes.length > 1) {
        let attr = "";
        v.attributes.forEach((a) => {
          attr = attr + "-" + a.pivot.value;
        });
        v.title = (v.color != null ? v.color.name : "") + attr;
      } else if (v.attributes.length == 0 && v.color != null) {
        v.title = v.color.name;
      }
      v.label = v.title;
      return v.label;
    },
    loadData() {
      this.$axios
        .get(`admin/varietyTransfers/${this.$route.params.id}`)
        .then((res) => {
          this.storeData = res.data.data.varietyTransfer;
          this.totalQuantity = res.data.data.varietyTransfer.items.reduce(
            (a, b) => {
              return a + b.quantity;
            },
            0
          );
        });
    },
    print() {
      print();
    },
  },
};
