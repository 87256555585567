import { render, staticRenderFns } from "./ProductPublishing.vue?vue&type=template&id=5987d2c9&scoped=true&"
import script from "./ProductPublishing.vue?vue&type=script&lang=js&"
export * from "./ProductPublishing.vue?vue&type=script&lang=js&"
import style0 from "@@/core/components/product/small-cards/ProductPublishing/ProductPublishingStyle.css?vue&type=style&index=0&id=5987d2c9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5987d2c9",
  null
  
)

export default component.exports