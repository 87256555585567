import InputGroup from "@@/core/components/product/shared/InputGroup/InputGroup";
import {BFormCheckbox} from "bootstrap-vue";

export default {
  name: "ProductShowConfig",
  components: {InputGroup, BFormCheckbox},
  props: {
    value: Object,
    mode:String
  },
  data() {
    return {
      realValue: this.value,
    }
  },
  watch: {
    value(newVal) {
      this.realValue = newVal;
    },
    realValue(newVal) {
      this.$emit('input', newVal);
    },
  }
}
