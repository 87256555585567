import { BTable } from "bootstrap-vue";
import WalletModal from "@@/core/components/users/userWallet/userWallet";
export default {
  components: { BTable, WalletModal },
  props: {
    user: Object,
  },
  data() {
    return {
      wallet: null,
    };
  },
  methods: {
    newWallet(item) {
      this.type = item;
      this.$root.$emit("bv::show::modal", "user-wallet");
      let wallet = {
        user_id: this.user.id,
        amount: 0,
      };
      this.wallet = wallet;
    },
  },
};
