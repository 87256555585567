<template>
  <div class="card mt-3">
    <div class="card-header border-0">
      <h5 class="card-title">اطلاعات محصول</h5>
    </div>
    <div class="card-body">
      <InputGroup label="نام محصول" required>
        <b-form-input type="text" v-model="realValue.title" placeholder="نام محصول"/>
      </InputGroup>
      <InputGroup label="عنوان انگلیسی" required>
        <b-form-input type="text" v-model="realValue.en_title" placeholder="نام محصول"/>
      </InputGroup>
      <!-- <InputGroup label="لینک محتوا">
        <b-form-input type="text" v-model="realValue.content_link" placeholder="لینک محتوا"/>
      </InputGroup> -->
      <InputGroup label="موجودی">
        <b-form-input v-model="realValue.quantity" type="number" min="0" placeholder="موجودی"/>
      </InputGroup>
      <InputGroup label="بارکد">
        <b-form-input v-model="realValue.barcode" type="text" placeholder="بارکد"/>
      </InputGroup>
      <InputGroup label="SKU">
        <b-form-input v-model="realValue.sku" type="text" placeholder="SKU"/>
      </InputGroup>
      <InputGroup label="دسته بندی" required>
        <treeselect
            :flat="true"
            :searchable="true"
            :multiple="true"
            :options="realOptions"
            noChildrenText="فرزندی وجود ندارد" noOptionsText="موردی وجود ندارد" noResultsText="موردی یافت نشد"
            placeholder="انتخاب دسته بندی ها"
            v-model="realValue.categories"
        />
     
      </InputGroup>
      <InputGroup label="تامین کننده" >
        <treeselect
            :flat="true"
            :searchable="true"
            :multiple="false"
            :options="providersList"
            noChildrenText="فرزندی وجود ندارد" noOptionsText="موردی وجود ندارد" noResultsText="موردی یافت نشد"
            placeholder="انتخاب تامین کننده"
            v-model="realValue.providers"
        />
     
      </InputGroup>
      <InputGroup label="برند">
        <treeselect :options="realBrands" :searchable="true"
                    noResultsText="موردی یافت نشد"
                    v-model="realValue.brand" :multiple="false"
                    noOptionsText="موردی وجود ندارد"
                    placeholder="انتخاب برند">
          <div slot="value-label" slot-scope="{ node }">{{ node.label }}</div>
        </treeselect>
      </InputGroup>
      <InputGroup label="واحد" required>
        <treeselect :options="realUnits" :searchable="true"
                    noResultsText="موردی یافت نشد"
                    v-model="realValue.unit" :multiple="false"
                    noOptionsText="موردی وجود ندارد"
                    placeholder="انتخاب واحد">
          <div slot="value-label" slot-scope="{ node }">{{ node.label }}</div>
        </treeselect>
      </InputGroup>
      <InputGroup customClass="tag-container" label="تگ ها">
        <TreeselectAddable @add-option="addTag" placeholder="انتخاب تگ ها" v-model="realValue.tags" :options="realTags"/>
      </InputGroup>
    </div>
  </div>
</template>

<script>
import ProductInformationImpl from '@@/core/components/product/ProductInformation/ProductInformationImpl'
export default {
  extends: ProductInformationImpl
}
</script>

<style scoped>

</style>
