import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";
import { BButton, BFormGroup, BFormInput } from "bootstrap-vue";
import Treeselect from "@riophae/vue-treeselect";
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "providers",
  mixins: [mixins],
  components: {
    Loading,
    Treeselect,
    BButton,
    BFormGroup,
    BFormInput,
    ProductSearch,
  },

  data() {
    return {
      disable: false,
      url: "admin/specificDiscount",
      discountList: [],
      selectedDiscountItem: null,
      selectedProducts: [],
      selectedCategories: [],
      model_ids: [],
      balance: 0,
      balance_type: { id: "more", label: "بیشتر از" },
      balance_types: [
        { id: "more", label: "بیشتر از" },
        { id: "less", label: "کمتر از" },
      ],
      range_from: 0,
      range_to: 0,
      inLoadId: null,
      discountTitle: null,
      mainData: null,
      categoryList: [],
      types: [
        { id: "product", label: "محصولات" },
        { id: "category", label: "دسته بندی" },
        { id: "balance", label: "موجودی" },
        { id: "range", label: "بازه قیمت" },
      ],
      selectedType: { id: "range", label: "بازه قیمت" },
    };
  },
  mounted() {
    this.getAllCategories();
    this.getDiscount();
  },
  methods: {
    saveDiscount() {
      let fd = new FormData();
      if (this.selectedDiscountItem) {
        fd.append("_method", "PUT");
      }
      fd.append("type", this.selectedType ? this.selectedType.id : null);

      if (this.selectedType && this.selectedType.id == "balance") {
        fd.append("balance", this.balance);
        fd.append(
          "balance_type",
          this.balance_type ? this.balance_type.id : null
        );
      } else if (this.selectedType && this.selectedType.id == "range") {
        fd.append("range_from", this.range_from);
        fd.append("range_to", this.range_to);
      } else if (
        this.selectedType &&
        (this.selectedType.id == "product" ||
          this.selectedType.id == "category")
      ) {
        if (this.selectedType.id == "product") {
          let ids = [];
          this.selectedProducts.forEach((item) => {
            ids.push(item.id);
          });
          fd.append("model_ids", ids.toString());
        } else {
          fd.append("model_ids", this.selectedCategories.toString());
        }
      }
      this.disable = true;
      this.$axios
        .post(
          `/admin/specificDiscountItem/${this.$route.params.item}/items/${
            this.$route.params.id
          }
          ${
            this.selectedDiscountItem ? "/" + this.selectedDiscountItem.id : ""
          }`,
          fd
        )
        .then((res) => {
          this.disable = false;
          this.selectedDiscountItem = null;
          this.$router.push(
            `/admin/discount-config/list/${this.$route.params.item}/${this.$route.params.id}`
          );
          this.$root.notify(res.data.message, "success");
        })
        .catch((err) => {
          this.$root.notify(err);
          this.disable = false;
        });
    },
    removeProduct(id) {
      this.selectedProducts = this.selectedProducts.filter((item) => {
        return item.id != id;
      });
    },
    selectProduct(p) {
      this.selectedProducts.push(p);
    },
    getAllCategories() {
      this.$axios.get("/admin/categories?all=1").then((res) => {
        let ctj = JSON.stringify(res.data.data.categories);
        let newData = ctj.replaceAll("title", "label");
        this.categoryList = JSON.parse(newData);
      });
    },
    handlerDiscount(item) {
      this.selectedDiscountItem = null;
      this.inLoadId = null;
      this.selectedProducts = [];
      this.selectedCategories = [];
      this.model_ids = [];
      this.balance = 0;
      this.balance_type = { id: "more", label: "بیشتر از" };
      this.range_from = 0;
      this.range_to = 0;
      this.discountTitle = null;
      this.selectedType = { id: "range", label: "بازه قیمت" };
      this.inLoadId = item.id;
      this.inLoadId = null;
      this.selectedDiscountItem = item;
      this.balance = this.selectedDiscountItem.balance;
      this.balance_type = this.selectedDiscountItem.balance_type
        ? this.balance_type.find(
            (item) => item.id == this.selectedDiscountItem.balance_type
          )
        : { id: "more", label: "بیشتر از" };
      this.range_from = this.selectedDiscountItem.range_from;
      this.range_to = this.selectedDiscountItem.range_to;
      this.discountTitle = this.selectedDiscountItem.title;
      this.selectedType = this.selectedDiscountItem.type
        ? this.types.find((item) => item.id == this.selectedDiscountItem.type)
        : null;
      if (this.selectedDiscountItem.type == "product") {
        this.selectedProducts = this.selectedDiscountItem.products;
      } else if (this.selectedDiscountItem.type == "category") {
        let ids = [];
        this.selectedDiscountItem.categories.forEach((item) =>
          ids.push(item.id)
        );
        this.selectedCategories = ids;
        console.log(ids);
      }
      this.$forceUpdate();
    },
    getDiscount() {
      if (this.$route.params.edit)
        this.$axios
          .get(
            `/admin/specificDiscountItem/${this.$route.params.item}/items/${this.$route.params.id}/${this.$route.params.edit}`
          )
          .then((res) => {
            this.mainData = res.data.data.specificDiscountItem;
            this.handlerDiscount(this.mainData);
          });
    },
  },
};
