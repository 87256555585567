import Loading from '@@/core/components/shared/Loading/Loading'
import {BTooltip, BTable, VBModal} from "bootstrap-vue";

import mixins from '@@/core/mixins/mixins'

export default {
  name: "Coupon",
  components: {
    Loading,
    BTooltip, BTable
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/agents',
      fields: [
        {
          key: 'id',
          label: 'شناسه'
        },
        {
          key: 'shop_name',
          label: 'نام فروشگاه'
        },
        {
          key: 'new_orders_count',
          label: 'تعداد سفارشات'
        },
        {
          key: 'show',
          label: 'نمایش جزئیات'
        },
      ],
      mode: 'create',
      coupon: {
        title: '',
        code: '',
        start_date: '',
        end_date: '',
        type: 'percentage',
        amount: 0,
        usage_limit: 0,
        usage_per_user_limit: 0,
        min_order_amount: ''
      },
      select_type: null,
      agentsOrder:null,
    }
  },
  mounted() {
    this.loadItems().then((res)=>{
      this.agentsOrder=res.data.data.agents
    })
  },
  methods: {
    async editItem(id, index) {
      this.disabled = true
      try {
        this.coupon = this.items.data.find((item) => {
          return item.id == id;
        })
        this.mode = 'edit'
        this.submit_url = this.url + `/${id}`
        this.$root.$emit('bv::show::modal', 'coupon-modal')
        this.coupon.index = index
      } catch (error) {
        this.$root.notify(error)
      } finally {
        this.disabled = false
      }
    },
    newCoupon() {
      this.mode = 'create'
      this.submit_url = this.url
      this.coupon = {
        title: '',
        code: '',
        start_date: '',
        end_date: '',
        type: 'percentage',
        amount: 0,
        usage_limit: 0,
        usage_per_user_limit: 0,
        min_order_amount: ''
      }
    },
  }
}
