<template>
  <main class="mt-5">
    <span id="add-btn">
      <button @click="newDiscount" class="btn btn-success">تخفیف جدید</button>
    </span>
    <FormModal @formSubmited="loadData" :editedItem="editedItem"></FormModal>
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">تخفیف ها</h5>
        <div style="flex: 1"></div>
      </div>
      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="list" :fields="fields">
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(start_date)="data">
              {{ data.item.start_date | persianDate }}
            </template>
            <template v-slot:cell(end_date)="data">
              {{ data.item.end_date | persianDate }}
            </template>

            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <a class="action-btns1 pt-1 px-2" @click="editItem(data.item)">
                  <i class="feather feather-edit text-primary"></i>
                </a>
              </span>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a
                  class="action-btns1 pt-1 px-2"
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
            </template>
            <template v-slot:cell(add)="data">
              <span :id="'add-btn-' + data.item.id">
                <router-link
                  class="action-btns1 pt-1 px-2"
                  :to="`/admin/discount-item/${data.item.id}`"
                >
                  <i class="feather feather-plus text-primary"></i>
                </router-link>
              </span>
            </template>
          </b-table>
          <Loading v-if="!list" />
          <!-- <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >admin/specificDiscount/11
          </pagination>
          <div v-else class="alert alert-danger">موردی یافت نشد</div> -->
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import discountListImpl from "@@/core/pages/discount/discountList/discountListImpl.js";
export default {
  extends: discountListImpl,
};
</script>
