<template>
  <div>
    <treeselect v-if="realOptions && realOptions.length"
        :flat="true"
        :searchable="true"
        :multiple="true"
        :options="realOptions"
        noChildrenText="فرزندی وجود ندارد" noOptionsText="موردی وجود ندارد" noResultsText="موردی یافت نشد"
        placeholder="انتخاب دسته بندی ها"
        v-model="realValue"
    />
    <div v-else>در حال بارگزاری...</div>
  </div>
</template>

<script>
import {Treeselect} from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "CategorySelect",
  components: {
    Treeselect
  },
  props: {
    value: Array,
  },
  data() {
    return {
      realValue: this.value,
      items: []
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    async loadItems() {
      try {
        const response = await this.$axios.get('admin/categories?all=1')
        this.items = response.data.data.categories
      } catch (e) {

      }
    }
  },
  computed: {
    realOptions() {
      return this.$root.addLabel(this.items)
    }
  },
  watch: {
    realValue() {
      this.$emit('input', this.realValue)
    },
    value() {
      this.realValue = this.value
    }
  }
}
</script>

<style scoped>

</style>
