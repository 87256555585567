<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold py-3">لیست فروش</h5>
        <div style="flex: 1"></div>
        <div class="d-flex" style="gap: 0.5rem">
          <Refresh />
          <Back url="/admin/shop" />
        </div>
      </div>
      <div class="card-body">
        <form @submit.prevent="filterItem()">
          <div class="row mt-5">
            <b-form-group class="col-md-1" label="شناسه :">
              <b-form-input
                :disabled="disabled"
                placeholder="شناسه"
                v-model="filter.miniOrderId"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group class="col-md-2" label="موبایل :">
              <b-form-input
                :disabled="disabled"
                placeholder="شناسه"
                v-model="filter.mobile"
              >
              </b-form-input>
            </b-form-group>

            <b-form-group label="صندوقدار :" class="col-md-2">
              <multiselect
                placeholder="صندوقدار"
                v-model="filter.cashier_ids"
                open-direction="bottom"
                :options="cashiers"
                label="name"
                track-by="id"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                :disabled="disabled"
              >
                <template slot="noOptions"> موردی یافت نشد </template>
                <template slot="noResult"> موردی یافت نشد </template>
              </multiselect>
            </b-form-group>

            <b-form-group label="از تاریخ :" class="col-md-2">
              <date-picker
                type="datetime"
                v-model="filter.start_date"
                format="YYYY-MM-DD HH:mm"
                display-format="jYYYY-jMM-jDD HH:mm"
              />
            </b-form-group>
            <b-form-group label="تا تاریخ:" class="col-md-2">
              <date-picker
                type="datetime"
                v-model="filter.end_date"
                format="YYYY-MM-DD HH:mm"
                display-format="jYYYY-jMM-jDD HH:mm"
              />
            </b-form-group>
            <div class="text-center mt-6 col-md-3">
              <button
                :disabled="disabled"
                type="submit"
                class="btn btn-primary px-5"
              >
                جستجو
              </button>
              <button
                :disabled="disabled"
                @click="resetFilter"
                class="btn btn-warning px-5 mr-3"
              >
                برداشتن فیلتر
              </button>
            </div>
          </div>
        </form>
      </div>

      <div class="d-flex justify-content-center">
        <b-container v-if="totals">
          <b-row>
            <b-col class="d-flex align-items-center gap-8">
              <strong>تعداد فروش حضوری :</strong>
              <span class="large-text">{{
                totals.miniOrders_count
              }}</span></b-col
            >
            <b-col class="d-flex align-items-center gap-8"
              ><strong>تعداد آیتم ها :</strong>
              <span class="large-text">{{ totals.total_quantity }}</span></b-col
            >
            <b-col class="d-flex align-items-center gap-8"
              ><strong>جمع مبلغ کارت به کارت :</strong>
              <span class="large-text"
                >{{ totals.total_cardByCard_amount | price }}تومان</span
              ></b-col
            >
          </b-row>
          <b-row>
            <b-col class="d-flex align-items-center gap-8"
              ><strong>جمع مبلغ دریافت نقدی :</strong>
              <span class="large-text"
                >{{ totals.total_cash_amount | price }}تومان</span
              ></b-col
            >
            <b-col class="d-flex align-items-center gap-8"
              ><strong>جمع تخفیف داده شده صندوقدار :</strong>
              <span class="large-text"
                >{{ totals.total_cashier_discount | price }}تومان</span
              ></b-col
            >
            <b-col class="d-flex align-items-center gap-8"
              ><strong>جمع مبلغ کارتخوان :</strong>
              <span class="large-text"
                >{{ totals.total_pos_amount | price }}تومان</span
              ></b-col
            >
          </b-row>
          <b-row>
            <b-col class="d-flex align-items-center gap-8"
              ><strong>تعداد کل مرجوعی :</strong>
              <span class="large-text">{{
                totals.total_refund_quantity | price
              }}</span></b-col
            >
            <b-col class="d-flex align-items-center gap-8"
              ><strong>جمع مبلغ مرجوعی :</strong>
              <span class="large-text"
                >{{ totals.total_refund_amount | price }}تومان</span
              ></b-col
            >
            <b-col class="d-flex align-items-center gap-8"
              ><strong>موجودی صندوق :</strong>
              <span class="large-text"
                >{{ totals.total_cashier_balance | price }}تومان</span
              ></b-col
            >
          </b-row>
          <b-row>
            <b-col class="d-flex align-items-center gap-8"
              ><strong class="text-success"> فروش کل :</strong>
              <span class="large-text text-success font-bold"
                >{{ totals.total_amount | price }}تومان</span
              ></b-col
            >
            <b-col class="d-flex align-items-center gap-8">
              <router-link target="_blank" id="seeMore" to="/admin/shop/report">
                <span> نمایش ریز جزئیات </span>
              </router-link>
            </b-col>
            <b-col class="d-flex align-items-center gap-8"> </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="card-body">
        <div>
          <b-table
            responsive
            hover
            bordered
            :items="items.data"
            :fields="fields"
          >
            <template v-slot:cell(user)="data">
              {{
                data.item.user && data.item.user.first_name
                  ? data.item.user.first_name +
                    " " +
                    data.item.user.last_name +
                    " " +
                    data.item.user.mobile
                  : data.item.user.mobile
              }}
            </template>
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(pos)="data">
              <span
                v-if="
                  data.item.cardByCard_amount == 0 && data.item.cash_amount == 0
                "
              >
                <svg
                  height="24"
                  width="24"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 507.2 507.2"
                  xml:space="preserve"
                >
                  <circle
                    style="fill: #32ba7c"
                    cx="253.6"
                    cy="253.6"
                    r="253.6"
                  />
                  <path
                    style="fill: #0aa06e"
                    d="M188.8,368l130.4,130.4c108-28.8,188-127.2,188-244.8c0-2.4,0-4.8,0-7.2L404.8,152L188.8,368z"
                  />
                  <g>
                    <path
                      style="fill: #ffffff"
                      d="M260,310.4c11.2,11.2,11.2,30.4,0,41.6l-23.2,23.2c-11.2,11.2-30.4,11.2-41.6,0L93.6,272.8
		c-11.2-11.2-11.2-30.4,0-41.6l23.2-23.2c11.2-11.2,30.4-11.2,41.6,0L260,310.4z"
                    />
                    <path
                      style="fill: #ffffff"
                      d="M348.8,133.6c11.2-11.2,30.4-11.2,41.6,0l23.2,23.2c11.2,11.2,11.2,30.4,0,41.6l-176,175.2
		c-11.2,11.2-30.4,11.2-41.6,0l-23.2-23.2c-11.2-11.2-11.2-30.4,0-41.6L348.8,133.6z"
                    />
                  </g>
                </svg>
              </span>
            </template>
            <template v-slot:cell(more)="data">
              <div class="d-flex align-items-center justify-content-center">
                <router-link
                  :to="'/admin/shop/orders/' + data.item.id"
                  class="action-btns1 pt-1 px-1"
                >
                  <i
                    style="vertical-align: text-top"
                    class="fe fe-eye text-primary"
                  ></i>
                </router-link>
                <button
                  :style="
                    !hasPermission('delete_mini_order')
                      ? 'background: gray'
                      : 'background: transparent'
                  "
                  :disabled="!hasPermission('delete_mini_order') ? true : false"
                  class="action-btns1 pt-1 px-1"
                  @click="deleteItem(data.item.id)"
                >
                  <i
                    style="vertical-align: text-top"
                    class="feather feather-trash-2 text-danger"
                  ></i>
                </button>
              </div>
            </template>
            <template v-slot:cell(type)="data">
              <span
                v-if="data.item.type == 'increment'"
                class="badge badge-success status"
                >افزایش</span
              >
              <span v-else class="badge badge-danger status">کاهش</span>
            </template>
            <template v-slot:cell(fastShow)="data">
              <button
                @click="showItem(data.item)"
                class="action-btns1 pt-1 px-1"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 16 16"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  class="si-glyph si-glyph-slide-show"
                  fill="#000000"
                  stroke="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0" />

                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />

                  <g id="SVGRepo_iconCarrier">
                    <title>Slide-show</title>
                    <defs></defs>
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g fill="#0094f0">
                        <rect
                          x="7"
                          y="0"
                          width="1.906"
                          height="2.219"
                          class="si-glyph-fill"
                        ></rect>
                        <path
                          d="M15.993,2.621 C15.993,2.287 15.674,2.016 15.281,2.016 L0.781,2.016 C0.387,2.016 0.069,2.287 0.069,2.621 L0.069,3.937 L1.031,3.937 L1.031,11.986 L5.465,11.986 L3.17,14.724 C3.009,14.886 3.069,15.209 3.306,15.446 L3.736,15.875 L7.063,12.105 L7.063,15.968 L8.914,15.968 L8.914,12.002 L12.333,15.874 L12.762,15.445 C12.999,15.209 13.06,14.885 12.898,14.723 L10.533,11.985 L14.939,11.985 L14.939,3.936 L15.995,3.936 L15.995,2.621 L15.993,2.621 Z M14.031,11.106 L1.959,11.106 L1.959,3.937 L14.031,3.937 L14.031,11.106 L14.031,11.106 Z"
                          class="si-glyph-fill"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            </template>

            <template v-slot:cell(total)="data">
              <span v-if="data.item.total >= 0" class="text-success">
                {{ data.item.total | price }}
              </span>
              <span v-else style="direction: ltr" dir="ltr" class="text-danger"
                >-{{ data.item.total | price }}</span
              >
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">موردی یافت نشد</div>
        </div>
      </div>
      <b-modal
        id="miniOrder-modal"
        hide-footer
        :title="`اطلاعات سفارش ${selectedItem ? selectedItem.id : null}`"
      >
        <div id="miniOrder-modalContainer" v-if="selectedItem">
          <div>
            <label class="d-flex gap-8" for="">
              <svg
                fill="#00bfff"
                width="24"
                height="24"
                viewBox="0 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0" />

                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />

                <g id="SVGRepo_iconCarrier">
                  <title>time</title>
                  <path
                    d="M0 16q0-3.232 1.28-6.208t3.392-5.12 5.12-3.392 6.208-1.28q3.264 0 6.24 1.28t5.088 3.392 3.392 5.12 1.28 6.208q0 3.264-1.28 6.208t-3.392 5.12-5.12 3.424-6.208 1.248-6.208-1.248-5.12-3.424-3.392-5.12-1.28-6.208zM4 16q0 3.264 1.6 6.048t4.384 4.352 6.016 1.6 6.016-1.6 4.384-4.352 1.6-6.048-1.6-6.016-4.384-4.352-6.016-1.632-6.016 1.632-4.384 4.352-1.6 6.016zM14.016 16v-5.984q0-0.832 0.576-1.408t1.408-0.608 1.408 0.608 0.608 1.408v4h4q0.8 0 1.408 0.576t0.576 1.408-0.576 1.44-1.408 0.576h-6.016q-0.832 0-1.408-0.576t-0.576-1.44z"
                  />
                </g>
              </svg>
              <span>زمان ثبت سفارش</span>
            </label>
            <b-form-input
              :value="
                new Date(selectedItem.created_at).toLocaleDateString('fa')
              "
              type="text"
              readonly
            >
            </b-form-input>
          </div>
          <div>
            <label>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0" />

                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />

                <g id="SVGRepo_iconCarrier">
                  <rect
                    x="6.5"
                    y="4.5"
                    width="11"
                    height="15"
                    rx="1.5"
                    stroke="#00bfff"
                  />
                  <rect
                    x="8.5"
                    y="6.5"
                    width="7"
                    height="5"
                    stroke="#00bfff"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 17.5H9.5"
                    stroke="#00bfff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.5 17.5H15.5"
                    stroke="#00bfff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.5 17.5H12.5"
                    stroke="#00bfff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 15.5H9.5"
                    stroke="#00bfff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.5 15.5H15.5"
                    stroke="#00bfff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.5 15.5H12.5"
                    stroke="#00bfff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 13.5H9.5"
                    stroke="#00bfff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.5 13.5H15.5"
                    stroke="#00bfff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.5 13.5H12.5"
                    stroke="#00bfff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
              <span>پرداخت از پوز </span>
            </label>

            <b-form-input
              :value="
                (selectedItem.total -
                  (selectedItem.cardByCard_amount + selectedItem.cash_amount))
                  | price
              "
              type="text"
              readonly
            >
            </b-form-input>
          </div>
          <div>
            <label class="d-flex gap-8" for="">
              <svg
                width="24"
                height="24"
                viewBox="0 -196 1416 1416"
                class="icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0" />

                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />

                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M324.358919 22.140541H1361.643243c18.819459 0 33.210811 14.391351 33.210811 33.21081v645.396757c0 18.819459-14.391351 33.210811-33.210811 33.210811H324.358919c-18.819459 0-33.210811-14.391351-33.210811-33.210811V55.351351c0-18.819459 14.391351-33.210811 33.210811-33.21081z"
                    fill="#9DBE87"
                  />

                  <path
                    d="M1361.643243 756.099459H324.358919c-30.996757 0-55.351351-24.354595-55.351351-55.351351V55.351351c0-30.996757 24.354595-55.351351 55.351351-55.351351H1361.643243c30.996757 0 55.351351 24.354595 55.351352 55.351351v645.396757c0 29.88973-24.354595 55.351351-55.351352 55.351351zM324.358919 44.281081c-6.642162 0-11.07027 4.428108-11.07027 11.07027v645.396757c0 6.642162 4.428108 11.07027 11.07027 11.07027H1361.643243c6.642162 0 11.07027-4.428108 11.070271-11.07027V55.351351c0-6.642162-4.428108-11.07027-11.070271-11.07027H324.358919z"
                    fill="#131313"
                  />

                  <path
                    d="M230.261622 116.237838h1038.391351c18.819459 0 33.210811 14.391351 33.210811 33.210811v645.396756c0 18.819459-14.391351 33.210811-33.210811 33.210811H230.261622c-18.819459 0-33.210811-14.391351-33.210811-33.210811V149.448649c0-18.819459 14.391351-33.210811 33.210811-33.210811z"
                    fill="#9DBE87"
                  />

                  <path
                    d="M1267.545946 850.196757H230.261622c-30.996757 0-55.351351-24.354595-55.351352-55.351352V149.448649c0-30.996757 24.354595-55.351351 55.351352-55.351352h1038.391351c30.996757 0 55.351351 24.354595 55.351351 55.351352v645.396756c-1.107027 29.88973-25.461622 55.351351-56.458378 55.351352zM230.261622 138.378378c-6.642162 0-11.07027 4.428108-11.070271 11.070271v645.396756c0 6.642162 4.428108 11.07027 11.070271 11.070271h1038.391351c6.642162 0 11.07027-4.428108 11.07027-11.070271V149.448649c0-6.642162-4.428108-11.07027-11.07027-11.070271H230.261622z"
                    fill="#131313"
                  />

                  <path
                    d="M143.913514 208.121081h1038.391351c18.819459 0 33.210811 14.391351 33.210811 33.210811v645.396757c0 18.819459-14.391351 33.210811-33.210811 33.21081H143.913514c-18.819459 0-33.210811-14.391351-33.210811-33.21081V241.331892c0-17.712432 14.391351-33.210811 33.210811-33.210811z"
                    fill="#9DBE87"
                  />

                  <path
                    d="M1182.304865 942.08H143.913514c-30.996757 0-55.351351-24.354595-55.351352-55.351351V241.331892c0-30.996757 24.354595-55.351351 55.351352-55.351351h1038.391351c30.996757 0 55.351351 24.354595 55.351351 55.351351v645.396757c0 29.88973-25.461622 55.351351-55.351351 55.351351zM143.913514 230.261622c-6.642162 0-11.07027 4.428108-11.070271 11.07027v645.396757c0 6.642162 4.428108 11.07027 11.070271 11.07027h1038.391351c6.642162 0 11.07027-4.428108 11.07027-11.07027V241.331892c0-6.642162-4.428108-11.07027-11.07027-11.07027H143.913514z"
                    fill="#131313"
                  />

                  <path
                    d="M55.351351 290.041081h1038.391352c18.819459 0 33.210811 14.391351 33.210811 33.210811v645.396757c0 18.819459-14.391351 33.210811-33.210811 33.21081H55.351351c-18.819459 0-33.210811-14.391351-33.21081-33.21081V323.251892c0-17.712432 14.391351-33.210811 33.21081-33.210811z"
                    fill="#9DBE87"
                  />

                  <path
                    d="M1093.742703 1024H55.351351c-30.996757 0-55.351351-24.354595-55.351351-55.351351V323.251892c0-30.996757 24.354595-55.351351 55.351351-55.351351h1038.391352c30.996757 0 55.351351 24.354595 55.351351 55.351351v645.396757c0 30.996757-25.461622 55.351351-55.351351 55.351351zM55.351351 312.181622c-6.642162 0-11.07027 4.428108-11.07027 11.07027v645.396757c0 6.642162 4.428108 11.07027 11.07027 11.07027h1038.391352c6.642162 0 11.07027-4.428108 11.07027-11.07027V323.251892c0-6.642162-4.428108-11.07027-11.07027-11.07027H55.351351z"
                    fill="#131313"
                  />

                  <path
                    d="M954.257297 902.227027H194.836757c0-52.03027-43.174054-95.204324-95.204325-95.204324V472.700541c52.03027 0 95.204324-43.174054 95.204325-95.204325h759.42054c0 52.03027 43.174054 95.204324 95.204325 95.204325v334.322162c-53.137297 0-95.204324 43.174054-95.204325 95.204324z"
                    fill="#D6F0C5"
                  />

                  <path
                    d="M954.257297 924.367568H194.836757c-12.177297 0-22.140541-9.963243-22.140541-22.140541 0-39.852973-33.210811-73.063784-73.063784-73.063784-12.177297 0-22.140541-9.963243-22.14054-22.14054V472.700541c0-12.177297 9.963243-22.140541 22.14054-22.140541 39.852973 0 73.063784-33.210811 73.063784-73.063784 0-12.177297 9.963243-22.140541 22.140541-22.14054h759.42054c12.177297 0 22.140541 9.963243 22.140541 22.14054 0 39.852973 33.210811 73.063784 73.063784 73.063784 12.177297 0 22.140541 9.963243 22.14054 22.140541v334.322162c0 12.177297-9.963243 22.140541-22.14054 22.14054-39.852973 0-73.063784 33.210811-73.063784 73.063784 0 12.177297-9.963243 22.140541-22.140541 22.140541z m-739.494054-44.281082h718.460541c8.856216-46.495135 46.495135-84.134054 92.99027-92.99027V492.627027c-46.495135-8.856216-84.134054-46.495135-92.99027-92.99027H214.763243c-8.856216 46.495135-46.495135 84.134054-92.99027 92.99027V785.989189c46.495135 9.963243 84.134054 47.602162 92.99027 94.097297z"
                    fill="#131313"
                  />

                  <path
                    d="M576.761081 790.417297c-35.424865 0-73.063784-13.284324-99.632432-47.602162-7.749189-9.963243-5.535135-23.247568 3.321081-30.996757 9.963243-7.749189 23.247568-5.535135 30.996756 3.321081 26.568649 34.317838 67.528649 35.424865 94.097298 26.568649 22.140541-7.749189 35.424865-22.140541 35.424865-37.638919 0-14.391351-34.317838-24.354595-64.207568-33.210811-46.495135-14.391351-105.167568-30.996757-105.167567-86.348108 0-26.568649 16.605405-50.923243 45.388108-65.314594 35.424865-17.712432 95.204324-24.354595 151.662702 16.605405 9.963243 7.749189 12.177297 21.033514 4.428108 30.996757-7.749189 9.963243-21.033514 12.177297-30.996756 4.428108-37.638919-27.675676-79.705946-26.568649-105.167568-13.284324-13.284324 6.642162-22.140541 16.605405-22.14054 26.568648 0 21.033514 30.996757 30.996757 73.063783 44.281081 45.388108 13.284324 95.204324 28.782703 95.204325 75.277838 0 34.317838-25.461622 65.314595-65.314595 79.705946-11.07027 3.321081-26.568649 6.642162-40.96 6.642162z"
                    fill="#131313"
                  />

                  <path
                    d="M574.547027 549.085405c-12.177297 0-22.140541-9.963243-22.140541-22.14054v-48.709189c0-12.177297 9.963243-22.140541 22.140541-22.140541s22.140541 9.963243 22.140541 22.140541v48.709189c0 13.284324-9.963243 22.140541-22.140541 22.14054z"
                    fill="#131313"
                  />

                  <path
                    d="M574.547027 832.484324c-12.177297 0-22.140541-9.963243-22.140541-22.14054v-36.531892c0-12.177297 9.963243-22.140541 22.140541-22.140541s22.140541 9.963243 22.140541 22.140541v36.531892c0 12.177297-9.963243 22.140541-22.140541 22.14054z"
                    fill="#131313"
                  />

                  <path
                    d="M285.612973 653.145946m-40.96 0a40.96 40.96 0 1 0 81.92 0 40.96 40.96 0 1 0-81.92 0Z"
                    fill="#AECD99"
                  />

                  <path
                    d="M285.612973 715.139459c-34.317838 0-63.100541-27.675676-63.100541-63.10054s27.675676-63.100541 63.100541-63.100541c34.317838 0 63.100541 27.675676 63.100541 63.100541s-28.782703 63.100541-63.100541 63.10054z m0-80.812973c-9.963243 0-18.819459 7.749189-18.819459 18.81946s7.749189 18.819459 18.819459 18.819459c9.963243 0 18.819459-7.749189 18.819459-18.819459s-8.856216-18.819459-18.819459-18.81946z"
                    fill="#131313"
                  />

                  <path
                    d="M865.695135 653.145946m-40.96 0a40.96 40.96 0 1 0 81.92 0 40.96 40.96 0 1 0-81.92 0Z"
                    fill="#AECD99"
                  />

                  <path
                    d="M865.695135 715.139459c-34.317838 0-63.100541-27.675676-63.10054-63.10054s27.675676-63.100541 63.10054-63.100541c34.317838 0 63.100541 27.675676 63.100541 63.100541s-28.782703 63.100541-63.100541 63.10054z m0-80.812973c-9.963243 0-18.819459 7.749189-18.819459 18.81946s7.749189 18.819459 18.819459 18.819459 18.819459-7.749189 18.81946-18.819459-8.856216-18.819459-18.81946-18.81946z"
                    fill="#131313"
                  />
                </g>
              </svg>
              <span>پرداخت نقدی</span>
            </label>

            <b-form-input
              :value="selectedItem.cash_amount | price"
              type="text"
              readonly
            >
            </b-form-input>
          </div>
          <div>
            <label class="d-flex gap-8" for="">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0" />

                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />

                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M17 15V17.8C17 18.9201 17 19.4802 16.782 19.908C16.5903 20.2843 16.2843 20.5903 15.908 20.782C15.4802 21 14.9201 21 13.8 21H8.2C7.0799 21 6.51984 21 6.09202 20.782C5.71569 20.5903 5.40973 20.2843 5.21799 19.908C5 19.4802 5 18.9201 5 17.8V5.57143C5 5.04025 5 4.77465 5.05014 4.55496C5.2211 3.80597 5.80597 3.2211 6.55496 3.05014C6.77465 3 7.04025 3 7.57143 3H11M10 18H12M19 4.50003C18.5 4.37601 17.6851 4.37145 17 4.37601M17 4.37601C16.7709 4.37754 16.9094 4.3678 16.6 4.37601C15.7926 4.4012 15.0016 4.73678 15 5.68753C14.9982 6.70037 16 7.00003 17 7.00003C18 7.00003 19 7.23123 19 8.31253C19 9.12512 18.1925 9.48118 17.1861 9.59908C16.3861 9.59908 16 9.62503 15 9.50003M17 4.37601L17 3M17 9.5995V11"
                    stroke="#00bfff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
              <span>کارت به کارت</span>
            </label>

            <b-form-input
              :value="selectedItem.cardByCard_amount | price"
              type="text"
              readonly
            >
            </b-form-input>
          </div>

          <div>
            <label class="d-flex gap-8" for="">
              <svg
                width="24"
                height="24"
                viewBox="0 0 1024 1024"
                class="icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0" />

                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />

                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M200.99 814.55c-23.52-39.54-24.45-87.29-2.48-127.71l214.17-394.27h127.24l119.46 218.25 64.14-35.11-100.24-183.14h35.02v-73.14h-33.39l69.95-146.29H256l76.33 146.29h-39.76v73.14h36.84l-195.2 359.34c-34.39 63.34-32.93 138.11 3.91 200.04s101.86 98.91 173.91 98.91h89.3v-73.14h-89.3c-46-0.01-87.52-23.61-111.04-63.17z m175.68-668.26h202.14l-34.98 73.14H414.84l-38.17-73.14z"
                    fill="#00bfff"
                  />

                  <path
                    d="M914.29 731.43V548.57H475.44v109.71H402.3v182.86h73.14v109.71H914.29V768h-73.14v-36.57h73.14z m-73.14-109.72v36.57h-36.57v-36.57h36.57z m-292.57 0h182.86v36.57H548.58v-36.57z m-73.14 109.72H658.3V768H475.44v-36.57z m73.14 146.28v-36.57h182.85v36.57H548.58z m292.57 0h-36.57v-36.57h36.57v36.57zM731.44 768v-36.57h36.57V768h-36.57z"
                    fill="#00bfff"
                  />
                </g>
              </svg>
              <span>جمع سفارش</span>
            </label>

            <b-form-input
            :value="selectedItem.total | price"
            
            type="text" readonly> </b-form-input>
          </div>
        </div>
      </b-modal>
    </div>
  </main>
</template>

<script>
import {
  BTable,
  BButton,
  BCollapse,
  BFormGroup,
  BFormInput,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BContainer,
  BRow,
  BCol,
  BModal,
} from "bootstrap-vue";
import mixins from "@@/core/mixins/mixins";
import Loading from "@@/core/components/shared/Loading/Loading";
import { priceFilter } from "@@/core/libraries/filters";
import Back from "@@/core/components/shared/Back/Back";
import Refresh from "@@/core/components/shared/Refresh/Refresh";

//
export default {
  name: "ShopIndex",
  mixins: [mixins],
  directives: {
    "b-toggle": VBToggle,
  },
  components: {
    BModal,
    Refresh,
    BTable,
    Loading,
    Back,
    BButton,
    BCollapse,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BContainer,
    BRow,
    BCol,
  },
  data() {
    return {
      disabled: false,
      url: "admin/mini_orders",

      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "user",
          label: "مشتری",
        },
        {
          key: "mini_order_items_count",
          label: "تعداد آیتم ها",
        },
        {
          key: "total",
          label: "جمع کل",
        },
        {
          key: "pos",
          label: "پرداخت کامل از پوز",
        },
        {
          key: "fastShow",
          label: "نمایش سریع",
        },
        {
          key: "more",
          label: "عملیات",
          formatter: priceFilter,
        },
        {
          key: "created_at",
          label: "تاریخ ایجاد",
        },
      ],
      cashiers: [],
      totals: null,
      filter: {
        miniOrderId: "",
        end_date: "",
        mobile:"",
        start_date: "",
        cashier_ids: "",
      },
      selectedItem: null,
    };
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    showItem(item) {
      this.selectedItem = item;
      this.$root.$emit("bv::show::modal", "miniOrder-modal");
      // this.$root.$emit("bv::show::modal", "customer-modal");
    },
    resetFilter() {
      this.filter = {
        miniOrderId: "",
        mobile:"",
        end_date: "",
        start_date: "",
        cashier_ids: "",
      };
    },
    loadItems() {
      this.$axios
        .get("admin/mini_orders", {
          params: {
            miniOrderId: this.filter.miniOrderId,
            start_date: this.filter.start_date,
            mobile:this.filter.mobile,
            end_date: this.filter.end_date,
            cashier_ids: this.filter.cashier_ids
              ? this.filter.cashier_ids.id
              : null,
          },
        })
        .then((res) => {
          this.items = res.data.data.mini_orders;
          this.cashiers = res.data.data.cashiers;
          this.totals = res.data.data.totals;
        });
    },
    showDescription(item) {
      window.swal({
        icon: "info",
        showConfirmButton: true,
        text: item.description,
        button: "بستن",
      });
    },
    filterItem() {
      this.loadItems();
    },
  },
};
</script>

<style scoped>
.order-product-img {
  max-width: 50px;
  margin: auto;
}

.table-title {
  font-weight: bold;
}
</style>
<style>
.card.little.info {
  background: linear-gradient(30deg, #d2e8ff 0%, #d5e6fc 35%, #eaf5ff 100%);
}

.card.little.online {
  background: linear-gradient(30deg, #c8ffc8 0%, #c8ffc8 35%, #e0ffe0 100%);
}

.card.little.physical {
  background: linear-gradient(30deg, #ffffe3 0%, #ffffbc 35%, #ffffc5 100%);
}

.card.little .card-header {
  font-weight: bold;
}
.large-text {
  font-size: large;
}
.gap-8 {
  gap: 8px;
}
#seeMore {
  border-bottom: 1px solid;
  padding: 5px;
}
#miniOrder-modalContainer label{
  margin-top:16px ;
  margin-bottom: 4px;
}
</style>
