<template>
  <div class="card mt-3">
    <div class="card-header border-0">
      <h5 class="card-title">قیمت گذاری</h5>
    </div>
    <div class="card-body">
      <InputGroup label-padding="0px" col1="3" col2="9" label="قیمت خرید">
        <b-form-input
          :value="realValue.purchasePrice | priceinput"
          @input="
            (value) => {
              realValue.purchasePrice =
                value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
          type="text"
          placeholder="قیمت خرید"
        />
      </InputGroup>
      <InputGroup
        label-padding="0px"
        col1="3"
        col2="9"
        label="قیمت  همکار"
        required
      >
        <b-form-input
          :value="realValue.unitPrice | priceinput"
          @input="
            (value) => {
              realValue.unitPrice =
                value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
          placeholder="قیمت  همکار"
          type="text"
        />
      </InputGroup>
      <InputGroup label-padding="0px" col1="3" col2="9" label="قیمت فروش ">
        <b-form-input
          :value="realValue.sellPrice | priceinput"
          @input="
            (value) => {
              realValue.sellPrice =
                value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
          type="text"
          placeholder="قیمت فروش "
        />
      </InputGroup>
      <InputGroup
        label-padding="0px"
        col1="3"
        col2="9"
        label="قیمت نماینده ویژه"
        required
      >
        <b-form-input
          :value="realValue.unit_price_vip | priceinput"
          @input="
            (value) => {
              realValue.unit_price_vip =
                value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
          placeholder="قیمت نماینده ویژه"
          type="text"
        />
      </InputGroup>
      <InputGroup
        label-padding="0px"
        col1="3"
        col2="9"
        label="قیمت نمایندگی آنلاین "
      >
        <b-form-textarea
          :value="realValue.online_agents_price"
          @input="
            (value) => {
              realValue.online_agents_price = value;
            }
          "
          type="text"
          placeholder="قیمت فروش "
        >
        </b-form-textarea>
      </InputGroup>
      <!-- <InputGroup label-padding="0px" col1="3" col2="9" label="قیمت سهم سجاد کمرون">
        <b-form-input :value="realValue.sharePrice | priceinput" @input="value=>{realValue.sharePrice = value !== '' ? +value.replace(/\D/g, '') : ''}"
                      type="text" placeholder="قیمت سهم سجاد کمرون"/>
      </InputGroup> -->

      <InputGroup label-padding="0px" col1="3" col2="9" label="تخفیف">
        <Discount v-model="realValue" />
      </InputGroup>
    </div>
  </div>
</template>

<script>
import ProductPricingImpl from "@@/core/components/product/small-cards/ProductPricing/ProductPricingImpl";
export default {
  extends: ProductPricingImpl,
};
</script>

<style scoped></style>
