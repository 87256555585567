import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BRow, BCol, BFormGroup } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
  },
  props: {
    selectedUser: Object,
    mode: {
      type: String,
      default: "create",
    },
    value: {
      type: Object,
      default: () => {
        return {
          province: null,
          city: null,
          first_name: "",
          last_name: "",
          postal_code: "",
          mobile: "",
          address: "",
        };
      },
    },
    adminProvinces: null,
  },
  data() {
    return {
      baseUrl: "admin/userSajad",
      url: "",
      cities: [],
      disabled: false,
      auth: "front",
      address: this.value,
    };
  },
  created() {
    if (this.$root.isPart("admin")) {
      this.auth = "admin";
    }
  },
  mounted() {
    if (this.auth === "admin") {
      // this.baseUrl = "admin/customers" + this.baseUrl;
    } else {
      if (this.$store.getters[this.auth + "/getProvincesData"] == null) {
        this.$store.dispatch(this.auth + "/getProvincesDataFromServer");
      }
      // this.baseUrl = "customer" + this.baseUrl;
    }
  },
  computed: {
    provinces() {
      if (this.auth === "admin") {
        return this.adminProvinces;
      } else {
        return this.$store.getters["front/getProvincesData"];
      }
    },
  },
  watch: {
    address(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.address = newVal;
    },
    "address.province": function (newVal) {
      if (newVal) {
        let finded = this.provinces.find((item) => item.id == newVal.id);

        console.log(finded);
        this.cities = finded.cities;
      }
    },
    mode(newVal) {
      if (newVal) {
        this.resetVeeValidate();
      }
      if (newVal == "create") {
        this.cities = null;
      }
    },
  },
  methods: {
    resetVeeValidate() {
      this.$refs.observer.reset();
    },
    setCities(items) {
      this.cities = items.cities;
    },
    async submitAddress(e) {
      this.disabled = true;

      this.url = `${this.baseUrl}/${this.selectedUser.id}/addresses${
        this.mode == "edit" ? "/" + this.address.id : ""
      }`;

      try {
        let formData = new FormData(e.target);
        formData.append("city_id", this.address.city ? this.address.city.id : "");
        formData.append("_method", this.mode == "edit" ? "put" : "post");
        const response = await this.$axios.post(this.url, formData);
        // const response = await this.$axios.post(this.url, {
        //   city: this.address.city.id,
        //   first_name: this.address.first_name,
        //   last_name: this.address.last_name,
        //   postal_code: this.address.postal_code,
        //   mobile: this.address.mobile,
        //   address: this.address.address,
        //   _method: this.mode == "edit" ? "put" : "post",
        //   customer_id: this.auth === "admin" ? this.address.customer_id : "",
        // });
        this.$emit("new-address", response.data.data.newAddress);
        this.$root.notify(response.data.message, "success");
        window.$("#userAddAddressModal").modal("hide");
        this.cities = null;
      } catch (error) {
        this.$root.notify(error);
      }

      this.disabled = false;
    },
    resetForm() {
      this.$("form#address-form input").val("");
    },
  },
};
