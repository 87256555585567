<template>
  <div class="card mt-3">
    <div class="card-header border-0">
      <h5 class="card-title">تنظیمات دیگر</h5>
    </div>
    <div class="card-body">
      <InputGroup label-padding="0px" col1="4" col2="8" label="اخطار موجودی">
        <b-form-input type="number" min="0" v-model="realValue.lowStockQuantityWarning"/>
      </InputGroup>
      <InputGroup @label-click="realValue.listenCharge = !realValue.listenCharge" label-padding="0px" col1="8" col2="4" label="ارسال نوتیفیکیشن به کاربران در انتظار">
        <b-form-checkbox v-model="realValue.listenCharge" :unchecked-value="false"/>
      </InputGroup>
      <!-- <InputGroup @label-click="realValue.is_damaged = !realValue.is_damaged" label-padding="0px" col1="8" col2="4" label="محصول زده دار است؟">
        <b-form-checkbox v-model="realValue.is_damaged" :unchecked-value="false"/>
      </InputGroup> -->
    </div>
  </div>
</template>

<script>
import ProductOtherConfigImpl from '@@/core/components/product/small-cards/ProductOtherConfig/ProductOtherConfigImpl'
export default {
  extends: ProductOtherConfigImpl
}
</script>

<style scoped>

</style>
