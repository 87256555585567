import Loading from "@@/core/components/shared/Loading/Loading";
import { BButton, BModal, BFormGroup, BFormInput, BTable } from "bootstrap-vue";
export default {
  components: {
    BModal,
    Loading,
    BButton,
    BFormGroup,
    BFormInput,
    BTable,
  },
  data() {
    return {
      unit_price_percentage: null,
      title: "",
      disabled: false,
      items: null,
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "unit_price_percentage",
          label: "درصد",
        },
        {
          key: "created_at",
          label: "ساخت",
        },
      ],
    };
  },
  methods: {
    newItem() {
      this.$root.$emit("bv::show::modal", "unitPrice-modal");
    },
    saveData() {
      this.disabled = true;
      let fd = new FormData();
      fd.append("title", this.title);
      fd.append("unit_price_percentage", this.unit_price_percentage);
      this.$axios
        .post("/admin/unit_price_calculator", fd)
        .then((res) => {
          this.disabled = false;
          this.$root.notify(res.data.message, "success");
          this.$root.$emit("bv::hide::modal", "unitPrice-modal");
          this.loadData();
        })
        .catch((errr) => {
          this.disabled = false;
          this.$root.notify(errr);
        });
    },
    async loadData() {
      await this.$axios.get("/admin/unit_price_calculator").then((res) => {
        this.items = res.data.data.unitPriceCalculators;
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
