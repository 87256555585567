<template>
    <div id="bracodes">
        <div v-for="(item, index) in numbers" :key="index">
            220,000
        </div>
    </div>
</template>
<script >
export default{
    data(){
        return{
            numbers:5
        }
    }
}
</script>
<style>

#bracodes div{
border: 1px solid rgba(0, 0, 0, 0.363);
width: 5cm;
height: 4cm;
margin-bottom: 2mm;
display: flex;
align-items: center;
justify-content: center;
}
</style>