<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">
          {{ selectedDiscountItem ? "ویرایش تخفیف ویژه" : "ثبت تخفیف ویژه" }}
        </h5>
        <div style="flex: 1"></div>
      </div>
      <div class="card-body">
        <div style="margin-top: 24px" class="col-md-12 row">
          <b-form-group class="col-md-6" label="انتخاب گروه تخفیف">
            <multiselect
              placeholder="گروه تخفیف را انتخاب کنید"
              v-model="selectedType"
              open-direction="top"
              :options="types"
              label="label"
              :searchable="true"
              :close-on-select="true"
              :allow-empty="false"
              track-by="id"
              :multiple="false"
              :show-labels="false"
            >
              <template slot="noOptions"> موردی یافت نشد </template>
              <template slot="noResult"> موردی یافت نشد </template>
            </multiselect>
          </b-form-group>

          <div id="optionSection">
            <div v-if="selectedType.id == 'category'" class="col-md-12">
              <div id="selectCategory" class="col-md-12">
                <treeselect
                  v-model="selectedCategories"
                  :multiple="true"
                  :alwaysOpen="true"
                  :openDirection="'below'"
                  :options="categoryList"
                />
              </div>
            </div>
            <div v-else-if="selectedType.id == 'balance'" class="col-md-12 row">
              <b-form-group label="میزان موجودی :" class="col-md-6">
                <b-form-input v-model="balance" type="text"></b-form-input>
              </b-form-group>
              <b-form-group label="نوع موجودی :" class="col-md-6">
                <multiselect
                  placeholder="کمتر یا بیشتر را انتخاب کنید"
                  v-model="balance_type"
                  open-direction="top"
                  :options="balance_types"
                  label="label"
                  :searchable="true"
                  :close-on-select="true"
                  :allow-empty="false"
                  track-by="id"
                  :multiple="false"
                  :show-labels="false"
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>
            </div>
            <div v-else-if="selectedType.id == 'range'" class="col-md-12 row">
              <b-form-group label=" از قیمت :" class="col-md-6">
                <b-form-input v-model="range_from" type="text"></b-form-input>
              </b-form-group>
              <b-form-group label=" تا قیمت :" class="col-md-6">
                <b-form-input v-model="range_to" type="text"></b-form-input>
              </b-form-group>
            </div>
            <div v-else-if="selectedType.id == 'product'" class="col-md-12">
              <b-form-group label="انتخاب محصول :" class="col-md-12">
                <ProductSearch
                  ref="productSearch"
                  :selectable="true"
                  class="row"
                  :show-label="false"
                  :productOnly="true"
                  @selectProduct="selectProduct"
                />
              </b-form-group>
              <div id="selectedProducts" class="col-md-12">
                <div v-for="(item, index) in selectedProducts" :key="index">
                  <button>{{ item.title }}</button>
                  <button @click="removeProduct(item.id)">X</button>
                </div>
              </div>
            </div>
          </div>

          <b-button
            :class="{ disable: disable }"
            class="col-md-2"
            variant="primary"
            type="button"
            @click="saveDiscount()"
            >ثبت تخفیف</b-button
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import discountImpl from "@@/core/pages/discount/discount/discountImpl";
export default {
  extends: discountImpl,
};
</script>
<style>
div#selectCategory {
  height: 200px;
}
div#optionSection {
  width: 100%;
  background: #f5deb370;
  padding: 24px;
  border-radius: 16px;
  margin: 24px auto;
}
.disable {
  opacity: 0.5;
  pointer-events: none;
}
div#selectedProducts button:first-child {
  background: #47ba47;
  color: white;
}

div#selectedProducts button:last-child {
  color: white;
  background-color: red;
}
div#selectedProducts button {
  border: unset;
  padding: 6px 12px;
}
div#selectedProducts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}
.oldItem h2 {
  color: green;
  font-weight: bold;
}

.oldItem h4 {
  font-weight: bolder;
  font-size: 16px;
}
.oldItem {
  padding: 8px;
  border: unset;
  border-radius: 16px;
  background: #eaeaea;
}
button.inEdit * {
  color: white;
}
button.inEdit {
  background: #255bfb;
}
button.loading {
  filter: blur(1px);
  pointer-events: none;
}
</style>
