<template>
  <main class="mt-5">
    <span id="add-btn">
      <router-link
        to="/admin/providers/create"
        :disabled="hasPermission('write_product') ? false : true"
        class="btn btn-success"
        >تامین کننده جدید</router-link
      >
    </span>
    <b-tooltip
      v-if="!hasPermission('write_product')"
      target="add-btn"
      triggers="hover"
    >
      شما به این بخش دسترسی ندارید
    </b-tooltip>
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">تامین کننده ها</h5>
        <div style="flex: 1"></div>
      </div>
      <div v-if="items" class="card-body">
        <div>
          <b-table hover bordered :items="items" :fields="fields">
            <!-- <template v-slot:cell(title)="data">
             <router-link v-if="data.item.status != 'draft'" target="_blank" :to="`/product/${data.item.id}`">
              <span v-b-tooltip.hover="{placement: 'bottom', customClass: data.item.title.length <= 25 ? 'd-none': ''}"
                :title="data.item.title">
                {{ data.item.title.substr(0, 25) }}
                {{ data.item.title.length > 25 ? '...' : '' }}
              </span>
             </router-link>
             <span v-else v-b-tooltip.hover="{placement: 'bottom', customClass: data.item.title.length <= 25 ? 'd-none': ''}"
                :title="data.item.title">
                {{ data.item.title.substr(0, 25) }}
                {{ data.item.title.length > 25 ? '...' : '' }}
              </span>
            </template> -->
            <template v-slot:cell(is_active)="data">
              <span
                class="badge"
                :class="{
                  'badge-success': data.item.is_active,
                  'badge-warning': !data.item.is_active,
                }"
              >
                {{ data.item.is_active ? "فعال" : "غیرفعال" }}
              </span>
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <router-link
                  @mouseenter.native="savePage()"
                  :to="'/admin/providers/edit/' + data.item.id"
                  :disabled="!hasPermission('modify_product') ? true : false"
                  class="action-btns1 pt-1 px-2"
                  @click="editItem(data.index)"
                >
                  <i class="feather feather-edit text-primary"></i>
                </router-link>
              </span>
              <b-tooltip
                v-if="!hasPermission('modify_product')"
                :target="'edit-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a
                  :disabled="!hasPermission('delete_product') ? true : false"
                  class="action-btns1 pt-1 px-2"
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip
                v-if="!hasPermission('delete_product')"
                :target="'delete-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="!items" />
         
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import providersImpl from "@@/core/pages/providers/providers/providersImpl";
export default {
  extends: providersImpl,
};
</script>
