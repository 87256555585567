<template>
  <b-modal
      id="show-varieties-modal"
      ok-only
      class="in-form-modal"
      ok-title="بستن"
      title="تنوع ها"
  >
    <b-spinner v-if="loading" />
    <p v-else-if="noVariety">این محصول تنوعی ندارد</p>
    <div v-else>
      <table class="table bordered  table-bordered">
        <thead>
          <th>تنوع</th>
          <th>تعدا فروش</th>
          <th>میزان فروش(تومان)</th>
        </thead>
        <tbody>
          <tr v-for="varietyReport in varietyReports" :key="varietyReport.variety.id">
            <td>
              <VarietyInfoText :variety="varietyReport.variety" />
            </td>
            <td>{{ varietyReport.quantity }}</td>
            <td>{{ varietyReport.total | price }}</td>
          </tr>
        </tbody>

      </table>

    </div>
  </b-modal>
</template>

<script>
import {BModal, BSpinner} from 'bootstrap-vue'
import VarietyInfoText from "@@/core/components/shared/VarietyInfoText/VarietyInfoText";

export default {
  name: "ShowVarietiesModal",
  components: {VarietyInfoText, BModal, BSpinner},
  props: {
    productId: Number,
    reportCode: [Number, String]
  },
  data() {
    return {
      loading: false,
      data: null,
      noVariety: false,
      varietyReports: []
    }
  },
  watch: {
    productId() {
      if (this.productId) {
        this.loadData()
      }
    }
  },
  methods: {
    async loadData() {
      try {
        this.loading = true
        const response = await this.$axios.get('admin/reports/varieties',
            {params: {product_id: this.productId, report_mini_product: this.reportCode}})
        let data = response.data.data;
        this.noVariety = data.no_variety;
        this.varietyReports = data.variety_reports;
      } catch (e) {
        this.$root.notify(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
