<template>
  <main id="print" class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">
          {{ $route.params.id ? "ویرایش انتقال" : "انتقال جدید" }}
        </h5>
        <div style="flex: 1"></div>
        <Back />
      </div>

      <b-table
        class="mt-3 admin-order"
        v-if="rows.length"
        hover
        bordered
        responsive
        :items="rows"
        :fields="fields"
      >
        <template v-slot:cell(quantityTransfer)="data">
          <b-form-input
            style="width: 80px"
            class="m-auto"
            v-model="data.item.quantityTransfer"
          >
          </b-form-input>
        </template>
        <template v-slot:cell(index)="data">
          <span>
            {{ data.index + 1 }}
          </span>
        </template>
      </b-table>
      <div class="card-body">
        <b-form @submit.prevent="sendMover" class="row">
          <b-form-group class="col-md-12" label="انتخاب محصول">
            <ProductSearch
              ref="productSearch"
              :selectable="true"
              class="row"
              :disabled="disabled"
              @selectProduct="selectProduct"
              :show-label="false"
              @selectVariety="selectVariety"
            />
          </b-form-group>
          <b-form-group class="col-md-6" label="از انبار :">
            <multiselect
              placeholder="انبار را انتخاب کنید"
              v-model="from_location_id"
              open-direction="bottom"
              :options="locations"
              label="title"
              track-by="id"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
            >
              <template slot="noOptions"> موردی یافت نشد </template>
              <template slot="noResult"> موردی یافت نشد </template>
            </multiselect>
          </b-form-group>

          <b-form-group class="col-md-6" label="به انبار :">
            <multiselect
              placeholder="انبار را انتخاب کنید"
              v-model="to_location_id"
              open-direction="bottom"
              :options="locations"
              label="title"
              track-by="id"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
            >
              <template slot="noOptions"> موردی یافت نشد </template>
              <template slot="noResult"> موردی یافت نشد </template>
            </multiselect>
          </b-form-group>
          <b-form-group class="col-md-6" label="نام انتقال دهنده ">
            <b-form-input name="mover" v-model="transfer.mover"> </b-form-input>
          </b-form-group>
          <b-form-group class="col-md-6" label="نام تحویل گیرنده ">
            <b-form-input name="receiver" v-model="transfer.receiver">
            </b-form-input>
          </b-form-group>

          <b-button type="submit" variant="primary">ثبت</b-button>
          <!-- <b-button style="margin-right: 16px;" type="button" @click="print" variant="success">چاپ</b-button> -->
        </b-form>
      </div>
    </div>
  </main>
</template>
<script>
import createImpl from "@@/core/pages/Store/storeTransfer/create/createImpl";
export default {
  extends: createImpl,
};
</script>
<style></style>
