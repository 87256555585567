<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold py-3">فروشگاه حضوری</h5>
      </div>
      <div class="card-body">
        <router-link to="/admin/shop/create" class="btn btn-success"
          >خرید جدید</router-link
        >
        <router-link to="/admin/shop/orders" class="btn btn-secondary mr-3"
          >لیست فروش</router-link
        >
        <router-link
          to="/admin/store/transaction-management"
          class="btn btn-light mr-3"
          >تراکنش های انبار
        </router-link>
        <button
        style="margin-right: 8px;"
          :disabled="hasPermission('write_store') ? false : true"
          type="button"
          tabindex="-1"
          class="btn btn-success"
          @click="incrementModal()"
        >
           افزایش موجودی انبار
        </button>
        <b-tooltip
          v-if="!hasPermission('write_store')"
          target="add-btn"
          triggers="hover"
        >
          شما به این بخش دسترسی ندارید
        </b-tooltip>
        <div style="height: 400px" class="mt-3">
          <Chart1 mode="offline" />
        </div>
        <StoreModal mode="increment" v-model="store" />
      </div>
    </div>
  </main>
</template>

<script>
import StoreModal from "@@/core/components/store/StoreForm/StoreForm";
import Chart1 from "@@/core/components/dashboard/Chart1/Chart1";
export default {
  name: "Shop",
  components: { Chart1, StoreModal },
  data() {
    return {
      store: {
        selectedProduct: null,
        selectedVariety: null,
        quantity: 0,
        varietyQuantity: 0,
        description: "",
      },
    };
  },
  methods: {
    incrementModal() {
      this.store = {
        selectedProduct: null,
        selectedVariety: null,
        quantity: 0,
        varietyQuantity: 0,
        description: "",
      };
      this.$root.$emit("bv::show::modal", "store-modal");
    },
  },
};
</script>

<style scoped></style>
