import Back from "@@/core/components/shared/Back/Back";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
} from "bootstrap-vue";
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";

export default {
  components: {
    Back,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    ProductSearch,
    BTable,
  },
  data() {
    return {
      title: "",
      url: "",
      axiosCancelSource: null,
      transfer: {
        to: "",
        from: "",
        mover: null,
        receiver: null,
        quantity: null,
        product_id: null,
        variety_id: null,
      },
      rows: [],
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "label",
          label: "عنوان",
        },
        {
          key: "quantityTransfer",
          label: "تعداد انتقالی",
        },
      ],
      to_location_id: "",
      from_location_id: "",
      categories: [],
      attributes: [],
      specifications: [],
      brands: [],
      locations: [],
    };
  },
  mounted() {
    this.getLocations();

    if (this.$route.params.id) {
      this.url = "admin/varietyTransfers/" + this.$route.params.id;
      this.getseller();
    } else {
      this.url = "admin/varietyTransfers";
    }
  },
  methods: {
    getLocations() {
      this.$axios.get("admin/varietyTransferLocations").then((res) => {
        this.locations = res.data.data.locations;
      });
    },
    print() {
      print();
    },
    selectProduct(p) {
      this.transfer.product_id = p ? p.id : null;
    },
    selectVariety(v) {
      v.quantityTransfer = 1;
      let fi = this.rows.findIndex((item) => item.id == v.id);
      if (fi == -1) this.rows.push(v);
      // this.transfer.variety_id = v ? v.id : null;
    },
    async sendMover(e) {
      e.preventDefault();
      this.disabled = true;

      try {
        // const varieties = this.varieties.map((v) => {
        //   return {
        //     id: v.id,
        //     quantity: v.quantity,
        //     amount: v.final_price.amount,
        //   };
        // });
        let formData = new FormData(e.target);
        this.rows.forEach((row, index) => {
          formData.append(`varieties[${index}][id]`, row.id);
          formData.append(
            `varieties[${index}][quantity]`,
            row.quantityTransfer
          );
        });
        formData.append("_method", this.mode == "edit" ? "put" : "post");
        formData.append(
          "from_location_id",
          this.from_location_id ? this.from_location_id.id : null
        );
        formData.append(
          "to_location_id",
          this.to_location_id ? this.to_location_id.id : null
        );

        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response, "success");
        this.$router.push("/admin/store-transfer");
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
    async getseller() {
      try {
        this.axiosCancelSource = this.$axios.CancelToken.source();
        const response = await this.$axios.get(this.url, {
          cancelToken: this.axiosCancelSource.token,
        });
        this.seller = response.data.data.seller;
        this.$refs.sellerForm.setDisabled(false);
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return;
        }
        this.$root.notify(error);
      }
    },
  },
};
