<template>
  <main  class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3">گزارش کیف پول 
          <span v-if="otherInfo">
          <span>(مجموع واریز   {{otherInfo.totalDeposit | price}} تومان -</span>
          <span>مجموع برداشت   {{otherInfo.totalWithdraw | price}} تومان)</span>
        </span>
        </h5>
        <div style="flex: 1"></div>
        <button @click="print" class="btn btn-primary"><span style="transform: translateY(3px)" class="feather-printer"></span></button>
        <ExcelDownload 
        :extraQuery="extraQuery"
        class="mr-2" url="admin/reports/wallets" />
      </div>
      <div class="row px-4 not-in-print">
        <form @submit.prevent="doFilter" class="col-12 row" style="row-gap: 15px">
          <div class="col-6">
            <DatePickerRange v-model="filter.date" />
          </div>
          <div class="col-2">
            <b-form-input type="number" v-model="filter.customer_id" placeholder="شناسه کاربر" />
          </div>
          <div class="col-12 d-flex justify-content-end">
            <button :disabled="disabled" type="submit" class="btn btn-primary">فیلتر</button>
          </div>
        </form>
      </div>
      <div id="print" class="p-4">
        <div class="d-flex mb-3 mr-2-print" v-if="appliedFilter.date.startDate || appliedFilter.date.endDate">
          <div v-if="appliedFilter.date.startDate">
            <span> از تاریخ</span><span> {{ appliedFilter.date.startDate | persianDate }} </span>
          </div>
          <div class="mr-4" v-if="appliedFilter.date.endDate">
            <span> تا تاریخ</span><span> {{ appliedFilter.date.endDate | persianDate }} </span>
          </div>
        </div>
        <div class="w-100 mt-1 mb-2 mr-2-print" v-if="appliedFilter.customer_id">جستجوی کاربر "{{ appliedFilter.customer_id }}"</div>
        <SelectPageCount :count="reports ? reports.total : false" v-model="per_page" />
        <b-table @sort-changed="sortChanged"  :style="{opacity: disabled ? '0.6' : 1}"
                  id="main-table"  v-if="reports" bordered striped :fields="fields"
                  :items="reports.data" :disabled="disabled"
                  :filter-included-fields="['title', 'id']"
                  responsive="sm">
          <template v-slot:cell(title)="data">
            <a target="_blank" :href="'/admin/customers/show/' + data.item.id">{{ data.item.title }}</a>
          </template>
        </b-table>
        <pagination
            :router="true"
            v-if="reports && reports.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="reports"
            @pagination-change-page="changePage" />
        <div v-if="!reports" class="alert alert-primary">در حال بارگزاری</div>
      </div>
    </div>
  </main>
</template>

<script>
import {BTable, BFormGroup, BFormInput} from 'bootstrap-vue'
import DatePickerRange from '@@/core/components/shared/DatePickerRange/DatePickerRange'
import SelectPageCount from "@@/core/components/shared/SelectPageCount/SelectPageCount";
import {priceFilter} from "@@/core/libraries/filters";
import sortMixin from "@@/core/libraries/mixins/sortMixin";
import ExcelDownload from "@@/core/components/excelDownload/ExcelDownload";

function Filter() {
  this.customer_id = '';
  this.date = {
    startDate: '',
    endDate: ''
  }
}

Filter.prototype.set = function(filters) {
  Object.entries(filters).forEach(([index,value]) => {
    this[index] = value
  })
}

export default {
  name: "ProductsReport",
  components: {
    SelectPageCount,
    DatePickerRange,
    BTable, BFormGroup, BFormInput,ExcelDownload
  },
  mixins: [sortMixin],
  data() {
    return {
      otherInfo:null,
      disabled: true,
      page: 1,
      previousPage: 1,
      per_page: 50,
      fields: [
        {
          key: 'id',
          label: 'شناسه کاربر',
          sortable: true
        },
        {
          key: 'title',
          label: 'کاربر',
        },
        {
          key: 'wallet_balance',
          label: 'موجودی فعلی',
          sortable: true,
          formatter: priceFilter
        },
        {
          key: 'total_deposit',
          label: 'دریافت',
          sortable: true,
          formatter: priceFilter
        },
        {
          key: 'total_withdraw',
          label: 'برداشت',
          sortable: true,
          formatter: priceFilter
        },
        {
          key: 'deposit_count',
          label: 'دفعات دریافت',
          sortable: true
        },
        {
          key: 'withdraw_count',
          label: 'دفعات برداشت',
          sortable: true,
        },

      ],
      reports: null,
      filter: new Filter(),
      appliedFilter: new Filter(),
    }
  },
  watch: {
    per_page() {
      this.changePage(1)
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    print() {
      print()
    },
    doFilter() {
      this.loadData()
    },
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData()
    },
    async loadData() {
      try {
        this.appliedFilter.set(this.filter)
        this.disabled = true
        const response = await this.$axios.get('admin/reports/wallets', {
          params: {
            customer_id: this.appliedFilter.customer_id,
            start_date: this.appliedFilter.date.startDate,
            end_date: this.appliedFilter.date.endDate,
            per_page: this.per_page,
            page: this.page,
            ...this.getSortFilters()
          }
        });
        this.reports = response.data.data.reports;
        this.otherInfo={
          totalDeposit: response.data.data.totalDeposit,
          totalWithdraw: response.data.data.totalWithdraw
        }
        this.reports.data = this.reports.data.map(v => {
          v.title = (v.first_name ? v.first_name + ' ' : '')
          v.title += (v.last_name ? v.last_name + ' - ' : '')
          v.title += v.mobile;
          return v
        })
      } catch (e) {
        this.$root.notify(e)
      } finally {
        this.disabled = false
      }
    }
  },
  computed:{
    extraQuery(){
      return {
            page: this.page,
            start_date: this.filter.date.startDate,
            end_date: this.filter.date.endDate,
          }
    }
  }
}
</script>

<style>

.print-only {
  display: none;
}
@media print {
  .not-in-print {
    display: none!important;
  }

  .app-content .side-app {
    padding: 0!important;
  }

  .print-only {
    display: block!important;
  }

  body * {
    visibility: hidden;
  }
  #print, #print * {
    visibility: visible;
    font-size: 1.25rem;
  }
  #print {
    padding: 0!important;
  }
  #print {
    width: 100%;
  }

  #print .not-in-print {
    display: none;
  }

  .mr-2-print {
    margin-right: 20px;
  }
}
</style>
