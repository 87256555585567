import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";
import FormModal from "@@/core/components/discountList/modal/modal.vue";
import {
  BButton,
  BCard,
  BCollapse,
  BFormGroup,
  BFormInput,
  BTable,
  BTooltip,
  VBToggle,
} from "bootstrap-vue";

export default {
  name: "Gift",
  components: {
    FormModal,
    Loading,
    BTooltip,
    BButton,
    BCollapse,
    BCard,
    BFormGroup,
    BFormInput,
    BTable,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  mixins: [mixins],

  data() {
    return {
      url: "admin/specificDiscount",
      list: [],
      editedItem: null,
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "type",
          label: "نوع",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      mainTitle: "",
      subTitle: "",
    };
  },
  mounted() {
    this.url = `admin/specificDiscountItem/${this.$route.params.item}/items/${this.$route.params.id}`;
    this.loadData();
    this.$axios
      .get(`admin/specificDiscount/${this.$route.params.item}`)
      .then((res) => {
        this.mainTitle = res.data.data.specificDiscount.title;
        this.subTitle = localStorage.getItem("discountName");
      });
  },
  methods: {
    editItem(item) {
      this.editedItem = item;
      this.$root.$emit("bv::show::modal", "coupen-info");
    },
    newDiscount() {
      this.editedItem = null;
      this.$root.$emit("bv::show::modal", "coupen-info");
    },
    loadData() {
      this.loadItems().then((res) => {
        this.list = res.data.data.items;
      });
    },
    deleteItem(id) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.$axios
              .delete(url + `/${id}`)
              .then((response) => {
                this.loadData();
                window.swal({
                  icon: "success",
                  showConfirmButton: true,
                  text: response.data.message,
                  button: "باشه",
                });
              })
              .catch((error) => {
                window.swal({
                  title: "خطا",
                  showConfirmButton: true,
                  text: error.response.data.message,
                  button: "باشه",
                });
              });
          }
        })
        .catch(() => {});
    },
  },
};
