<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold py-3">گزارش محصولات</h5>
        <div style="flex: 1"></div>
        <button @click="print" class="btn btn-primary">
          <span
            style="transform: translateY(3px)"
            class="feather-printer"
          ></span>
        </button>
      </div>
      <div class="row px-4 not-in-print">
        <form @submit.prevent="doFilter" class="col-12">
          <div>
            <ProductSearch
              class="row"
              @selectProduct="selectTheProduct"
              @selectVariety="selectTheVariety"
              :disabled="disabled"
            />
          </div>
          <b-form-group class="col-xl-6 col-md-12" label="نوع :">
            <multiselect
              placeholder="نوع را انتخاب کنید"
              v-model="type"
              open-direction="bottom"
              :options="types"
              label="label"
              track-by="id"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
            >
              <template slot="noOptions"> موردی یافت نشد </template>
              <template slot="noResult"> موردی یافت نشد </template>
            </multiselect>
          </b-form-group>
          <div class="col-6">
            <DatePickerRange v-model="filter.date" />
          </div>
        
          <div class="col-12 d-flex justify-content-end">
            <button :disabled="disabled" type="submit" class="btn btn-primary">
              فیلتر
            </button>
          </div>
        </form>
      </div>
      <div id="print" class="p-4">
        <div
          class="d-flex mb-3 mr-2-print print-justify-center"
          style="gap: 16px"
          v-if="appliedFilter.date.startDate || appliedFilter.date.endDate"
        >
          <strong class="show-in-print">گزارش فروش حضوری </strong>
          <div v-if="appliedFilter.date.startDate">
            <span> از تاریخ</span
            ><span> {{ appliedFilter.date.startDate | persianDate }} </span>
          </div>
          <div class="mr-4" v-if="appliedFilter.date.endDate">
            <span> تا تاریخ</span
            ><span> {{ appliedFilter.date.endDate | persianDate }} </span>
          </div>
        </div>
        <div class="w-100 mt-1 mb-2 mr-2-print" v-if="filter.search">
          جستجوی عبارت "{{ filter.search }}"
        </div>

        <b-table
          id="main-table"
          v-if="reports"
          bordered
          striped
          :fields="fields"
          :items="reports"
          responsive="sm"
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(amount)="data">
            {{ data.item.amount | price }}
          </template>
          <template v-slot:cell(diff_amount_from_real)="data">
            {{ data.item.diff_amount_from_real | price }}
          </template>
          <template v-slot:cell(discount_amount)="data">
            {{ data.item.discount_amount | price }}
          </template>
          <template v-slot:cell(sell_price)="data">
            {{ data.item.sell_price | price }}
          </template>
          <template #bottom-row="data">
            <b-td colspan="2"
              ><span>میزان فروش کل:</span>
              <strong> {{ totals.sum_amount | price }} تومان</strong></b-td
            >
            <!-- <b-td
                >میزان ماتفاوت
                {{ totals.sum_diff_amount_from_real | price }} تومان</b-td
              > -->
            <b-td colspan="2">
              <span>میزان کل تخفیف:</span>
              <strong>{{ totals.sum_discount_amount | price }} تومان</strong>
            </b-td>
            <b-td
              ><span>تعداد کل:</span>
              <strong>{{ totals.sum_quantity | price }} </strong>
            </b-td>
          </template>
        </b-table>
        <table v-if="totals" class="d-flex justify-content-between"></table>
        <ShowVarietiesModal
          :product-id="showVarietiesProductId"
          :report-code="reportCode"
        />
        <div v-if="reportsLoading" class="alert alert-primary">
          در حال بارگزاری
        </div>
      </div>
      <div class="d-flex flex-column w-100">
        <!--        <div>-->
        <!--          <div class="d-flex">-->
        <!--            <div class="p-5" style="border: 1px solid" v-for="field in fields">-->
        <!--              {{ field.label }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="d-flex w-100" v-for="item in reports">-->
        <!--            <div class="p-5" style="border: 1px solid" v-for="field in fields">-->
        <!--              {{ item[field.key] }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </main>
</template>

<script>
import { BTable, BFormGroup, BFormInput, BTr, BTd } from "bootstrap-vue";
import DatePickerRange from "@@/core/components/shared/DatePickerRange/DatePickerRange";
import ShowVarietiesModal from "@@/core/components/report/ShowVarietiesModal";
import Dropdown from "@@/core/components/shared/Dropdown/Dropdown";
import configProvider from "@@/core/configProvider";
import reportMixin from "@/core/mixins/reportMixin";
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";

function Filter() {
  this.search = "";
  this.date = {
    startDate: "",
    endDate: "",
  };
}

Filter.prototype.set = function (filters) {
  Object.entries(filters).forEach(([index, value]) => {
    this[index] = value;
  });
};

export default {
  name: "ProductsReport",
  components: {
    Dropdown,
    ShowVarietiesModal,
    DatePickerRange,
    BTable,
    BFormGroup,
    BFormInput,
    BTr,
    BTd,
    ProductSearch
  },
  configProvider,
  data() {
    return {
      reportsLoading: false,
      selectedProduct:null,
      selectedVariety:null,
      disabled: false,
      totals: null,
      index: 1,
      fields: [
        {
          key: "index",
          label: " ردیف",
          sortable: true,
        },
        {
          key: "product_title",
          label: "عنوان",
          sortable: true,
        },
        {
          key: "quantity",
          label: "تعداد",
        },
        {
          key: "sell_price",
          label: "قیمت فروش (تومان)",
          sortable: true,
        },
        {
          key: "discount_amount",
          label: "قیمت تخفیف (تومان)",
        },
        {
          key: "amount",
          label: "میزان فروش کل (تومان)",
          sortable: true,
        },
        // {
        //   key: "diff_amount_from_real",
        //   label: "ماتفاوت",
        // },
      ],
      types: [
        { id: "sell", label: "فروش" },
        { id: "refund", label: "مرجوعی" },
      ],
      type: { id: "sell", label: "فروش" },
      page: 1,
      reports: null,
      sortBy: null,
      sortDesc: null,
      filter: new Filter(),
      appliedFilter: new Filter(),
      showVarietiesProductId: null,
    };
  },
  mixins: [reportMixin],

  methods: {
    selectTheVariety(e){
      console.log(e);
      this.selectedVariety=e;
    },
    selectTheProduct(e){
      console.log(e);
      this.selectedProduct=e;
    },
    print() {
      print();
    },
    doFilter() {
      this.loadData();
    },
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData();
    },
    async loadData() {
      try {
        this.appliedFilter.set(this.filter);
        this.disabled = true;
        this.reportsLoading = true;

        const response = await this.$axios.get(
          "admin/reports/mini_order/products",
          {
            params: {
              start_date: this.appliedFilter.date.startDate,
              end_date: this.appliedFilter.date.endDate,
              type: this.type?this.type.id:null,
              variety_ids:this.selectedVariety?this.selectedVariety.id:"",
              products_ids:this.selectedProduct?this.selectedProduct.id:""
            },
          }
        );
        this.reports = response.data.data.product_lists;
        this.totals = response.data.data.totals;
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
        this.reportsLoading = false;
      }
    },
    showVarieties(productId) {
      this.$root.$emit("bv::show::modal", "show-varieties-modal");
      this.showVarietiesProductId = productId;
    },
  },
};
</script>

<style>
.print-only {
  display: none;
}
.show-in-print {
  display: none;
}
@media print {
  .show-in-print {
    display: block;
  }
  .not-in-print {
    display: none !important;
  }
  .print-justify-center {
    justify-content: center;
  }
  .app-content .side-app {
    padding: 0 !important;
  }

  .print-only {
    display: block !important;
  }

  body * {
    visibility: hidden;
  }
  #print,
  #print * {
    visibility: visible;
    font-size: 1.25rem;
  }
  #print {
    padding: 0 !important;
  }
  #print {
    width: 100%;
  }

  #print .not-in-print {
    display: none;
  }

  .mr-2-print {
    margin-right: 20px;
  }
}
</style>
