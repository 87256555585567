import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import InputGroup from "@@/core/components/product/shared/InputGroup/InputGroup";
import TreeselectAddable from "@@/core/components/product/shared/TreeselectAddable/TreeselectAddable";
import { BFormInput } from "bootstrap-vue";

export default {
  name: "ProductInformation",
  components: {
    TreeselectAddable,
    InputGroup,
    Treeselect,
    BFormInput,
  },
  props: {
    categories: Array,
    selectedCategories: Array,
    units: Array,
    tags: Array,
    value: Object,
    providers: Array,
  },
  data() {
    return {
      realValue: this.value,
    };
  },
  mounted() {},
  computed: {
    realUnits() {
      return this.$root.addLabel(this.units);
    },
    realTags() {
      return this.$root.addLabel(this.tags);
    },
    providersList() {
      return this.$root.addLabel(this.providers,'full_name');
    },
    realOptions() {
      return this.$root.addLabel(this.categories);
    },
    realBrands() {
      return this.readBrands(this.selectedCategories);
    },
  },
  watch: {
    value(newVal) {
      this.realValue = newVal;
    },
    realValue(newVal) {
      this.$emit("input", newVal);
    },
    selectedCategories: function (newVal) {
      const brands = this.readBrands(newVal);
      let found = false;
      brands.forEach((brand) => {
        if (brand.id == this.realValue.brand) {
          found = true;
        }
      });
      if (!found) {
        this.realValue.brand = null;
      }
    },
  },
  methods: {
    readBrands(categories) {
      let brands = [];
      let addedBrandIds = [];
      categories.forEach((cat) => {
        cat.brands.forEach((brand) => {
          if (addedBrandIds.includes(brand.id)) {
            return;
          }
          addedBrandIds.push(brand.id);
          brands.push({
            label: brand.name,
            name: brand.name,
            id: brand.id,
          });
        });
        let brandsFromChildren = this.readBrands(cat.children);
        brandsFromChildren.forEach((b) => {
          if (addedBrandIds.includes(b.id)) {
            return;
          }
          addedBrandIds.push(b.id);
          brands.push({
            label: b.name,
            name: b.name,
            id: b.id,
          });
        });
      });

      return brands;
    },
    addTag(tag) {
      this.$emit("add-tag", tag);
    },
  },
};
