<template>
  <main v-if="list" class="mt-5">
    <span id="add-btn">
      <button @click="newDiscount" class="btn btn-success">
        <router-link
          style="color: white"
          :to="`/admin/discount-config/item/${$route.params.item}/${$route.params.id}`"
        >
          آیتم جدید
        </router-link>
      </button>
    </span>
    <FormModal @formSubmited="loadData" :editedItem="editedItem"></FormModal>
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">
          تخفیف
          <span v-if="mainTitle">
            {{ ` -  ${mainTitle}  ${subTitle ? "- " + subTitle : ""}` }}
          </span>
        </h5>
        <div style="flex: 1"></div>
      </div>
      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="list" :fields="fields">
            <template v-slot:cell(type)="data">
              {{
                data.item.type == "product"
                  ? "محصولات"
                  : data.item.type == "category"
                  ? "دسته بندی"
                  : data.item.type == "balance"
                  ? "موجودی"
                  : "رنج"
              }}
            </template>

            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <router-link
                  class="action-btns1 pt-1 px-2"
                  :to="`/admin/discount-config/item/${$route.params.item}/${$route.params.id}/${data.item.id}`"
                >
                  <i class="feather feather-edit text-primary"></i>
                </router-link>
              </span>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a
                  class="action-btns1 pt-1 px-2"
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </main>
  <Loading v-else />
</template>
<script>
import listImpl from "@@/core/pages/discount/list/listImpl.js";
export default {
  extends: listImpl,
};
</script>
