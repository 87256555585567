<template>
  <main class="mt-5 admin-order">
    <div class="card mt-3">
      <OrderHeader
        :allowEdit="allowEditOrder"
        @order-edit="orderEdit"
        @print-order="printOrder"
      />
      <div class="card-body" v-if="order">
        <OrderInfo
          @update-order-status="updateOrderStatus"
          @show-customer="showCustomer"
          :order="order"
        />
        <OrderAddress
          @editOrderAddress="editOrderAddress"
          :addresses="addresses"
          :order="order"
        />

        <OrderInvoices :order="order" />
        <OrderAddButton @new-order="newOrder" />
        <OrderItems
          :total="total"
          :total-price="totalPrice"
          :productsDiscount="productsDiscount"
          :order="order"
          :disabled="disabled"
        />
        <OrderLogs :order="order" />
      </div>
      <Loading v-else />
    </div>
    <CustomerModal :customer="customer" @new-wallet="newWallet" />

    <OrderAdd ref="orderAdd" :order="order" :disabled="disabled" />
    <wallet-modal
      v-model="wallet"
      @set-new-wallet="setNewWallet"
    ></wallet-modal>
    <update-item
      v-model="orderItem"
      @update-log-item="updateLogItem"
      @update-log-status="updateStatus"
      @update-item="updatedItem"
    ></update-item>
    <order-edit
      ref="orderEdit"
      v-model="edit"
      @order-update="updateOrder"
      @addNewAddressToOrder="addNewAddressToOrder"
      :addresses="addresses"
      :shippings="shippings"
    ></order-edit>
    <OrderChangeStatus
      :order="order"
      :disabled="disabled"
      ref="changeOrderStatus"
    />
  </main>
</template>
<script>
import OrderDetailImpl from "@@/core/pages/order/OrderDetail/OrderDetailImpl";
export default {
  components: {},
  extends: OrderDetailImpl,
};
</script>
<style
  scoped
  src="@@/core/pages/order/OrderDetail/OrderDetailStyle.css"
></style>
<style src="@@/core/pages/order/OrderDetail/OrderDetailStylePublic.css"></style>
