<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold py-3">گزارش سفارشات(تکی)</h5>
      </div>
      <div class="card-body">
        <BForm>
          <DatePickerRange
            newFormat="YYYY-MM-DD"
            newFormatShow="jYYYY-jMM-jDD"
            v-model="filter.date"
          />
          <div class="row">
            <BFormGroup class="col-xl-6 col-md-12" label="شناسه سفارش">
              <BFormInput
                v-model="filter.order_ids"
                name="noname"
                type="text"
                placeholder="شناسه ها را با , جدا کنید مانند 13,14,15"
              ></BFormInput>
            </BFormGroup>
            <BFormGroup class="col-xl-6 col-md-12" label="نام سایت نماینده">
              <BFormInput
                v-model="filter.customer_websites"
                name="noname"
                type="text"
                placeholder="مانند test.ir,test1.ir"
              ></BFormInput>
            </BFormGroup>
          </div>
          <BButton @click="loadData()" variant="primary">فیلتر سازی</BButton>
        </BForm>
        <br />
        <div v-if="total_profit != null" class="row">
          <span class="font-bold"> مجموع سود: </span>
          <span class="badge badge-success">
            {{ total_profit | price }}
            تومان
          </span>
        </div>
        <br />

        <b-table
          v-if="reports"
          :style="{ opacity: disabled ? '0.6' : 1 }"
          bordered
          responsive
          striped
          :fields="fields"
          :items="reports"
          :disabled="disabled"
        >
          <template v-slot:cell(index)="data">
            {{
              data.index +
              1 +
              (!disabled
                ? (page - 1) * per_page
                : (previousPage - 1) * per_page)
            }}
          </template>
          <template v-slot:cell(id)="data">
            <a :href="'/admin/order/' + data.item.id" target="_blank">{{
              data.item.id
            }}</a>
          </template>
          <template v-slot:cell(_total)="data">
            {{ data.item._total | price }}
          </template>
          <template v-slot:cell(mobile)="data">
            <router-link
              :to="'/admin/customers/show/' + data.item.customer.id"
              >{{ data.item.customer.mobile }}</router-link
            >
          </template>
          
          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | persianDate }}
          </template>
        </b-table>
        <div v-else-if="reports == ''" class="alert alert-danger">
          موردی یافت تشد
        </div>
        <pagination
          :router="true"
          v-if="reports && reports.data != ''"
          :disabled="disabled"
          :limit="2"
          :data="reports"
          @pagination-change-page="changePage"
        />
      </div>
    </div>
  </main>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput, BTable } from "bootstrap-vue";
import {
  getStatusLabel,
  getStatusColor,
} from "@@/core/libraries/adminFunctions";
import DatePickerRange from "@@/core/components/shared/DatePickerRange/DatePickerRange";
import { priceFilter } from "@@/core/libraries/filters";
import sortMixin from "@@/core/libraries/mixins/sortMixin";

export default {
  name: "OrdersReport",
  components: {
    DatePickerRange,
    BTable,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  mixins: [sortMixin],
  data() {
    return {
      total_profit: null,
      ordersInfo: null,
      attributesInfo: null,
      page: 1,
      previousPage: 1,
      per_page: 50,
      disabled: true,
      reports: null,
      filter: {
        date: {
          startDate: "",
          endDate: "",
        },
        order_ids: null,
        customer_websites: null,
      },
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "id",
          label: "شناسه سفارش",
        },
       
        
        {
          key: "discount_amount",
          label: "تخفیف",
          formatter: priceFilter,
        },
        {
          key: "shipping_amount",
          label: "هزینه حمل و نقل",
          formatter: priceFilter,
        },
        {
          key: "order_profit",
          label: "سود سفارش ",
          formatter: priceFilter,
        },
        {
          key: "total_amount",
          label: "جمع کل",
          formatter: priceFilter,
        },
      
        {
          key: "created_at",
          label: "تاریخ",
        },
      ],
    };
  },
  watch: {
    per_page() {
      this.changePage(1);
    },
  },

  methods: {
    getStatusLabel,
    getStatusColor,
    showAttributesInfo(report) {
      this.attributesInfo = report.attribute_info;
      this.$root.$emit("bv::show::modal", "attributes-info");
    },
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData();
    },
    convertToTimeStamp(data) {
      console.log(data);
      
      var myDate = data;
myDate = myDate.split("-");
var newDate = new Date( myDate[0], myDate[1] - 1, myDate[2]);
console.log(newDate.getTime()/1000);
return newDate.getTime()/1000
    },
    async loadData() {
      try {
        this.disabled = true;
        const response = await this.$axios.get("admin/profits", {
          params: {
            start_date: this.convertToTimeStamp(this.filter.date.startDate),
            end_date: this.convertToTimeStamp(this.filter.date.endDate),
            order_ids: this.filter.order_ids,
            customer_websites: this.filter.customer_websites,
          },
        });
        this.reports = response.data.data.orders;
        this.total_profit = response.data.data.total_profit;
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
      }
    },
    // convertToTimeStamp(data) {
    //   console.log(data);
    //   var myDate = data;
    //   myDate = myDate.split("-");
    //   var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
    //   console.log(newDate.getTime());
    // },
  },
};
</script>
