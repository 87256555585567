export default {
  data() {
    return {
      type: 'year',
      selectedYear: this.moment().format('YYYY'),
      selectedMonth: this.moment().format('M'),
    }
  },
  watch: {
    type(t) {
      this.loadType(t)
    },
    selectedYear() {
      this.loadType()
    },
    selectedMonth() {
      this.loadType()
    }
  },
  computed: {
    yearOptions() {
      let y = this.moment().format('YYYY')
      return [y, y-1, y-2, y-3, y-4, y-5, y-6, y-7, y-8].map(val => {
        return {
          name: val,
          label: val
        }
      })
    },
    monthOptions() {
      let m = this.moment().format('M');
      let options = [];
      for (let i = m; i > 0; i--) {
        options.push({
          name: i,
          label: this.moment().subtract(m - i, 'month').format('MMMM')
        });
      }
      return options;
    }
  },
  methods: {
    loadType(type = null) {
      alert('Please overwrite loadType method')
    },
    getTypeLabels(type, monthLength = 0) {
      let labels = null;
      switch (type) {
        case 'year':
          labels = ('فروردین_اردیبهشت_خرداد_تیر_مرداد_شهریور_مهر_آبان_آذر_دی_بهمن_اسفند').split('_');
          break;
        case 'month':
          // متغیر بر اساس ماهlabel
          labels = Array(monthLength)
          for (let i = 0; i < labels.length; i++) {
            labels[i] = i + 1
          }
          break;
        case 'week':
          labels = ['شنبه', 'یکشنبه', 'دوشنبه', 'سه شنبه', 'چهارشنبه', 'پنجشنبه', 'جمعه'];
          break;
      }

      return labels;
    }
  }
}
