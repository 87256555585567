import { getShippingPrice } from "@@/core/libraries/functions";
import { getStatusLabel } from "@@/core/libraries/adminFunctions";
import Back from "@@/core/components/shared/Back/Back";
import WalletModal from "@@/core/components/customer/CustomerWallet/CustomerWallet";
import UpdateItem from "@@/core/components/virtualOrder/OrderUpdateItem/OrderUpdateItem";
import walletTransaction from "@@/core/components/virtualOrder/OrderTransaction/OrderTransaction";
import OrderStatus from "@@/core/components/order/OrderStatus/OrderStatus";
import ProductSelect from "@@/core/components/shared/ProductSelect/ProductSelect";
import OrderEdit from "@@/core/components/order/OrderEdit/OrderEdit";
import Loading from "@@/core/components/shared/Loading/Loading";
import {
  BFormGroup,
  BFormInput,
  BModal,
  BTable,
  BTooltip,
  VBModal,
  BButton,
} from "bootstrap-vue";
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";
import OrderLogs from "@@/core/components/virtualOrder/OrderLogs/OrderLogs";
import walletSection from "@@/core/components/virtualOrder/wallet/wallet";
import OrderItems from "@@/core/components/virtualOrder/OrderItems/OrderItems";
import CustomerModal from "@@/core/components/order/CustomerModal/CustomerModal";
import ChangeOrderStatus from "@@/core/components/order/OrderChangeStatus/OrderChangeStatus";
import OrderInvoices from "@@/core/components/order/OrderInvoices/OrderInvoices";
import OrderAddress from "@@/core/components/virtualOrder/OrderAddress/OrderAddress";
import OrderAdd from "@@/core/components/virtualOrder/OrderAdd/OrderAdd";
import OrderInfo from "@@/core/components/order/OrderInfo/OrderInfo";
import OrderHeader from "@@/core/components/virtualOrder/OrderHeader/OrderHeader";
import OrderAddButton from "@@/core/components/order/OrderAddButton/OrderAddButton";
import OrderChangeStatus from "@@/core/components/order/OrderChangeStatus/OrderChangeStatus";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "OrderDetail",
  components: {
    Back,
    WalletModal,
    UpdateItem,
    OrderStatus,
    ProductSelect,
    OrderEdit,
    Loading,
    BTooltip,
    BTable,
    BFormGroup,
    BFormInput,
    BModal,
    ProductSearch,
    OrderChangeStatus,
    OrderAddButton,
    OrderHeader,
    OrderInfo,
    OrderAdd,
    OrderAddress,
    OrderInvoices,
    ChangeOrderStatus,
    CustomerModal,
    OrderItems,
    OrderLogs,
    walletTransaction,
    walletSection,
    BButton,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      url: "admin/virtualOrders",
      order: null,
      customer: {},
      edit: {
        addresses: null,
      },
      disabled: false,
      disable: false,
      wallet: null,
      orderItem: {},
      newItem: {},
      products: [],
      customerInfo: null,
      addresses: [],
      shippings: [],
      addressShippings: null,
      transactionList: [],
      cardByCardTransactionIds: [],
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.getOrderDetail(this.$route.params.id);
      this.loadData();
    }
  },
  computed: {
    total() {
      let orderItems = this.order.items.filter((item) => item.status == 1);
      if (orderItems != "") {
        return orderItems.reduce((sum, item) => {
          sum += item.amount * item.quantity;
          return sum;
        }, 0);
      } else {
        return 0;
      }
    },
    productsDiscount() {
      let orderItems = this.order.items.filter((item) => item.status == 1);
      if (orderItems != "") {
        return orderItems.reduce((sum, item) => {
          sum += item.quantity * item.discount_amount;
          return sum;
        }, 0);
      } else {
        return 0;
      }
    },
    totalPrice() {
      return (
        this.total + this.order.shipping_amount - this.order.discount_amount
      );
    },
    getShippingPrice() {
      return getShippingPrice(
        this.order.address,
        this.order.shipping,
        this.totalPrice + this.order.discount_amount // تخفیف نباید حساب بشه
      );
    },
  },
  watch: {
    // order: function(newVal) {
    //   console.log(newVal);
    // },
  },
  methods: {
    editOrderAddress(adderss) {
      this.order.address = adderss;
    },
    addNewAddressToOrder(address) {
      this.addresses.push(address);
    },
    getStatusLabel,
    updateOrderStatus() {
      this.$refs.changeOrderStatus.updateOrderStatus();
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === 0) return "table-order-status";
    },
    getOrderDetail(id) {
      this.$axios.get(`${this.url}/${id}`).then((response) => {
        let order = response.data.data.order;
        this.transactionList = response.data.data.wallet_transactions;
        this.cardByCardTransactionIds =
          response.data.data.cardByCardTransactionIds;
        order.address = JSON.parse(order.address);
        order.items.forEach((item) => {
          item.extra = JSON.parse(item.extra);
        });

        if (order.reserved == 1) {
          order.reservations.forEach((item) => {
            if (item.order_logs != "") {
              item.order_logs.forEach((log) => {
                order.order_logs.push(log);
              });
            }
            item.items.forEach((p) => {
              p.extra = JSON.parse(p.extra);
              order.items.push(p);
            });
          });
        }
        this.order = order;

        this.getCustomer(this.order.customer_id);
      });
    },
    async getCustomer(id) {
      const response = await this.$axios.get("admin/customers/" + id);
      let customer = response.data.data[0];
      this.addresses = customer.addresses;
    },
    async loadData() {
      const response = await this.$axios.get("admin/widgets", {
        params: {
          widgets: '["shippings"]',
        },
      });
      this.shippings = response.data.data.widgets.shippings;
    },
    showCustomer(item) {
      this.customer = item;
      this.wallet = {
        customer_id: item.id,
        amount: 0,
      };
      this.$root.$emit("bv::show::modal", "customer-modal");
    },

    updateLogItem(orderItem) {
      let orderLogOldQuantity =
        this.order.items[this.orderItem.index].old_quantity;
      let orderLogQuantity = 0;
      let orderLogType = "";
      if (+orderLogOldQuantity > +orderItem.quantity) {
        orderLogType = "decrement";
        orderLogQuantity = +orderLogOldQuantity - +orderItem.quantity;
      } else {
        orderLogType = "increment";
        orderLogQuantity = +orderItem.quantity - +orderLogOldQuantity;
      }
      let orderLog = {
        log_items: [
          {
            type: orderLogType,
            quantity: orderLogQuantity,
            order_item_id: orderItem.id,
            order_log_id: "",
            id: "",
          },
        ],
        amount:
          orderLogType == "decrement"
            ? -orderItem.amount * orderLogQuantity
            : orderItem.amount * orderLogQuantity,
      };
      this.order.order_logs.unshift(orderLog);
    },
    updatedItem(orderItem) {
      this.$root.$set(this.order.items, this.orderItem.index, orderItem);
    },
    updateStatus(orderItemLog) {
      let orderLog = {
        log_items: [
          {
            type: orderItemLog.type,
            quantity: orderItemLog.quantity,
            order_item_id: orderItemLog.order_item_id,
            order_log_id: orderItemLog.order_log_id,
            id: orderItemLog.id,
            // amount : orderItemLog.type == 'delete' ? this.orderItem.amount : -this.orderItem.amount
          },
        ],
      };
      this.order.order_logs.unshift(orderLog);
    },
    newOrder() {
      this.$refs.orderAdd.newOrder();
    },
    newWallet() {
      this.wallet.amount = 0;
      this.$root.$emit("bv::show::modal", "customer-wallet");
    },
    setNewWallet(newWallet) {
      this.customer.wallet.balance =
        +this.customer.wallet.balance + newWallet.amount;
    },
    printOrder() {
      let routeData = this.$router.resolve({
        path: "/admin/print-orders",
        query: { ids: this.order.id },
      });
      window.open(routeData.href, "_blank");
    },
    orderEdit() {
      let edit = cloneDeep(this.order);
      edit.address.label =
        edit.address.city.province.name +
        " ، " +
        edit.address.city.name +
        " ، " +
        edit.address.address +
        " - گیرنده : " +
        edit.address.first_name +
        " " +
        edit.address.last_name +
        " - کد پستی : " +
        edit.address.postal_code;

      edit.shipping = this.shippings.find((shipping) => {
        return shipping.id == edit.shipping.id;
      });
      this.edit = edit;

      this.$refs.orderEdit.setShippingReal();

      this.$root.$emit("bv::show::modal", "order-edit");
    },
    updateOrder(item, shippingPrice) {
      let order = item;
      order.address = JSON.parse(order.address);
      this.order.address = order.address;
      this.order.address_id = order.address_id;
      this.order.shipping = order.shipping;
      this.order.shipping_amount = shippingPrice;
      this.order.shipping_id = order.shipping_id;
      this.order.description = order.description;
      this.order.discount_amount = order.discount_amount;
      this.order.order_logs = order.order_logs;
    },
    async orderStatusFunc(type) {
      this.disable = true;
      try {
        let fd = new FormData();
        fd.append("virtualOrder_id", this.$route.params.id);
        let response = await this.$axios.post(
          type == "accept"
            ? "admin/accounting/virtualOrders/accept"
            : "admin/accounting/virtualOrders/reject",
          fd
        );
        this.$root.notify(response, "success");
        this.$router.push("/admin/accounting/virtual-order");
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disable = false;
      }
    },
  },
};
