<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">سفارش غیرحضوری</h5>
      </div>

      <div class="card-body">
        <form @submit.prevent="submit" style="min-height: 500px">
          <b-form-group class="col-md-6" label="زمان ثبت">
            <date-picker
              type="datetime"
              v-model="created_at"
              :format="'YYYY-MM-DD HH:mm'"
              :display-format="'jYYYY-jMM-jDD HH:mm'"
            />
          </b-form-group>
          <label for="">کاربر را انتخاب کنید</label>
          <div class="d-flex align-items-center" style="gap: 16px">
            <UserSearch :disabled="disabled" v-model="selectedUser" />
            <router-link
              target="_blank"
              class="btn btn-primary"
              to="/admin/users/create"
              >ساخت کاربر جدید</router-link
            >
            <!--  -->
            <div v-if="selectedUser" class="little card info">
              <div class="card-header">اطلاعات کاربری</div>
              <div class="card-body">
                <div class="d-flex" style="gap: 1rem">
                  <div>
                    شارژ کیف پول:
                    {{ selectedUser.wallet_balance | price }}
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
          </div>
          <div class="d-flex align-items-center" style="gap: 16px">
            <b-form-group class="col-xl-6 col-md-12" label="انتخاب آدرس">
              <multiselect
                placeholder="آدرس را انتخاب کنید"
                v-model="vo.address_id"
                open-direction="bottom"
                :options="userAddresses"
                label="title"
                track-by="id"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                :disabled="disabled"
                @input="setShipping($event)"
              >
                <template slot="noOptions"> موردی یافت نشد </template>
                <template slot="noResult"> موردی یافت نشد </template>
              </multiselect>
            </b-form-group>
            <b-button
              :disabled="!selectedUser"
              @click="newAddress"
              variant="primary"
              >ثبت آدرس جدید</b-button
            >
          </div>
          <b-form-group label="نوع پرداخت:">
            <div
              class="text-center mt-3 d-flex align-items-center"
              style="gap: 7px"
            >
              <input id="wallet" type="checkbox" v-model="wallet" />
              <label for="wallet" style="margin-bottom: 0">کیف پول</label>
              <input
                id="cardByCard"
                type="checkbox"
                v-model="cardByCard"
              />
              <label for="cardByCard" style="margin-bottom: 0"
                >کارت به کارت</label
              >
            </div>
          </b-form-group>
          <b-form-group v-if="cardByCard" class="col-xl-6 col-md-12" label="شماره کارت">
            <b-form-input v-model="vo.cardByCardTransactionId"></b-form-input>
          </b-form-group>
          <b-form-group v-if="vo.address_id" class="col-md-6">
            <label class="pb-1" for="">
              حمل و نقل <i class="text-danger">*</i></label
            >
            <multiselect
              v-model="vo.shipping"
              :allow-empty="false"
              placeholder="انتخاب کنید"
              open-direction="bottom"
              :options="addressShippings"
              label="name"
              track-by="id"
              :searchable="true"
              :multiple="false"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
            >
              <template slot="noOptions"> موردی یافت نشد </template>

              <template slot="noResult"> موردی یافت نشد </template>
            </multiselect>
          </b-form-group>
          <b-form-group class="col-xl-6 col-md-12" label="توضیحات">
            <b-form-textarea v-model="vo.description"></b-form-textarea>
          </b-form-group>
          <div>
            <ProductSearch
              class="row"
              @selectProduct="selectTheProduct"
              @selectVariety="selectedVariety"
              :disabled="disabled"
            />
            <template v-if="vo.varieties != ''">
              <b-table
                class="mt-3 admin-order"
                hover
                bordered
                responsive
                :items="vo.varieties"
                :fields="fields"
              >
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template v-slot:cell(image)="data" class="text-center">
                  <div class="order-product-img">
                    <router-link
                      :to="
                        '/admin/products/edit/' + data.item.variety.product.id
                      "
                      v-if="data.item.variety"
                    >
                      <img
                        class="w-100 h-100"
                        v-if="
                          data.item.variety && data.item.variety.images != ''
                        "
                        :src="data.item.variety.images[0].url"
                        alt=""
                      />
                      <img
                        class="w-100 h-100"
                        v-else-if="
                          data.item.variety.product &&
                          data.item.variety.product.major_image
                        "
                        :src="data.item.variety.product.major_image.url"
                        alt=""
                      />
                    </router-link>
                  </div>
                </template>
                <template v-slot:cell(title)="data">
                  <span style="width: 300px">
                    <p class="mb-0">
                      {{ data.item.variety.product.title }}
                    </p>
                    شناسه : {{ data.item.variety.id }} |
                    {{ data.item.variety.title }}
                  </span>
                </template>
                <template v-slot:cell(quantity)="data">
                  <button
                    type="button"
                    class="border border-radius7 px-1 bg-none"
                    @click="decrement(data.item)"
                    :disabled="
                      data.item.quantity == 1 || disabled ? true : false
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-dash"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
                      />
                    </svg>
                  </button>
                  <span class="px-2">{{ data.item.quantity }}</span>
                  <button
                    type="button"
                    class="border border-radius7 px-1 bg-none"
                    @click="increment(data.item)"
                    :disabled="disabled"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                      />
                    </svg>
                  </button>
                  <!-- <b-form-input
                placeholder="تعداد"
                v-model="data.item.quantity"
              ></b-form-input> -->
                  <input
                    type="hidden"
                    :name="'varieties[' + data.index + '][id]'"
                    :value="data.item.variety_id"
                  />
                  <input
                    type="hidden"
                    :name="'varieties[' + data.index + '][quantity]'"
                    :value="data.item.quantity"
                  />
                </template>
                <template v-slot:cell(amount)="data">
                  <span v-if="data.item.amount != 0">{{
                    data.item.amount | price
                  }}</span>
                  <span v-else>0</span>
                </template>
                <template v-slot:cell(discount_amount)="data">
                  <span v-if="data.item.discount_amount">{{
                    data.item.discount_amount | price
                  }}</span>
                  <span v-else>ندارد</span>
                </template>
                <template v-slot:cell(flash)="data">
                  {{
                    data.item.variety.product &&
                    data.item.variety.product.active_flash != ""
                      ? data.item.variety.product.active_flash[0].title
                      : "ندارد"
                  }}
                </template>
                <template v-slot:cell(total_price)="data">
                  <span v-if="data.item.amount != 0">{{
                    (data.item.amountWithCalcDiscount * data.item.quantity)
                      | price
                  }}</span>
                  <span v-else>0</span>
                </template>
                <template v-slot:cell(delete)="data">
                  <a
                    class="action-btns1 pt-1 px-2"
                    @click="deleteItem(data.index)"
                  >
                    <i class="feather feather-trash-2 text-danger"></i>
                  </a>
                </template>
              </b-table>

              <div class="mt-5 col-md-3 mx-auto">
                <div class="d-md-flex justify-content-between">
                  <span class="px-3 py-2 font-bold"> مجموع قیمت کالا ها: </span>
                  <span class="px-3 py-2"> {{ total | price }} تومان </span>
                </div>
                <div class="d-md-flex justify-content-between">
                  <span class="px-3 py-2 font-bold"> تخفیف : </span>
                  <span v-if="vo.discount_amount" class="px-3 py-2">
                    {{ vo.discount_amount | price }} تومان
                  </span>
                  <span v-else class="px-3 py-2"> ندارد </span>
                </div>

                <div
                  v-if="vo.shipping"
                  class="d-md-flex justify-content-between"
                >
                  <span class="px-3 py-2 font-bold"> هزینه ارسال : </span>
                  <span v-if="getShippingPrice != 0" class="px-3 py-2">
                    {{ getShippingPrice | price }} تومان
                  </span>
                  <span v-else class="px-3 py-2"> رایگان </span>
                </div>
                <div class="d-md-flex justify-content-between">
                  <span class="px-3 py-2 font-bold"> پرداخت نهایی : </span>
                  <span class="px-3 py-2">
                    {{ (totalPrice - Number(discountAmount)) | price }} تومان
                  </span>
                </div>
                
                
              </div>
            </template>

            <div class="col-md-12 text-center mt-5">
              <template v-if="disabledBtn">
                <div class="text-danger mb-3">
                  مبلغ پرداخت نهایی از موجودی کیف پول نماینده بیشتر است.
                </div>
              </template>
              <button
                :disabled="disabled || disabledBtn ? true : false"
                type="submit"
                class="btn btn-success"
              >
                {{ mode == "edit" ? " ویرایش سفارش" : " ثبت سفارش" }}
              </button>
            </div>
          </div>
        </form>
      </div>
      <address-modal
        @new-address="addNewAddress"
        mode="create"
        v-model="address"
        :adminProvinces="provinces"
        :selectedUser="selectedUser"
      ></address-modal>
    </div>
  </main>
</template>

<script>
import createImpl from "@@/core/pages/virtualOrders/create/createImpl";
export default {
  extends: createImpl,
};
</script>
<style>
.order-product-img {
  width: 50px;
}
.card.little.info {
  background: linear-gradient(30deg, #d2e8ff 0%, #d5e6fc 35%, #eaf5ff 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
}
</style>
