<template>
  <main class="mt-5 admin-recommendation">
    <div class="card mt-3">
      <div class="card-header  border-0 d-flex w-100">
        <h5 class="card-title font-bold">گروه پیشنهادی {{ group }}</h5>
        <div style="flex:1"></div>
        <button class="btn btn-success" v-b-modal.recommendation-modal>افزودن محصول</button>
      </div>
      <div class="card-body row">
        <table :style="disabled ? 'opacity: 0.8' : ''"
               class="table table-responsive-sm table-bordered table-hover">
          <thead>
          <tr>
            <th scope="col"><i class="fas fa-arrows-alt-v"></i>شناسه</th>
            <th scope="col">محصول</th>
            <th scope="col">تاریخ ثبت</th>
            <th scope="col">حذف</th>
          </tr>
          </thead>
          <draggable v-model="items" tag="tbody" @change="sortItems" v-bind="dragOptions">
            <tr class='flip-list' v-for="(item) in items" :key="item.id">
              <td scope="row">{{ item.id }}</td>
              <td scope="row">{{ item.product.id + ' - ' + item.product.title }}</td>
              <td scope="row">{{ item.created_at | persianDate }}</td>
              <td>
                                <span :id="'delete-btn-' + item.id">
                                    <a :disabled="!hasPermission('recommendation') ? true : false "
                                       class="action-btns1  pt-1 px-2 " @click="deleteItem(item.id)">
                                        <i class="feather feather-trash-2 text-danger"></i>
                                    </a>
                                  </span>
                <b-tooltip v-if="!hasPermission('recommendation')" :target="'delete-btn-' + item.id" triggers="hover">
                  شما به این بخش دسترسی ندارید
                </b-tooltip>
              </td>
            </tr>
          </draggable>
        </table>
        <Loading class="w-100" v-if="items == null"/>
        <div class="alert alert-danger text-right" v-if="items == ''">
          <span class="text-right">موردی یافت نشد ! </span>
        </div>
      </div>
    </div>
    <b-modal id="recommendation-modal" title="افزودن محصول" :ok-disabled="disabled"
             :cancel-disabled="disabled" @ok.prevent="newItem" class="in-form-modal" ok-title="ثبت" cancel-title="لغو" size="lg">
      <form :action="url">
        <b-form-group label="انتخاب محصول">
          <ProductSearch full-width @selectProduct="selectProduct" :product-only="true" />
        </b-form-group>
        <b-form-group class="col-md-6">
          <label> تاریخ و زمان پایان (اختیاری) </label>
          <date-picker type="datetime" v-model="expires_at" format="YYYY-MM-DD HH:mm" display-format="jYYYY-jMM-jDD HH:mm" />
        </b-form-group>
      </form>

    </b-modal>
  </main>
</template>

<script>
import Loading from "@@/core/components/shared/Loading/Loading";
import ProductSelect from "@@/core/components/shared/ProductSelect/ProductSelect";
import {BTooltip, BModal, BFormGroup, VBModal} from "bootstrap-vue";
import draggable from "vuedraggable";
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";

export default {
  name: "Recommendation",
  props: {
    group: String
  },
  components: {
    ProductSearch,
    ProductSelect,
    Loading,
    BTooltip, BModal, BFormGroup,
    draggable
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      url: 'admin/recommendations/groups/' + this.group,
      items: null,
      disabled: false,
      selectedProduct: {},
      products: []
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: this.disabled,
        ghostClass: "ghost"
      }
    }
  },
  created() {
    this.loadItems()
  },
  methods: {
    deleteItem(id) {
      window.swal({
        title: "آیتم حذف شود؟",
        text: "این عملیات غیر قابل بازگشت خواهد بود",
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        dangerMode: true,
        buttons: ["خیر", "بله حذف کن"]
      })
          .then((willDelete) => {
            if (willDelete) {
              this.$axios.delete(`admin/recommendations/${id}`)
                  .then((response) => {
                    this.items = this.items.filter(item => item.id != id)
                    window.swal({
                      icon: "success",
                      showConfirmButton: true,
                      text: response.data.message,
                      button: "باشه",
                    })
                  })
                  .catch(error => {
                    window.swal({
                      title: 'خطا',
                      showConfirmButton: true,
                      text: error.response.data.message,
                      button: "باشه",
                    })
                  })
            }
          })
          .catch(() => {

          })
    },
    async newItem() {
      try {
        this.disabled = true;
        const response = await this.$axios.post('admin/recommendations', {
          group: this.group,
          product_id: this.selectedProduct.id,
          expires_at: this.expires_at
        });
        this.items.push(response.data.data.recommendation);
        this.$root.notify(response.data.message, 'success');
        this.$root.$emit('bv::hide::modal', 'recommendation-modal');
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false
      }
    },
    async loadItems () {
      const response = await this.$axios.get(this.url)

      this.items = response.data.data.recommendations
      this.products = response.data.data.widgets.products.map(product => {
        product.label = product.id + ' - ' + product.title

        return product
      })
    },
    async sortItems() {
      let orders = [];

      this.items.forEach((item) => {
        orders.push(item.id)
      });
      try {
        this.disabled = true;
        const response = await this.$axios.post(`admin/recommendations/groups/${this.group}/sort`, {
          group: this.group,
          ids: orders,
        })
        this.$root.notify(response.data.message, 'success')
      } catch (error) {
        this.$root.notify(error)
      } finally {
        this.disabled = false
      }
    },
    selectProduct(product) {
      this.selectedProduct = product
    }
  }
}
</script>

<style scoped>
.admin-slider .table > thead > tr > td,
.admin-slider .table > thead > tr > th {
  font-weight: normal;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
