<template>
    <main class="mt-5">
        <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3"> تنوع ها</h5>
        <div style="flex: 1"></div>
        <ExcelDownload :extraQuery="{list:1}" class="mr-2" url="admin/reports/varieties-list" />
      </div>
      <div class="card-body  border-0">
        <b-form @submit.prevent="sendExcel">
            <b-form-file
            v-model="file"
      :state="Boolean(file)"
      placeholder="فایل اکسل را انتخاب کنید"
      accept=".xls,.xlsx"
            ></b-form-file>
            <b-button type="submit" v-if="disable==false" class="mt-3" variant="primary" >ارسال</b-button>
            <b-button  v-else  class="mt-3" disabled  variant="primary" >ارسال</b-button>
        </b-form>
      </div>
        
    </div>
    </main>
  </template>
  <script>

  import quntityVarietiesImpl from '@@/core/pages/quntity/quntityVarietiesImpl'
  export default {
    extends: quntityVarietiesImpl
  }
  </script>
  