import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";
import FormModal from "@@/core/components/discountList/modal/modal.vue";
import {
  BButton,
  BCard,
  BCollapse,
  BFormGroup,
  BFormInput,
  BTable,
  BTooltip,
  VBToggle,
} from "bootstrap-vue";

export default {
  name: "Gift",
  components: {
    FormModal,
    Loading,
    BTooltip,
    BButton,
    BCollapse,
    BCard,
    BFormGroup,
    BFormInput,
    BTable,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  mixins: [mixins],

  data() {
    return {
      url: "admin/specificDiscount",
      list: [],
      editedItem: null,
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "title",
          label: "نام",
        },
        {
          key: "start_date",
          label: "تاریخ شروع",
        },
        {
          key: "end_date",
          label: "تاریخ پایان",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
        {
          key: "add",
          label: "افزودن",
        },
      ],
      mainTitle:"",
    };
  },
  mounted() {
    this.loadData();
    
  },
  methods: {
    editItem(item) {
      this.editedItem = item;
      this.$root.$emit("bv::show::modal", "coupen-info");
    },
    newDiscount() {
      this.editedItem = null;
      this.$root.$emit("bv::show::modal", "coupen-info");
    },
    loadData() {
      
      this.loadItems().then((res) => {
        this.list = res.data.data.specificDiscounts;
      });
    },
    deleteItem(id) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          console.log(willDelete);
          if (willDelete) {
            this.$axios
              .delete(this.url + `/${id}`)
              .then((response) => {
                this.loadData();
                window.swal({
                  icon: "success",
                  showConfirmButton: true,
                  text: response.data.message,
                  button: "باشه",
                });
              })
              .catch((error) => {
                window.swal({
                  title: "خطا",
                  showConfirmButton: true,
                  text: error.response.data.message,
                  button: "باشه",
                });
              });
          }
        })
        .catch(() => {});
    },
  },
};
