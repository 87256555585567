import {BTooltip} from "bootstrap-vue";
import Back from "@@/core/components/shared/Back/Back";

export default {
  name: "OrderHeader",
  props:{
    allowPrint:{
      type:Boolean,
      default:true
    },
  },
  components: {
    BTooltip, Back
  },
  methods: {
    orderEdit() {
      this.$emit('order-edit')
    },
    printOrder() {
      this.$emit('print-order')
    }
  }
}
