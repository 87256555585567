<template>
  <b-modal id="update-order-item" hide-footer title="ویرایش سفارش">
    <form @submit.prevent="updateOrderItem">
      <b-form-group v-if="orderItem">
        <label class="pb-1" for="">
          تعداد جدید <i class="text-danger">*</i></label
        >
        <div class="d-flex">
          <b-form-input v-model="orderItem.quantity"> </b-form-input>
          <button :disabled="disabled" class="btn btn-success mr-1">
            ثبت تعداد
          </button>
        </div>
      </b-form-group>
      <b-form-group label="نوع پرداخت:">
          <div class="text-center mt-3 d-flex align-items-center" style="gap: 7px">
          <input id="wallet" type="checkbox" v-model="wallet" />
          <label for="wallet" style="margin-bottom: 0">کیف پول</label>
          <input id="cardByCard" type="checkbox" v-model="cardByCard" />
          <label for="cardByCard" style="margin-bottom: 0">کارت به کارت</label>
        </div>
        </b-form-group>
      <b-form-group label="شماره کارت" v-if="cardByCard">
        <b-form-input v-model="cartNumber"></b-form-input>
      </b-form-group>
    </form>
    <form @submit.prevent="updateStatus" class="mt-5">
      وضعیت :
      <button
        type="button"
        class="btn btn-success"
        :disabled="orderItem.status == 1 || disabled ? true : false"
        @click="updateStatus(1)"
      >
        فعال
      </button>
      <button
        type="button"
        class="btn btn-danger mr-2"
        :disabled="orderItem.status == 0 || disabled ? true : false"
        @click="updateStatus(0)"
      >
        غیر فعال
      </button>
      <span style="float: left; line-height: 38px"
        >موجودی فعلی:
        {{
          orderItem && orderItem.variety ? orderItem.variety.quantity : "?"
        }}</span
      >
    </form>
  </b-modal>
</template>
<script>
import OrderUpdateItemImpl from "@@/core/components/virtualOrder/OrderUpdateItem/OrderUpdateItemImpl";
export default {
  extends: OrderUpdateItemImpl,
};
</script>
