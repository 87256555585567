<template>
  <main class="mt-5 admin-order">
    <div class="card mt-3">
      <OrderHeader
        
        :allowPrint="false"
        @order-edit="orderEdit"
        @print-order="printOrder"
      />
      <div class="card-body" v-if="order">
        <OrderInfo
          @update-order-status="updateOrderStatus"
          @show-customer="showCustomer"
          :order="order"
        />
        <OrderAddress
          @editOrderAddress="editOrderAddress"
          :addresses="addresses"
          :order="order"
        />

        <OrderInvoices :order="order" />
        <OrderAddButton @new-order="newOrder" />
        <OrderItems
          :total="total"
          :total-price="totalPrice"
          :order="order"
          :productsDiscount="productsDiscount"
          :disabled="disabled"
        />
        <div class="row">
          <div class="col-md-6">
            <h5 class="bg-light text-center rounded py-2 mb-0 font-bold">
              کدهای پیگیری کارت به کارت
            </h5>
            <ol
              class="d-flex flex-column align-items-center justify-content-center"
              style="margin-top: 16px; gap: 16px"
              v-if="cardByCardTransactionIds && cardByCardTransactionIds.length"
            >
              <li
                v-for="(item, index) in cardByCardTransactionIds"
                :key="index"
              >
                {{ item }}
              </li>
            </ol>
            <p v-else>موردی پیدا نشد</p>
          </div>
          <div v-if="hasPermission('report')" class="col-md-6">
            <walletSection :user="order.user" />
          </div>
        </div>
        <OrderLogs :order="order" />
        <walletTransaction :list="transactionList" />
        <div  v-if="hasPermission('report')" class="d-flex justify-content-center">
          <div class="col-md-6 d-flex justify-content-center" style="gap: 6rem">
            <b-button @click="orderStatusFunc('accept')" variant="primary" :disabled="disable"> تایید سفارش </b-button>
            <b-button @click="orderStatusFunc('reject')" variant="warning" :disabled="disable"> عدم تایید سفارش </b-button>
          </div>
        </div>
      </div>
      <Loading v-else />
    </div>
    <CustomerModal :customer="customer" @new-wallet="newWallet" />

    <OrderAdd ref="orderAdd" :order="order" :disabled="disabled" />
    <wallet-modal
      v-model="wallet"
      @set-new-wallet="setNewWallet"
    ></wallet-modal>
    <update-item
      v-model="orderItem"
      @update-log-item="updateLogItem"
      @update-log-status="updateStatus"
      @update-item="updatedItem"
    ></update-item>
    <order-edit
      ref="orderEdit"
      v-model="edit"
      @order-update="updateOrder"
      @addNewAddressToOrder="addNewAddressToOrder"
      :addresses="addresses"
      :shippings="shippings"
    ></order-edit>
    <OrderChangeStatus
      :order="order"
      :disabled="disabled"
      ref="changeOrderStatus"
    />
  </main>
</template>
<script>
import OrderDetailImpl from "@@/core/pages/virtualOrders/OrderDetail/OrderDetailImpl";
export default {
  components: {},
  extends: OrderDetailImpl,
};
</script>
<style
  scoped
  src="@@/core/pages/virtualOrders/OrderDetail/OrderDetailStyle.css"
></style>
<style
  src="@@/core/pages/virtualOrders/OrderDetail/OrderDetailStylePublic.css"
></style>
