<template>
  <div class="app-header header">
    <div class="container-fluid">
      <div class="d-flex">
        <router-link to="/admin" class="header-brand">
          <img
            src="@@/assets/admin/images/brand/logo.png"
            class="header-brand-img desktop-lgo"
            alt="Dayonelogo"
          />
          <img
            src="@@/assets/admin/images/brand/logo-white.png"
            class="header-brand-img dark-logo"
            alt="Dayonelogo"
          />
          <img
            src="@@/assets/admin/images/brand/favicon.png"
            class="header-brand-img mobile-logo"
            alt="Dayonelogo"
          />
          <img
            src="@@/assets/admin/images/brand/favicon1.png"
            class="header-brand-img darkmobile-logo"
            alt="Dayonelogo"
          />
        </router-link>
        <div
          @click="toggleSidebar"
          class="app-sidebar__toggle"
          data-toggle="sidebar"
        >
          <a class="open-toggle" href="#">
            <i class="feather feather-menu"></i>
          </a>
          <a class="close-toggle" href="#">
            <i class="feather feather-x"></i>
          </a>
        </div>
        <div class="mt-0">
          <form class="form-inline">
            <div class="search-element">
              <input
                type="search"
                class="form-control header-search"
                placeholder="جستجو…"
                aria-label="Search"
                tabindex="1"
              />
              <button class="btn btn-primary-color">
                <i class="feather feather-search"></i>
              </button>
            </div>
          </form>
        </div>
        <!-- SEARCH -->
        <div class="d-flex order-lg-2 my-auto mr-auto">
          <a
            class="nav-link my-auto icon p-0 nav-link-lg d-md-none navsearch"
            href="#"
            data-toggle="search"
          >
            <i class="feather feather-search search-icon header-icon"></i>
          </a>
          <div class="dropdown">
            <a
              href="/"
              target="_blank"
              title="مشاهده وبسایت"
              class="nav-link icon"
            >
              <i class="feather feather-home header-icons"></i>
            </a>
          </div>
          <div title="تمام صفحه" class="dropdown header-fullscreen">
            <a class="nav-link icon full-screen-link">
              <i
                class="feather feather-maximize fullscreen-button fullscreen header-icons"
              ></i>
              <i
                class="feather feather-minimize fullscreen-button exit-fullscreen header-icons"
              ></i>
            </a>
          </div>
          <div title="پیام کاربران" class="dropdown header-message">
            <a class="nav-link icon" data-toggle="dropdown">
              <i class="feather feather-mail header-icon"></i>
              <span class="badge badge-success side-badge">{{
                unreadContacts
              }}</span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-left dropdown-menu-arrow animated"
            >
              <div class="header-dropdown-list message-menu" id="message-menu">
                <a
                  v-for="contact in contacts"
                  class="dropdown-item border-bottom"
                  href="#"
                >
                  <div class="d-flex align-items-center">
                    <!--                              <div class="">-->
                    <!--                                <span class="avatar avatar-md brround align-self-center cover-image"-->
                    <!--                                  data-image-src="@@/assets/admin/images/users/1.jpg"></span>-->
                    <!--                              </div>-->
                    <div class="d-flex">
                      <div class="pl-3">
                        <h6 class="mb-1">{{ contact.name }}</h6>
                        <p class="fs-13 mb-1">{{ contact.subject }}</p>
                        <div class="small text-muted">
                          {{ moment(contact.created_at).fromNow() }}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="text-center p-2">
                <router-link to="/admin/contact" class=""
                  >نمایش همه</router-link
                >
              </div>
            </div>
          </div>
          <div title="اعلانات" class="dropdown header-notify">
            <a
              class="nav-link icon"
              data-toggle="sidebar-right"
              data-target=".sidebar-right"
            >
              <i class="feather feather-bell header-icon"></i>
              <span class="bg-dot">{{
                unreadNotifications ? unreadNotifications : 0
              }}</span>
            </a>
          </div>
          <div class="dropdown profile-dropdown">
            <a
              href="#"
              class="nav-link pr-1 pl-0 leading-none"
              data-toggle="dropdown"
            >
              <button id="signBtn">
                <i class="fa fa-sign-out"></i>
              </button>
            </a>
            <div
              class="dropdown-menu dropdown-menu-left dropdown-menu-arrow animated"
            >
              <!--                        <div class="p-3 text-center border-bottom">-->
              <!--                          <a href="#" class="text-center user pb-0 font-weight-bold">John Thomson</a>-->
              <!--                          <p class="text-center user-semi-title">App Developer</p>-->
              <!--                        </div>-->
              <!--                        <a class="dropdown-item d-flex" href="#">-->
              <!--                          <i class="feather feather-user ml-3 fs-16 my-auto"></i>-->
              <!--                          <div class="mt-1">Profile</div>-->
              <!--                        </a>-->
              <!--                        <a class="dropdown-item d-flex" href="#">-->
              <!--                          <i class="feather feather-settings ml-3 fs-16 my-auto"></i>-->
              <!--                          <div class="mt-1">Settings</div>-->
              <!--                        </a>-->
              <!--                        <a class="dropdown-item d-flex" href="#">-->
              <!--                          <i class="feather feather-mail ml-3 fs-16 my-auto"></i>-->
              <!--                          <div class="mt-1">Messages</div>-->
              <!--                        </a>-->
              <h6 v-if="adminProfile" class="text-center font-bold pt-2">
                {{ adminProfile.name }}
              </h6>
              <a
                class="dropdown-item d-flex cursor-pointer"
                data-toggle="modal"
                data-target="#changepasswordnmodal"
              >
                <i class="feather feather-edit-2 ml-3 fs-16 my-auto"></i>
                <div class="mt-1">تغییر رمزعبور</div>
              </a>
              <a
                class="dropdown-item d-flex cursor-pointer"
                @click="logOut()"
                :disabled="disabled"
              >
                <i class="feather feather-power ml-3 fs-16 my-auto"></i>
                <div class="mt-1">خروج</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderImpl from "@@/core/components/shared/Header/HeaderImpl";
export default {
  extends: HeaderImpl,
};
</script>

<style
  scoped
  src="@@/core/components/shared/Header/HeaderStylePublic.css"
></style>
<style>
#signBtn {
  border: none;
  background: transparent;
  font-size: 24px;
  transform: rotate(180deg);
}
</style>
