import InputGroup from "@@/core/components/product/shared/InputGroup/InputGroup";
import Discount from "@@/core/components/product/shared/Discount/Discount";
import { BFormInput, BFormTextarea } from "bootstrap-vue";

export default {
  name: "ProductPricing",
  components: { Discount, InputGroup, BFormInput, BFormTextarea },
  props: {
    value: Object,
  },
  watch: {
    value(newVal) {
      this.realValue = newVal;
    },
    realValue(newVal) {
      this.$emit("input", newVal);
    },
  },
  data() {
    return {
      realValue: this.value,
    };
  },
};
