<template>
  <main class="mt-5 admin-faq">
   

    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">مدیریت رمز یکبار مصرف</h5>
      </div>
      <div class="card-body ">
        <b-form @submit.prevent="generatePassword()"  >
          <b-form-group
            label="شماره تماس :"
            label-for="userNumber"
          >
            <b-form-input
              id="userNumber"
              v-model="userNumber"
              type="number"
              placeholder="شماره تماس کاربر را وارد کنید"
              required
            ></b-form-input>
          </b-form-group>

          <b-button type="submit" :disabled="disabled" variant="primary"> جستجو</b-button>
        </b-form>
        <br>
        <p v-if="token">
            <span  class="badge badge-success font-size-26">{{token}}</span>
          
        </p>
        <p v-if="errMessage.length">
            
                <span v-for="err in errMessage" class="badge badge-danger"  :key="err">!{{err}}</span>
        </p>
      </div>
    </div>
    <!-- <faqModal :mode="mode" :linkables="linkables" :group="group" v-model="faq"
            :labelMultiselect="modelLable" /> -->
  </main>
</template>
<script>
import FaqImpl from "@@/core/pages/disposablePassword/disposablePasswordImpl";
export default {
  extends: FaqImpl,
};
</script>
