import Back from '@@/core/components/shared/Back/Back'
import WalletModal from '@@/core/components/users/userWallet/userWallet'
import AddressModal from '@@/core/components/address/AddressModal/AddressModal'
import mixins from "@@/core/mixins/mixins"
import {BTable} from "bootstrap-vue";

export default {
  mixins:[mixins],
  components: {
    Back,
    WalletModal,
    AddressModal,
    BTable
  },
  data() {
    return {
      url: "",
      axiosCancelSource: null,
      user: {},
      disabled: false,
      wallet: null,
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "address",
          label: "آدرس",
        },
        {
          key: "user",
          label: "گیرنده",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      transactionsFields: [
        {
          key: 'id',
          label: 'شناسه'
        },
        {
          key: 'amount',
          label: 'مبلغ (تومان)'
        },
        {
          key: 'type',
          label: 'نوع'
        },
        {
          key: 'confirmed',
          label: 'وضعیت'
        },
        {
          key: 'description',
          label: 'توضیحات'
        },
        {
          key: 'tracking_code',
          label: 'شناسه پرداخت'
        },
        {
          key: 'created_at',
          label: 'تاریخ'
        }
      ],
      address: {},
      provinces:[],
      addressMode: 'create',
      type: ''
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.url = "admin/userSajad/transactions?user_id=" + this.$route.params.id;
      this.getuser();
      this.loadData()
      this.loadItems()
    }
  },
  methods: {
    async loadData() {
      const response = await this.$axios.get("admin/orders/create?hide_products=1");
      this.provinces = response.data.data.provinces;
    },
    async getuser() {
      try {
        this.axiosCancelSource = this.$axios.CancelToken.source();
        const response = await this.$axios.get( "admin/userSajad/" + this.$route.params.id, {
          cancelToken: this.axiosCancelSource.token,
        });
        this.user = response.data.data.user;
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return;
        }
        this.$root.notify(error);
      }
    },
    newWallet(item) {
      this.type = item
      this.$root.$emit("bv::show::modal", "user-wallet");
      let wallet = {
        user_id: this.user.id,
        amount: 0,
      };
      this.wallet = wallet
    },
     setNewWallet(newWallet) {
       if(this.type == 'withdraw') {
 this.user.wallet.balance = +this.user.wallet.balance - newWallet.amount;
       } else {
          this.user.wallet.balance = +this.user.wallet.balance + newWallet.amount;
       }

      let transaction = {
        id: '',
        amount: newWallet.amount,
        type: this.type,
        confirmed: true,
        meta: {description: newWallet.description},
      }

      this.items.data.unshift(transaction)
    },
    deleteItem(id) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.$axios
              .delete(
                `admin/users/addresses/${this.$route.params.id}/${id}`
              )
              .then((response) => {
                this.user.addresses = this.user.addresses.filter(
                  (item) => item.id != id
                );
                window.swal({
                  icon: "success",
                  showConfirmButton: true,
                  text: response.data.message,
                  button: "باشه",
                });
              })
              .catch((error) => {
                window.swal({
                  title: "خطا",
                  showConfirmButton: true,
                  text: error.response.data.message,
                  button: "باشه",
                });
              });
          }
        })
        .catch(() => {});
    },
    addNewAddress(newAddress) {
      if(this.addressMode == 'create') {
          this.user.addresses.push(newAddress);
      } else {
        this.$root.$set(this.user.addresses , this.address.index ,  newAddress)
      }

    },
    newAddress() {
      this.address = {
        province: null,
        city: null,
        first_name: "",
        last_name: "",
        postal_code: "",
        address: "",
        mobile: "",
        user_id: null,
      };
      this.address.user_id = this.user.id;
      this.addressMode = "create";
      window.$("#AddAddress1").modal("show");
    },
    editAddress(index) {
      let item = {...this.user.addresses[index]}
      item.index = index
      item.province = item.city.province
      item.user_id = this.user.id

      this.address = item
      this.addressMode = "edit";
      window.$("#AddAddress1").modal("show");
    }
  },
};
