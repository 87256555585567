import Back from '@@/core/components/shared/Back/Back'
import { BForm, BFormGroup, BFormInput, BButton,BFormFile } from "bootstrap-vue";

export function usersFactory() {
  return {
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    national_code: '',
    gender: null,
    has_site:false,
    cart_number: '',
    birth_date: '',
    newsletter: 0,
    foreign_national: 0,
    expires_at: null
  }
}

export default {
  components: {
    Back,
    BForm, BFormGroup, BFormInput, BButton,BFormFile
  },
  data() {
    return {
      disabledBtn:false,
      title: '',
      url: '',
      axiosCancelSource: null,
      user: usersFactory()
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.url = 'admin/userSajad/' + this.$route.params.id
        this.getusers()
    } else {
      this.url = 'admin/userSajad'
    }
  },
  methods: {
   async sendUser(e){
      try {
        let formData = new FormData(e.target);
        // formData.append('type', this.specification.type ? this.specification.type.value : '')
        formData.append("_method", this.$route.params.id ? "put" : "post");
        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response, "success");
        this.$router.push("/admin/users");
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
    async getusers() {
      try {
        this.axiosCancelSource = this.$axios.CancelToken.source();
        const response = await this.$axios.get(this.url, {
          cancelToken: this.axiosCancelSource.token
        })
        this.user = response.data.data.user
        if (this.user.gender == 'male') {
          this.users.gender = {
            value: 'male',
            name: 'مرد'
          }
        } else {
          this.user.gender = {
            value: 'female',
            name: 'زن'
          }
        }
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return;
        }
        this.$root.notify(error)
      }
    }
  }
}
