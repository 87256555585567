<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold py-3">گزارش سود محصولات</h5>
      </div>
      <div class="card-body">
        <BForm>
          <DatePickerRange v-model="filter.date" />
          <dic class="row">
            <BFormGroup class="col-xl-6 col-md-12" label="شناسه محصولات">
              <BFormInput
                v-model="filter.product_ids"
                name="noname"
                type="text"
                placeholder="شناسه ها را با , جدا کنید مانند 13,14,15"
              ></BFormInput>
            </BFormGroup>
            <!-- <BFormGroup class="col-xl-6 col-md-12" label="نام سایت نماینده">
              <BFormInput
                v-model="filter.customer_websites"
                name="noname"
                type="text"
                placeholder="مانند test.ir,test1.ir"
              ></BFormInput>
            </BFormGroup> -->
          </dic>
          <BButton @click="loadData()" variant="primary">فیلتر سازی</BButton>
        </BForm>
        <br />
        <br />
        <div v-if="total_profit != null" class="row">
          <span class="font-bold"> مجموع سود: </span>
          <span class="badge badge-success">
            {{ total_profit | price }}
            تومان
          </span>
        </div>
        <br />
        <b-table
          v-if="reports"
          :style="{ opacity: disabled ? '0.6' : 1 }"
          bordered
          responsive
          striped
          :fields="fields"
          :items="reports"
          :disabled="disabled"
        >
          <template v-slot:cell(index)="data">
            {{
              data.index +
              1 +
              (!disabled
                ? (page - 1) * per_page
                : (previousPage - 1) * per_page)
            }}
          </template>
          <template v-slot:cell(id)="data">
            <a :href="'/admin/order/' + data.item.id" target="_blank">{{
              data.item.id
            }}</a>
          </template>
          <template v-slot:cell(_total)="data">
            {{ data.item._total | price }}
          </template>
          <template v-slot:cell(mobile)="data">
            <router-link
              :to="'/admin/customers/show/' + data.item.customer.id"
              >{{ data.item.customer.mobile }}</router-link
            >
          </template>
          <template v-slot:cell(attributes)="data">
            <button
              @click="showAttributesInfo(data.item)"
              class="btn btn-secondary"
              style="line-height: 0.8"
            >
              جزییات
            </button>
          </template>
          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | persianDate }}
          </template>
        </b-table>
        <div v-else-if="reports == ''" class="alert alert-danger">
          موردی یافت تشد
        </div>
        <pagination
          :router="true"
          v-if="reports && reports.data != ''"
          :disabled="disabled"
          :limit="2"
          :data="reports"
          @pagination-change-page="changePage"
        />
      </div>
    </div>
  </main>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput, BTable } from "bootstrap-vue";
import {
  getStatusLabel,
  getStatusColor,
} from "@@/core/libraries/adminFunctions";
import DatePickerRange from "@@/core/components/shared/DatePickerRange/DatePickerRange";
import { priceFilter } from "@@/core/libraries/filters";
import sortMixin from "@@/core/libraries/mixins/sortMixin";

export default {
  name: "OrdersReport",
  components: {
    DatePickerRange,
    BTable,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  mixins: [sortMixin],
  data() {
    return {
      total_profit: null,
      ordersInfo: null,
      attributesInfo: null,
      page: 1,
      previousPage: 1,
      per_page: 50,
      disabled: true,
      reports: null,
      filter: {
        date: {
          startDate: "",
          endDate: "",
        },
        product_ids: null,
        customer_websites: null,
      },
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        // {
        //   key: "id",
        //   label: "شناسه محصول",
        //   sortable: true,
        // },
        // {
        //   key: "attributes",
        //   label: "ویژگی",
        // },
        // {
        //   key: "order_items_count",
        //   label: "عنوان تنوع",
        //   sortable: true,
        // },
        {
          key: "product_title",
          label: "عنوان محصول",
          sortable: true,
        },

        {
          key: "order_id",
          label: "شناسه سفارش",
          sortable: true,
        },
        {
          key: "amount",
          label: "مبلغ",
          sortable: true,
        },
        // {
        //   key: "discount_amount",
        //   label: "تخفیف",
        //   formatter: priceFilter,
        //   sortable: true,
        // },
        // {
        //   key: "shipping_amount",
        //   label: "هزینه حمل و نقل",
        //   formatter: priceFilter,
        //   sortable: true,
        // },
        // {
        //   key: "total",
        //   label: "جمع کل",
        //   formatter: priceFilter,
        //   sortable: true,
        // },
        {
          key: "created_at",
          label: "تاریخ",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    per_page() {
      this.changePage(1);
    },
  },

  methods: {
    getStatusLabel,
    getStatusColor,
    showAttributesInfo(report) {
      this.attributesInfo = report.attribute_info;
      this.$root.$emit("bv::show::modal", "attributes-info");
    },
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData();
    },
    convertToTimeStamp(data) {
      console.log(data);

      // var myDate = data;
      // myDate = myDate.split("-");
      var newDate = new Date(data);
      console.log(newDate.getTime() / 1000);
      return newDate.getTime() / 1000;
    },
    async loadData() {
      try {
        this.disabled = true;
        const response = await this.$axios.get("admin/profits_byProduct", {
          params: {
            start_date: this.convertToTimeStamp(this.filter.date.startDate),
            end_date: this.convertToTimeStamp(this.filter.date.endDate),
            product_ids: this.filter.product_ids,
          },
        });
        console.log(response);
        this.reports = response.data.data.order_items;
        this.total_profit = response.data.data.total_profit;
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
      }
    },
  },
};
</script>
