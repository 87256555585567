import Back from "@@/core/components/shared/Back/Back";
import { BForm, BFormGroup, BFormInput,BFormCheckbox, BButton } from "bootstrap-vue";

export default {
  components: {
    Back,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
  },
  data() {
    return {
      title: "",
      url: "",
      axiosCancelSource: null,
      provider: {
        is_active: true,
        full_name: "",
        mobile: null,
      },
      categories: [],
      attributes: [],
      specifications: [],
      brands: [],
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.url = "admin/providers/" + this.$route.params.id;
      this.getprovider();
    } else {
      this.url = "admin/providers";
    }
  },
  methods: {
    async sendprovider(e) {
      e.preventDefault();
      this.disabled = true;

      try {
        let formData = new FormData(e.target);
        // formData.append('type', this.specification.type ? this.specification.type.value : '')
        formData.append("_method", this.mode == "edit" ? "put" : "post");
        formData.append("is_active",Number(this.provider.is_active))
        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response, "success");
        this.$router.push("/admin/providers");
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
    async getprovider() {
      try {
        this.axiosCancelSource = this.$axios.CancelToken.source();
        const response = await this.$axios.get(this.url, {
          cancelToken: this.axiosCancelSource.token,
        });
        this.provider = response.data.data.provider;
        this.$refs.providerForm.setDisabled(false);
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return;
        }
        this.$root.notify(error);
      }
    },
  },
};
