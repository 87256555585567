import {BTable} from "bootstrap-vue";
import {getStatusLabel} from "@@/core/libraries/adminFunctions";

export default {
  components: {BTable},
  data() {
    return {
      logFields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "description",
          label: "توضیح",
        },
        {
          key: 'created_at',
          label: 'تاریخ'
        }
      ],
    }
  },
  props: {
    order: Object,
    cardByCardTransactionIds:Array
  },
  methods: {
    getStatusLabel,
    getOrderLogLabel(status) {
      switch (status) {
        case "new":
          return "به سفارش اضافه شد";
        case "delete":
          return "از سفارش حذف شد";
        case "decrement":
          return "کاهش یافت";
        case "increment":
          return "افزایش یافت";
      }
    },
  }
}
