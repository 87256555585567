<template>
  <main v-if="list && mainData" class="mt-5">
    <span id="add-btn">
      <button @click="newDiscount" class="btn btn-success">افزودن</button>
    </span>
    <b-modal
      ok-only
      ok-title="بستن"
      hide-footer
      ok-variant="warning"
      id="coupen-info"
      title="تخفیف"
    >
      <b-form-group label="نوع تخفیف:">
        <multiselect
          placeholder="نوع"
          v-model="type"
          open-direction="bottom"
          :options="types"
          label="title"
          track-by="id"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
        >
          <template slot="noOptions"> موردی یافت نشد </template>
          <template slot="noResult"> موردی یافت نشد </template>
        </multiselect>
      </b-form-group>

      <b-form-group label="میزان تخفیف:">
        <b-form-input type="number" v-model="amount"> </b-form-input>
      </b-form-group>

      <b-button :disabled="disable" variant="primary" @click="submit()"
        >ثبت</b-button
      >
    </b-modal>
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">
          تخفیف
          <strong>{{ mainData.title }}</strong>
        </h5>
        <div style="flex: 1"></div>

        <h4>
          <span>از تاریخ :</span>
          <strong>
            {{ mainData.start_date | persianDate }}
          </strong>

          <span>تا تاریخ :</span>
          <strong>
            {{ mainData.end_date | persianDate }}
          </strong>
        </h4>
      </div>
      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="list" :fields="fields">
            <template v-slot:cell(discount)="data">
              {{ data.item.discount | price }}
            </template>
            <template v-slot:cell(discount_type)="data">
              {{ data.item.discount_type == "percentage" ? "%" : "تومان" }}
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <a class="action-btns1 pt-1 px-2" @click="editItem(data.item)">
                  <i class="feather feather-edit text-primary"></i>
                </a>
              </span>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a
                  class="action-btns1 pt-1 px-2"
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
            </template>
            <template v-slot:cell(add)="data">
              <span :id="'add-btn-' + data.item.id">
                <a
                @click.prevent="saveToStorage(data.item)"
                  class="action-btns1 pt-1 px-2"
                >
                  <i class="feather feather-plus text-primary"></i>
                </a>
              </span>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </main>
  <Loading v-else />
</template>
<script>
import discountTypeImpl from "@@/core/pages/discount/discountType/discountTypeImpl.js";
export default {
  extends: discountTypeImpl,
};
</script>
<style>
.discountOption a {
  display: flex;
  padding: 24px 16px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 0 8px #1122339e;
  transition: all 0.5s;
  transform-origin: top;
  min-height: 45px;
  gap: 8px;
}
.discountOption a h2 {
  margin-bottom: 0;
}

.discountOption a:hover {
  color: white;
  background-color: black;
}
.discountOption {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
}
</style>
