<template>
  <main id="print" class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">حواله انبار</h5>
        <div style="flex: 1"></div>
        <div class="d-flex" style="gap: 8px">
          <b-button @click="print">
            <i class="fa fa-print"></i>
          </b-button>
          <Back />
        </div>
      </div>
      <div v-if="storeData" class="titleFlex">
        <div>
          <span>شماره حواله :</span>
          <strong>{{ storeData.id }}</strong>
        </div>
        <div>
          <span>تاریخ حواله :</span>
          <strong>{{
            new Date(storeData.created_at).toLocaleDateString("fa")
          }}</strong>
        </div>
      </div>
      <b-table
        class="mt-3 admin-order"
        v-if="storeData"
        hover
        bordered
        responsive
        :items="storeData.items"
        :fields="fields"
      >
        <template v-slot:cell(label)="data">
          {{
            data.item.variety.product.title + getVarietyTitle(data.item.variety)
          }}
        </template>
        <template v-slot:cell(quantityTransfer)="data">
          {{ data.item.quantity }}
        </template>
        <template v-slot:cell(index)="data">
          <span>
            {{ data.index + 1 }}
          </span>
        </template>
        <template #bottom-row="data">
          <b-td colspan="6">
            <span>تعداد کل اقلام :</span>
            <strong> {{ totalQuantity }} </strong>
          </b-td>
        </template>
      </b-table>
      <div class="card-body">
        <div v-if="storeData" class="titleFlex">
          <div>
            <span>امضاء انتقال دهنده :</span>
            <strong>{{ storeData.mover ? storeData.mover : "نامشخص" }}</strong>
          </div>
          <div>
            <span>امضاء تحویل گیرنده :</span>
            <strong>{{
              storeData.receiver ? storeData.receiver : "نامشخص"
            }}</strong>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import printImpl from "@@/core/pages/Store/storeTransfer/print/printImpl";
export default {
  extends: printImpl,
};
</script>
<style>
.titleFlex {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
}
@media print {
  .show-in-print.titleFlex {
    display: flex;
    gap: 16px;
  }
  .show-in-print {
    display: block;
  }
  .not-in-print {
    display: none !important;
  }
  .print-justify-center {
    justify-content: center;
  }
  .app-content .side-app {
    padding: 0 !important;
  }

  .print-only {
    display: block !important;
  }

  body * {
    visibility: hidden;
  }
  #print,
  #print * {
    visibility: visible;
    font-size: 1.25rem;
  }
  #print {
    padding: 0 !important;
  }
  #print {
    width: 100%;
  }

  #print .not-in-print {
    display: none;
  }

  .mr-2-print {
    margin-right: 20px;
  }
}
</style>
